<template>
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
    crossorigin="anonymous"
  />
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
    crossorigin="anonymous"
  />

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
  />
  <!-- Header Start -->
  <AppNavbar />
  <!-- Header End -->
  <section style="padding-top: 120px; min-height: 140px"></section>

  <div v-if="message" class="notification is-success">
    <div class="post">
      <div class="img">
        <img :src="this.aux.URL_FotoCharla" alt="Imagen Charla" />
      </div>
      <div class="info">
        <h1 class="titulo">{{ this.aux.Nombre }}</h1>
        <div class="fecha">Organización: {{ this.aux.NombreInstitucion }}</div>
        <div class="fecha">
          Descripción: Aquí va la descripción de la charla
        </div>
        <div class="fecha">
          Fecha: {{ convertDateTimeNormal(this.aux.Fecha) }}
        </div>
        <div class="fecha">Hora: {{ this.aux.Hora }}</div>
        <div class="fecha">Link Zoom: {{ this.aux.Link }}</div>
        <button
          class="btn"
          @click="
            getCorreos(
              this.aux.Id_Charla,
              this.aux.Nombre,
              this.aux.Fecha,
              this.aux.Hora,
              this.aux.Link
            )
          "
        target="_top"
        >
          <i class="bi" style="font-style: normal">Notificar</i>
        </button>
        <button
          class="btn"
          @click="
            exportExcel(this.aux.Id_Charla, this.aux.Nombre, this.aux.Link)
          "
        >
          <i class="bi" style="font-style: normal">Descargar inscritos</i>
        </button>
      </div>
    </div>
  </div>

  <div
    id="app1"
    style="display: flex; flex-wrap: wrap; justify-content: center"
  >
    <div class="calendar-controls" style="margin: 5%">
      <!--<a href="" class="btn" @click="irACalendarioPresentacion()">Calendario Presentaciones</a>
                <a href="" class="btn" @click="irAListaCharlas()">Lista Charlas</a>-->

      <div class="box">
        <h4 class="title is-5">Charlas</h4>

        <div class="field">
          <label class="label">Unidad de tiempo del período</label>
          <div class="control">
            <div class="select">
              <select v-model="displayPeriodUom">
                <option>month</option>
                <option>week</option>
                <option>year</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Cantidad de períodos</label>
          <div class="control">
            <div class="select">
              <select v-model="displayPeriodCount">
                <option :value="1">1</option>
                <option :value="2">2</option>
                <option :value="3">3</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Día de inicio de la semana</label>
          <div class="control">
            <div class="select">
              <select v-model="startingDayOfWeek">
                <option
                  v-for="(d, index) in dayNames"
                  :key="index"
                  :value="index"
                >
                  {{ d }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="checkbox">
            <input v-model="useTodayIcons" type="checkbox" />
            Usar icono para el período actual
          </label>
        </div>

        <div class="field">
          <label class="checkbox">
            <input v-model="displayWeekNumbers" type="checkbox" />
            Mostrar número de semana
          </label>
        </div>

        <div class="field">
          <label class="checkbox">
            <input v-model="showTimes" type="checkbox" />
            Mostrar horas
          </label>
        </div>

        <div class="field">
          <label class="label">Temas</label>
          <label class="checkbox">
            <input v-model="useDefaultTheme" type="checkbox" />
            Por defecto
          </label>
        </div>

        <div class="field">
          <label class="checkbox">
            <input v-model="useHolidayTheme" type="checkbox" />
            Feriados
          </label>
        </div>
      </div>
    </div>
    <div class="calendar-parent">
      <calendar-view
        :items="items"
        :show-date="showDate"
        :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
        :enable-drag-drop="true"
        :disable-past="disablePast"
        :disable-future="disableFuture"
        :show-times="showTimes"
        :date-classes="myDateClasses"
        :display-period-uom="displayPeriodUom"
        :display-period-count="displayPeriodCount"
        :starting-day-of-week="startingDayOfWeek"
        :class="themeClasses"
        :period-changed-callback="periodChanged"
        :current-period-label="useTodayIcons ? 'icons' : ''"
        :displayWeekNumbers="displayWeekNumbers"
        :enable-date-selection="true"
        :selection-start="selectionStart"
        :selection-end="selectionEnd"
        @date-selection-start="setSelection"
        @date-selection="setSelection"
        @date-selection-finish="finishSelection"
        @drop-on-date="onDrop"
        @click-date="onClickDay"
        @click-item="onClickItem"
      >
        <template v-slot:header="{ headerProps }">
          <calendar-view-header
            :header-props="headerProps"
            @input="setShowDate"
          />
        </template>
      </calendar-view>
    </div>
  </div>
  <br />
  <br />
  <AppFooter />
</template>
<script>
// Load CSS from the published version
import AppNavbar from "./navbar-admin.vue";
import AppFooter from "./footer-admin.vue";

// Load CSS from the local repo
import "../../../node_modules/vue-simple-calendar/dist/style.css";
import "../../../node_modules/vue-simple-calendar/dist/css/default.css";
import "../../../node_modules/vue-simple-calendar/dist/css/holidays-us.css";

//import "../node_modules/vue-simple-calendar/dist/style.css"
//import "../node_modules/vue-simple-calendar/static/css/default.css"
//import "../node_modules/vue-simple-calendar/static/css/holidays-us.css"

//import { CalendarView, CalendarViewHeader, CalendarMath } from "vue-simple-calendar" // published version
import {
  CalendarView,
  CalendarViewHeader,
  CalendarMath,
} from "vue-simple-calendar";
import axios from "axios";
import { rutaString, puertoString } from "@/assets/apiconfig";

// Exportar a Excel
import exportFromJSON from "export-from-json";

export default {
  name: "App",
  components: {
    CalendarView,
    CalendarViewHeader,
    AppFooter,
    AppNavbar,
  },

  data() {
    return {
      /* Show the current month, and give it some fake items to show */
      showDate: this.thisMonth(1),
      superUsuario:
        sessionStorage.getItem("tipo") === null
          ? -1
          : sessionStorage.getItem("tipo"),
      message: "",
      startingDayOfWeek: 0,
      disablePast: false,
      disableFuture: false,
      displayPeriodUom: "month",
      displayPeriodCount: 1,
      displayWeekNumbers: false,
      showTimes: true,
      selectionStart: null,
      selectionEnd: null,
      newItemTitle: "",
      newItemStartDate: "",
      newItemEndDate: "",
      useDefaultTheme: true,
      useHolidayTheme: true,
      useTodayIcons: false,
      items: [
        /*{
					id: "e0",
					startDate: "2018-01-05",
				},*/
      ],
      Correos: [],
      ruta: rutaString,
      puerto: puertoString,
      aux: {
        Fecha: "17/10/2021",
      },
    };
  },
  computed: {
    userLocale() {
      return CalendarMath.getDefaultBrowserLocale;
    },
    dayNames() {
      return CalendarMath.getFormattedWeekdayNames(this.userLocale, "long", 0);
    },
    themeClasses() {
      return {
        "theme-default": this.useDefaultTheme,
        "holiday-us-traditional": this.useHolidayTheme,
        "holiday-us-official": this.useHolidayTheme,
      };
    },
    myDateClasses() {
      // This was added to demonstrate the dateClasses prop. Note in particular that the
      // keys of the object are `yyyy-mm-dd` ISO date strings (not dates), and the values
      // for those keys are strings or string arrays. Keep in mind that your CSS to style these
      // may need to be fairly specific to make it override your theme's styles. See the
      // CSS at the bottom of this component to see how these are styled.
      const o = {};
      const theFirst = this.thisMonth(1);
      const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13;
      const idesDate = this.thisMonth(ides);
      o[CalendarMath.isoYearMonthDay(idesDate)] = "ides";
      o[CalendarMath.isoYearMonthDay(this.thisMonth(21))] = [
        "do-you-remember",
        "the-21st",
      ];
      return o;
    },
  },
  async mounted() {
    if (this.superUsuario != 1) {
      alert("Acceso denegado");
      this.$router.push("/");
    } else {
      /*SCRIPTS NAVBAR*/
      const navbar = document.querySelector(".navbar");
      const menuBtn = document.querySelector("#menu-btn");
      menuBtn.addEventListener("click", () => {
        navbar.classList.toggle("active");
      });

      window.addEventListener("scroll", () => {
        navbar.classList.remove("active");
      });

      const responsecharla = await axios.get(
        `http://${this.ruta}${this.puerto}/charlas`
      );
      this.Charlas = responsecharla.data;
      this.llenarCalendario(this.Charlas);
      this.newItemStartDate = CalendarMath.isoYearMonthDay(
        CalendarMath.today()
      );
      this.newItemEndDate = CalendarMath.isoYearMonthDay(CalendarMath.today());
    }
  },

  methods: {
    obtenerColor(color) {
      if (color != null) {
        return "background-color: " + color + "; color: #ffffff";
      } else {
        console.log("no hay color");
        return "background-color: #a4a4d9";
      }
    },
    async getCorreos(id, nombre, fecha, hora, link) {
      try {
        //const id = this.$route.params.id;
        const response = await axios.get(
          `http://${this.ruta}${this.puerto}/notificacion_estudiantes/${id}`
        );
        this.Correos = response.data;
        this.openEmailClient(nombre, fecha, hora, link);
      } catch (err) {
        console.log(err);
      }
    },

    openEmailClient(nombre, fecha, hora, link) {
      const name = "Recordatorio: Charla " + nombre;
      const emails = this.Correos.map((e) => e.correo);
      const email = emails.join(",");
      const message =
        "Charla: " + nombre +
        "%0D%0AFecha: " + fecha.substring(0,10) +
        "%0D%0AHora: " + hora +
        "%0D%0ALink del zoom: " + link +
        "%0D%0A %0D%0A %0D%0A %0D%0A";
      console.log(email);
      const subject = "" + name + "";
      const body = "" + message + "";

      

      //console.log("mailto:" + email + "?subject=" + subject + "&body=" + body);
      
      window.location.href = "mailto:" + email + "?subject=" + subject + "&body=" + body;
    },

    async exportExcel(id, nombre, link) {
      const response = await axios.get(
        `http://${this.ruta}${this.puerto}/inscritosCharla/${id}`
      );
      const data = response.data;
      const datosCharla = [
        { "Nombre de la charla": nombre },
        { "Enlace de la reunión": link },
      ];
      const modifiedData = datosCharla.concat(data);
      const name = "inscritosCharla";
      const exportType = exportFromJSON.types.xls;
      exportFromJSON({ data: modifiedData, fileName: name, exportType });
    },

    irAListaCharlas() {
      this.$router.push(`/lista_charlas`);
    },
    irACalendarioPresentacion() {
      this.$router.push(`/calendario_presentaciones`);
    },
    irAInscripcionForm(idReunion, tipoReunion) {
      const idEstudiante = 1;

      this.$router.push(
        `/inscripcion-form/${idEstudiante}/${idReunion}/${tipoReunion}`
      );
    },

    llenarCalendario(Charlas) {
      Charlas.forEach((charla) => {
        //console.log(""+this.obtenerFecha(new Date(charla.Fecha))+" "+charla.Hora);
        this.items.push({
          startDate: this.convertDateTime(
            "" + this.obtenerFecha(new Date(charla.Fecha)) + " " + charla.Hora
          ),
          title: charla.NombreInstitucion + ": " + charla.Nombre,
          id: charla.Id_Charla,
          style: `${this.obtenerColor(charla.Color)}`, // Comprobación por ternario corregida
        });
      });
    },
    obtenerFecha(fechaISO) {
      return fechaISO.toISOString().substring(0, 10);
    },
    periodChanged() {
      // range, eventSource) {
      // Demo does nothing with this information, just including the method to demonstrate how
      // you can listen for changes to the displayed range and react to them (by loading items, etc.)
      //console.log(eventSource)
      //console.log(range)
    },
    convertDateTimeNormal(dateTimeString) {
      const trimmedDate = dateTimeString.substring(0, 10); // Recortar los primeros 10 caracteres de la cadena
        const parts = trimmedDate.split("-");
        const reversedDate = parts.reverse().join("-");
          return reversedDate;
    },
    convertDateTime(dateTimeString) {
      const dateParts = dateTimeString.split(/[- :]/);
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1;
      const day = parseInt(dateParts[2]);
      const hour = parseInt(dateParts[3]);
      const minute = parseInt(dateParts[4]);
      //console.log(new Date(year, month, day, hour, minute));
      return new Date(year, month, day, hour, minute);
    },

    thisMonth(d, h, m) {
      const t = new Date();
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0);
    },
    onClickDay() {
      this.selectionStart = null;
      this.selectionEnd = null;
      this.message = false;
    },
    onClickItem(e) {
      this.message = true;
      this.Charlas.forEach((itemCharla) => {
        if (itemCharla.Id_Charla == e.id) {
          //console.log(itemCharla)
          this.aux = itemCharla;
        }
      });
    },
    setShowDate(d) {
      this.message = `Changing calendar view to ${d.toLocaleDateString()}`;
      this.showDate = d;
    },
    setSelection(dateRange) {
      this.selectionEnd = dateRange[1];
      this.selectionStart = dateRange[0];
    },
    finishSelection(dateRange) {
      this.setSelection(dateRange);
      this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`;
    },
    onDrop(/*item, date*/) {
      /*
			this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`
			// Determine the delta between the old start date and the date chosen,
			// and apply that delta to both the start and end date to move the item.
			const eLength = CalendarMath.dayDiff(item.startDate, date)
			item.originalItem.startDate = CalendarMath.addDays(item.startDate, eLength)
			item.originalItem.endDate = CalendarMath.addDays(item.endDate, eLength)*/
    },
    clickTestAddItem() {
      this.items.push({
        startDate: this.newItemStartDate,
        endDate: this.newItemEndDate,
        title: this.newItemTitle,
        id: "e" + Math.random().toString(36).substr(2, 10),
      });
      this.message = "You added a calendar item!";
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  background-color: #f7fcff;
}

#app1 {
  display: flex;
  flex-direction: row;
  font-family: Calibri, sans-serif;
  width: 90%;
  min-width: 30rem;
  min-height: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.calendar-controls {
  margin-right: 1rem;
  min-width: 14rem;
  max-width: 30rem;
  font-size: 1.5rem;
}

.calendar-parent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 1.5rem;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 80vh;
  max-height: 150vh;
  background-color: white;
}

/* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
.cv-wrapper.period-month.periodCount-2 .cv-week,
.cv-wrapper.period-month.periodCount-3 .cv-week,
.cv-wrapper.period-year .cv-week {
  min-height: 6rem;
}

/* These styles are optional, to illustrate the flexbility of styling the calendar purely with CSS. */

/* Add some styling for items tagged with the "birthday" class */
.theme-default .cv-item.birthday {
  background-color: #e0f0e0;
  border-color: #d7e7d7;
}

.theme-default .cv-item.birthday::before {
  content: "\1F382"; /* Birthday cake */
  margin-right: 0.5em;
}

/* The following classes style the classes computed in myDateClasses and passed to the component's dateClasses prop. */
.theme-default .cv-day.ides {
  background-color: #ffe0e0;
}

.ides .cv-day-number::before {
  content: "\271D";
}

.cv-day.do-you-remember.the-21st .cv-day-number::after {
  content: "\1F30D\1F32C\1F525";
}

/*EMPIEZA CSS NILMAR*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

:root {
  --orange: #ff7800;
  --black: #120f40;
  --darkYellow: #f2b90c;
  --yellow: #f2cb05;
  --marine: #024873;
  --blue: #034c8c;
  --white: #f2f2f2;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2s linear;
}

/*BOTÓN QUE LLEVA AL INICIO*/
.scroll-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: var(--black);
  color: var(--white);
  padding: 20px;
  border-radius: 50%;
  font-size: 20px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: 9999;
}

.scroll-top-btn:hover {
  opacity: 0.8;
}
/*FIN BOTÓN QUE LLEVA AL INICIO*/

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
}

body {
  background: #f2f2f2;
}

.features .btn {
  border: 0.2rem solid var(--black);
  color: var(--darkYellow);
}

.products .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}

.review .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}

.categories .btn {
  border: 0.2rem solid var(--yellow);
  background-color: var(--black);
  width: 75%;
  height: 4.5rem;
  font-size: 1.8rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center center;
  color: var(--white);
  padding-top: 7px;
}

.footer .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}

.modal .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}

.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: 0.8rem 3rem;
  font-size: 2.5rem;
  font-weight: 400;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--white);
  color: var(--yellow);
  cursor: pointer;
}

.btn:hover {
  background: var(--black);
  border: 0.2rem solid var(--white);
  color: #f2f2f2;
  transition: 0.5s;
  scale: 1.1;
}

/* NAVBAR GENERAL*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 9%;
  background: #f2f2f2;
  box-shadow: var(--box-shadow);
}

.header .logo {
  min-width: 7.15rem;
  min-height: 7.15rem;
  /*max-height: 8rem;*/
  height: 50px;
  width: 50px;
  font-size: 3rem;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  color: var(--black);
}
.header .logo:hover {
  transition: 0.5s;
  scale: 1.2;
}

.header .logo i {
  color: var(--black);
}

.header .navbar a {
  font-size: 1.7rem;
  margin: 0 1rem;
  margin-top: 18px;
  color: var(--black);
  text-decoration: none;
}

.header .navbar a:hover {
  color: var(--black);
  scale: 1.2;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.5s;
}

.header .icons div {
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.5rem;
  border-radius: 0.5rem;
  background: #eee;
  color: var(--black);
  font-size: 2rem;
  margin-left: 0.3rem;
  cursor: pointer;
  text-align: center;
}

.header .icons div:hover {
  background: var(--black);
  color: #f2f2f2;
}

#menu-btn {
  display: none;
}

.header .search-form {
  position: absolute;
  top: 110%;
  right: -110%;
  width: 50rem;
  height: 5rem;
  background: #f2f2f2;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
}

.header .search-form.active {
  right: 2rem;
  transition: 0.5s;
}

.header .search-form input {
  height: 100%;
  width: 100%;
  background: none;
  text-transform: none;
  font-size: 1.6rem;
  color: var(--black);
  padding: 0 1.5rem;
}

.header .search-form label {
  font-size: 2.2rem;
  padding-right: 1.5rem;
  color: var(--black);
  cursor: pointer;
}

.header .search-form label:hover {
  color: var(--black);
}

.header .shopping-cart {
  position: absolute;
  top: 100%;
  right: -110%;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  width: 35rem;
  background: #f2f2f2;
}

.header .shopping-cart.active {
  right: 2rem;
  transition: 0.4s linear;
}

.header .shopping-cart .box {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  margin: 1rem 0;
}

.header .shopping-cart .box img {
  height: 10rem;
}

.header .shopping-cart .box .fa-trash {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  right: 2rem;
  cursor: pointer;
  color: var(--light-color);
  transform: translateY(-50%);
}

.header .shopping-cart .box .fa-trash:hover {
  color: var(--black);
}

.header .shopping-cart .box .content h3 {
  color: var(--black);
  font-size: 1.7rem;
  padding-bottom: 1rem;
}

.header .shopping-cart .box .content span {
  color: var(--light-color);
  font-size: 1.6rem;
  padding-bottom: 1rem;
}

.header .shopping-cart .box .content .quantity {
  padding-left: 1rem;
}

.header .shopping-cart .total {
  font-size: 2.5rem;
  padding: 1rem 0;
  text-align: center;
  color: var(--black);
}

.header .shopping-cart .btn {
  display: block;
  text-align: center;
  margin: 1rem;
}

@media (max-width: 991px) {
  html {
    font-size: 55%;
  }
  .header {
    padding: 2rem;
  }
  section {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .header .navbar {
    position: absolute;
    top: 110%;
    right: -110%;
    width: 30rem;
    box-shadow: var(--box-shadow);
    border-radius: 0.5rem;
    background: #f2f2f2;
  }

  .header .search-form {
    width: 90%;
  }
  #menu-btn {
    display: inline-block;
  }

  .header .navbar.active {
    right: 2rem;
    transition: 0.5s linear;
  }

  .header .navbar a {
    font-size: 2rem;
    margin: 2rem 2.5rem;
    display: block;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .heading {
    font-size: 2.5rem;
  }

  .footer {
    text-align: center;
  }
}

section {
  padding: 2rem 9%;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  /*background: url()no-repeat;*/
  background-position: center center;
  background-size: cover;
  padding-top: 17rem;
  padding-bottom: 10rem;
}

.home .content {
  text-align: center;
  width: 90rem;
}

.home .content h3 {
  color: var(--white);
  font-size: 6rem;
}

.home .content h3 span {
  color: var(--yellow);
}

.home .content p {
  color: var(--white);
  font-size: 1.9rem;
  padding: 1rem 0;
  line-height: 1.8;
}

.heading {
  text-align: center;
  padding: 2rem 0;
  padding-bottom: 3rem;
  font-size: 3.5rem;
  color: var(--darkYellow);
}

.heading span {
  background: var(--black);
  color: #f2f2f2;
  display: inline-block;
  padding: 0.5rem 3rem;
  clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
}

.features .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
}

.features .box-container .box {
  padding: 3rem 2rem;
  background: #f2f2f2;
  outline: var(--outline);
  outline-style: -1rem;
  text-align: center;
  box-shadow: var(--box-shadow);
}

.features .box-container .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.features .box-container .box img {
  margin: 1rem 9;
  height: 15rem;
}

.features .box-container .box h3 {
  font-size: 2.5rem;
  line-height: 1.8;
  color: var(--light-color);
  padding: 1rem 0;
}

.products .product-slider {
  padding: 1rem;
}

.products .product-slider:first-child {
  margin-bottom: 2rem;
}

.products .product-slider .box {
  background: #f2f2f2;
  border-radius: 0.5rem;
  text-align: center;
  padding: 3rem 2rem;
  outline-offset: -1rem;
  outline: var(--outline);
  box-shadow: var(--box-shadow);
}

.products .product-slider .box:hover {
  outline-offset: 0rem;
  outline: var(--outline-hover);
}

.products .product-slider .box img {
  height: 20rem;
}

.products .product-slider .box h3 {
  font-size: 2.5rem;
  color: var(--black);
}

.products .product-slider .box .stars i {
  font-size: 1.7rem;
  color: var(--black);
  padding: 0.5rem 0;
}

.categories .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  gap: 1.5rem;
}

.categories .box-container .box {
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  background: #f2f2f2;
  box-shadow: var(--box-shadow);
  outline: var(--outline);
  outline-offset: -1rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories .box-container .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.categories .box-container img {
  margin: 1rem 0;
  max-height: 25rem;
  min-height: 25rem;
  object-fit: cover;
  width: 95%;
}

.categories .box-container .box h3 {
  font-size: 2rem;
  color: var(--black);
  line-height: 1.8;
  height: 7rem;
  display: flex;
  align-items: center;
}

.categories .box-container .box p {
  font-size: 1.7rem;
  color: var(--black);
  line-height: 1.8;
  padding: 1rem 0;
  height: 9rem;
}

.review .review-slider {
  padding: 1rem;
}

.review .review-slider .box {
  background: #f2f2f2;
  border-radius: 0.5rem;
  text-align: center;
  padding: 3rem 2rem;
  outline-offset: -1rem;
  outline: var(--outline);
  box-shadow: var(--box-shadow);
  transition: 0.2s linear;
}

.review .review-slider .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.review .review-slider .box img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
}

.review .review-slider .box p {
  padding: 1rem 0;
  line-height: 1.8;
  color: var(--light-color);
  font-size: 1.5rem;
}

.review .review-slider .box h3 {
  padding-bottom: 0.5rem;
  color: var(--black);
  font-size: 2.2rem;
}

.review .review-slider .box .stars i {
  color: var(--black);
  font-size: 1.7rem;
}

.boton .btn {
  border: 0.2rem solid var(--black);
  background-color: var(--black);
  color: var(--white) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
}
.boton .btn:hover {
  border: 0.2rem solid var(--white);
  color: var(--black) !important;
  transition: 0.5s;
  scale: 1.1;
}

/*CARTAS BLOGS-PRESENTACIONES*/

.blogs .btn {
  border: 0.2rem solid var(--yellow);
  background-color: var(--black);
  color: var(--white) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
}
.blogs .btn:hover {
  border: 0.2rem solid var(--white);
  color: var(--black) !important;
  transition: 0.5s;
  scale: 1.1;
}

.blogs .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  gap: 1.5rem;
}

.blogs .box-container .box {
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  background: #f2f2f2;
  max-width: 50rem;
}

.blogs .box-container .box img {
  height: 25rem;
  width: 100%;
  object-fit: cover;
}

.blogs .box-container .box .content {
  padding: 2rem;
}

.blogs .box-container .box .content .icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a {
  color: var(--light-color);
  font-size: 1.5rem;
}

.blogs .box-container .box .content .icons a:hover {
  color: var(--black);
}

.blogs .box-container .box .content .icons a i {
  color: var(--black);
  padding-right: 0.5rem;
}

.blogs .box-container .box .content h3 {
  line-height: 1.8;
  color: var(--black);
  font-size: 2.2rem;
  padding: 0.5rem 0;
  height: 7.5rem;
}

.blogs .box-container .box .content p {
  line-height: 1.8;
  color: var(--light-color);
  font-size: 1.5rem;
  padding: 0.5rem 0;
}
/*FIN CARTAS BLOGS-PRESENTACIONES*/

.footer {
  padding-top: 50px;
  background: #eee;
  box-shadow: var(--box-shadow);
  text-decoration: none;
}

.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;
}

.footer .box-container .box h3 {
  font-size: 2.5rem;
  color: var(--black);
  padding: 1rem 0;
}

.footer .box-container .box h3 i {
  color: var(--black);
}

.footer .box-container .box .links {
  display: block;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box .links i {
  color: var(--black);
  padding-right: 0.5rem;
}

.footer .box-container .box .links:hover i {
  padding-right: 2rem;
  text-decoration: none;
}

.footer .box-container .box p {
  line-height: 1.8;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box .share a {
  height: 4rem;
  width: 4rem;
  line-height: 4rem;
  border-radius: 0.5rem;
  font-size: 2rem;
  color: var(--black);
  margin-left: 0.2rem;
  text-align: center;
}

.footer .box-container .box .share a:hover {
  background: var(--black);
  color: #f2f2f2;
}

.footer .box-container .box .email {
  margin: 0.7rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--black);
  background: #eee;
  font-size: 1.6rem;
  color: var(--black);
  text-transform: none;
}

.footer .box-container .box .payment-img {
  margin-top: 2rem;
  height: 3rem;
}

.footer .credit {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 2.5rem;
  font-size: 2rem;
  color: var(--black);
  border-top: var(--border);
}

.footer .credit span {
  color: var(--black);
}

a {
  text-decoration: none;
}

/*ACABA CSS NILMAR*/

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}
.gallery-block {
  padding-bottom: 60px;
  padding-top: 60px;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: -0px;
  margin-top: 15px;
}
.gallery-block .heading {
  margin-bottom: 50px;
  text-align: center;
}

.gallery-block .heading h2 {
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.gallery-block.cards-gallery h6 {
  font-size: 17px;
  font-weight: bold;
}

.gallery-block.cards-gallery .card {
  transition: 0.4s ease;
}

.gallery-block.cards-gallery .card img {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.gallery-block.cards-gallery .card-body {
  text-align: center;
}

.gallery-block.cards-gallery .card-body p {
  font-size: 15px;
}

.gallery-block.cards-gallery a {
  color: #212529;
}

.gallery-block.cards-gallery a:hover {
  text-decoration: none;
}

.gallery-block.cards-gallery .card {
  margin-bottom: 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

@media (min-width: 576px) {
  .gallery-block .transform-on-hover:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important;
  }
}

.calendar-controls .btn {
  border: 0.2rem solid var(--black);
  background-color: var(--black);
  color: var(--white) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
}

.calendar-controls .btn:hover {
  border: 0.2rem solid var(--white);
  color: var(--black) !important;
  transition: 0.5s;
  scale: 1.1;
}

/*ESTILO TARJETAS */
/*TARJETAS NOTIFICACIONES */
body {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.post {
  width: 90%;
  max-width: 100%;
  padding: 2.5rem 15rem;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: #fff;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  border-radius: 2rem;
}

.post .btn {
  align-items: center;
  justify-content: center center;
  border: 0.2rem solid var(--black);
  background-color: var(--black);
  color: var(--white) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
}
.post .btn:hover {
  border: 0.2rem solid var(--white);
  background-color: var(--white);
  color: var(--black) !important;
  transition: 0.5s;
  scale: 1.1;
}

.info {
  flex-grow: 1;
  min-width: 0;
}

.img {
  min-width: 30%;
  max-width: 30%;
  height: 30rem;
  transform: translateX(-8rem);
  position: relative;
}

.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 2rem;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #fff;
}

.titulo {
  font-size: 2.5rem;
  margin: 1rem 0 1rem;
  text-transform: uppercase;
  color: #4facfe;
}

.fecha {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0.5rem 0 1rem;
}

.descripcion {
  margin-bottom: 3rem;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.7);
}

/* ===== RESPONSIVE ===== */

@media screen and (max-width: 1170px) {
  .btn {
    min-width: 35%;
    max-width: 35%;
  }
}

@media screen and (max-width: 1068px) {
  .post {
    max-width: 80rem;
  }
  .img {
    min-width: 30rem;
    max-width: 30rem;
  }
  .btn {
    min-width: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 868px) {
  .post {
    padding: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .post {
    padding: 2.5rem;
    flex-direction: column;
  }
  .img {
    min-width: 100%;
    max-width: 100%;
    transform: translate(0, -4rem);
  }
  .btn {
    min-width: 90%;
    max-width: 90%;
  }
}
/*FIN DE LOS ESTILOS TARJETAS */
</style>

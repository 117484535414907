<template>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous">
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
      />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ" crossorigin="anonymous">
  
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"> 
    <br>
    <br>
      <!-- Header Start -->
      <AppNavbar/>
      <!-- Header End -->
      <section style="padding-top: 19px;min-height: 110px; " ></section>
      



      <section class="blogs" id="blogs">
          <h1 class="heading">Charlas<span>{{ Institucion.Nombre }}</span></h1>
          <div class="box-container">
            <div class="box" v-for="item in items" :key="item.Id_Charla">
                <img :src="item.URL_FotoCharla" alt="">
                <div class="content">
                    <div class="icons">
                        <a><i class="fas fa-calendar"></i>{{formatDate(item.Fecha)}}</a>
                        <a><i class="fas fa-clock"></i>{{item.Hora}}</a>
                    </div>
                    <h3>{{item.Nombre}}</h3>
                    <p>Cupos: {{item.Cantidad_Cupos}}</p>
                    <p>Cupos Disponibles: {{item.Cupos_Disponibles}}</p>
                    <div style="padding-top:5%;display: inline-block;justify-content: space-between;width: 100%;gap: 10px;">
                        <a style="justify-content: center;align-items: center; width: 40%;margin-left: 5%;margin-right: 5%;"  @click="this.abrirFormCharla(item.Id_Charla )" class="btn">
                            <span class="mensaje">Editar</span>
                            <i class="fas fa-pencil-alt" style="vertical-align: middle;"></i>
                        </a>
                        <a style="justify-content: center;align-items: center;width: 40%;margin-left: 5%;margin-right: 5%;" @click="doDelete(item.Id_Charla)" class="btn">
                            <span class="mensaje">Borrar</span>
                            <i class="fa fa-trash" style="vertical-align: middle;"></i>
                        </a>
                    </div>
                </div>
            </div>








              <div class="box" style="position: relative; display: flex;justify-content: center;align-items: center;">
                    <i class="anadir fas fa-plus" @click="showModalInstitucionNueva=true"></i>
                    <span class="add-text">Registrar</span>
                </div>
          </div>
  
      </section>
      <br>
      <AppFooter/>
    <!--<div>
      <div class="row">
        <div class="col-md-6 col-lg-4 align-items-stretch" v-for="item in items" :key="item.Id_institucion">
          <div class="card border-0 transform-on-hover">
            <a class="lightbox" href="item.URL_Foto">
              <img :src="item.URL_Foto" alt="Card Image" class="card-img-top" >
            </a>
            <div class="card-body">
              <h6><a href="#">{{item.Nombre}}</a></h6>
              <p class="text-muted card-text">{{item.Descripcion}}</p>
              <button class="btn btn-primary" @click="verPagina(item.Id_institucion)">Ver Pagina</button>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  
      <a href="#" class="scroll-top-btn">
          <i class="fas fa-arrow-up"></i>
      </a>
      
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                  <div class="modal-body p-0">
                      <div class="container-fluid">
                          <div class="row" style="padding: 25px;border-radius: 15px;">
                              <div class="col-lg-5 col-sm-20"
                                  style="background-position: center center;background-repeat: none;background-size: cover; background-image: url(https://images.unsplash.com/photo-1586769852044-692d6e3703f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGNvbnRhY3QlMjB1c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60); min-height:300px;">
                              </div>
                              <div class="col-lg-7">
                                  <form class="p-lg-5 col-15 row g-3">
                                      <div>
                                          <h1 style="padding-top: 10px;padding-bottom: 10px; font-size: 50px;" >CONFERENCIA ¡Nombre!</h1>
                                      <h3>EXPOSITORES: ESTO ESS UN TEXTO DE PRUEBA PARA LA INSCRIPCIÓN A LA CONFERENCIA QUE SEA EXTRAÍDA DE LA BDD</h3>
                                      </div>
                                      <div class="col-lg-12">
                                          <!--<label for="userName" class="form-label">First name</label>
                                          <input type="text" class="form-control" placeholder="Jon" id="userName"
                                              aria-describedby="emailHelp">-->
                                          <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi tempora odit labore, nostrum sequi harum soluta ab aliquid? Dolor alias laboriosam praesentium ab quia officiis sequi dignissimos itaque eum ea.</h1>
                                      </div>
                                      <div class="col-lg-6">
                                          <!--<label for="userName" class="form-label">Last name</label>
                                          <input type="text" class="form-control" placeholder="Doe" id="userName"
                                              aria-describedby="emailHelp">-->
                                      </div>
                                      <div class="col-12">
                                          <!--<label for="userName" class="form-label">Email address</label>
                                          <input type="email" class="form-control" placeholder="Johndoe@example.com" id="userName"
                                              aria-describedby="emailHelp">-->
                                          
                                      </div>
                                      <div class="col-12">
                                          <!--<label for="exampleInputEmail1" class="form-label">Enter Message</label>
                                          <textarea name="" placeholder="This is looking great and nice." class="form-control" id=""  rows="4"></textarea>-->
                                          
                                      </div>
  
                                      <div class="col-15">
                                          <button style="width: 100%;" type="submit" class="btn btn-brand">Inscribirme!</button>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
  
              </div>
          </div>
      </div>
      <!--End Modal-->
      <!-- Reload the page after closing inscripcionInstitucion modal -->

      <InscripcionCharla v-if="showModalInstitucion" :id="institucionSelect" @close-modal="showModalInstitucion=false" @update="reloadPage()"/>
      <NuevaCharla v-if="showModalInstitucionNueva" :id="this.Id_Institucion" @close-modal-2="showModalInstitucionNueva=false" @update-2="reloadPage()"/>

      <BorrarCharla ref="confirmDialogue"></BorrarCharla>

  </template>
     
  <script>
  import axios from "axios";
  import AppNavbar from "./navbar-admin.vue";
  import AppFooter from "./footer-admin.vue";
  import InscripcionCharla from "./ModificarCharlas/CharlasForm.vue";
  import BorrarCharla from "./Modals/ConfirmDialogue.vue";
  import NuevaCharla from "./ModificarCharlas/NuevaCharlasForm.vue";
  import { rutaString,puertoString } from '@/assets/apiconfig';

  
  export default {
    name: "EditarCharlas",
    props: {
      Id_Institucion: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        superUsuario: sessionStorage.getItem("tipo") === null ? -1 : sessionStorage.getItem("tipo"),
        items: [],
        showModalInstitucion: false,
        showModalInstitucionNueva: false,
        showModalInstitucionBorrar: false,
        institucionSelect: "0",
        Institucion: [],

ruta: rutaString,
      puerto: puertoString,
        
      };
    },
    
  
    created() {
        console.log('asldjalsd' + this.Id_Institucion);
  this.getCharlas(this.Id_Institucion);
  
    },
  
    methods: {
        formatDate(dateString) {
        const trimmedDate = dateString.substring(0, 10); // Recortar los primeros 10 caracteres de la cadena
        const parts = trimmedDate.split("-");
        const reversedDate = parts.reverse().join("-");
          return reversedDate;
      },
        async reloadPage(){
            console.log("reload");
            this.getCharlas(this.Id_Institucion);
            this.$router.push(`/EditarCharlas/${this.Id_Institucion}`);
        },
        abrirFormCharla(aux){
            this.institucionSelect = aux.toString();
            this.showModalInstitucion = true;
        },
        



        async doDelete(auxborrar) {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Borrar Charla',
                message: 'Estas seguro que deseas borrar esta Charla? Esta accion no se puede deshacer.',
                okButton: 'Borrar',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.deleteCharla(auxborrar);
                alert('El proceso de borrado fue exitoso')
            } 
        },
      async getCharlas(id) {
        try {
            console.log(id);
            await new Promise(resolve => setTimeout(resolve, 250));

            const responsecharla = await axios.get(`http://${this.ruta}${this.puerto}/charla_inst/${id}`);
            console.log(responsecharla);
            this.items = responsecharla.data;
        } catch (err) {
          console.log(err);
        }
      },
  
      async deleteCharla(id) {
        
        try {
          await axios.delete(`http://${this.ruta}${this.puerto}/charlas/${id}`);
          this.getCharlas(this.Id_Institucion);
        } catch (err) {
          console.log(err);
        }
        this.$router.push(`/EditarCharlas/${this.Id_Institucion}`);
      },
  
      verPagina(id) {
        // Abrir otra página y enviar el ID del item como parámetro en la URL
        //window.open(`/PaginaInstitucion?id=${id}`, "_blank");
        window.open(`/PaginaInstitucion/${id}`);
  
      }
    },
  
    /*SCRIPTS NAVBAR*/
    async mounted() {


    if (this.superUsuario != 1){
        alert("Acceso denegado");
        this.$router.push("/");
    } else{
            
        const responseInstitucion = await axios.get(`http://${this.ruta}${this.puerto}/instituciones/${this.Id_Institucion}`);
      this.Institucion = responseInstitucion.data;
        /*script para el navbar*/

      const navbar = document.querySelector('.navbar');
      const menuBtn = document.querySelector('#menu-btn');
      menuBtn.addEventListener('click', () => {
        navbar.classList.toggle('active');
      });
  
      window.addEventListener('scroll', () => {
        navbar.classList.remove('active');
      });
  
      /*script botón que lleva al inicio de la página*/
      const scrollBtn = document.querySelector('.scroll-top-btn');
      window.addEventListener('scroll', function() {
          if (window.pageYOffset > 300) {
          scrollBtn.style.opacity = "1";
          } else {
          scrollBtn.style.opacity = "0";
          }
      });
  
      scrollBtn.addEventListener('click', function(e) {
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: 'smooth' });
      });
      /*FIN DE BOTÓN*/

        }


    },
    /*Componentes*/
    components:{
      AppNavbar,
      AppFooter,
      InscripcionCharla,
      BorrarCharla,
        NuevaCharla,
    }
  };
  </script>
 <style scoped>
 /*EMPIEZA CSS NILMAR*/
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');
 /*CARTAS blogs-PRESENTACIONES*/


 .blogs .box-container{
     display: grid;
     grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
     gap: 1.5rem;
 }
 
 .blogs .box-container .box{
     padding: 3rem 2rem;
     border-radius: .5rem;
     background: #f2f2f2;
     box-shadow: var(--box-shadow);
     outline: var(--outline);
     outline-offset: -1rem;
     text-align: center;
 
 
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;

       flex-wrap: wrap;
       flex-direction: column;
 }

 .blogs .box-container .box .anadir{
   height: 75%;
   width: 100%;
   font-size:22rem;
   color: var(--black);
   display: flex;
   justify-content: center;
   align-items: center;
   transition: transform 1.5s;
 }

 .blogs .box-container .box .anadir:hover{
   animation: rotate 5s ease-in-out;
   transform: rotateZ(360deg);
 }




 .blogs .box-container .box:hover .add-text {
   opacity: 1; 
   }

   .blogs .box-container .box .add-text {
       position: absolute;
       font-size: 2rem;
       color: var(--black); 
       bottom: 1rem;
       left: 50%; 
       transform: translateX(-50%); 
       opacity: 0; 
       transition: opacity 0.5s; 
       align-self: flex-end; 
   }

 .blogs .box-container .box .anadir{
   height: 100%;
   width: 100%;
   font-size:22rem;
   color: var(--black);
 }
 
 .blogs .box-container .box:hover{
     outline: var(--outline-hover);
     outline-offset: 0rem;
 }
 
 .blogs .box-container img{
     margin: 1rem 0;
     max-height: 25rem;
     min-height: 25rem;
     object-fit: cover;
     width: 95%;
 }
 
 .blogs .box-container .box h3{
     font-size: 2rem;
     color: var(--black);
     line-height: 1.8;
     height: 7rem;
     display: flex;
     align-items: center;
 }
 
 .blogs .box-container .box p{
     font-size: 1.7rem;
     color: var(--black);
     line-height: 1.8;
     padding: 1rem 0;
     height: 9rem;
 }


.heading1{
   text-align: center;
   padding: 1.5rem 1rem;
   border-top-left-radius: 20px;
   border-bottom-left-radius: 20px;
   border-top-right-radius: 20px;
   border-bottom-right-radius: 20px;
 }

.blogs .btn{
   border: .2rem solid var(--yellow);
   background-color: var(--black);
   color: var(--white)!important;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
   width: 100%;
   height: 3.5rem;
   font-size: 1.7rem;
}
.blogs .btn:hover{
   border: .2rem solid var(--white);
   color: var(--black) !important;
   transition: .5s;
   scale: 1.1;
}

.blogs .box-container{
   display: grid;
   grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
   gap: 1.5rem;
}

.blogs .box-container .box{
   overflow: hidden;
   border-radius: .5rem;
   box-shadow: var(--box-shadow);
   background: #f2f2f2;
   max-width: 50rem;
}

.blogs .box-container .box img{
   height: 25rem;
   width: 100%;
   object-fit: cover;
}

.blogs .box-container .box .content{
   padding: 2rem;
}

.blogs .box-container .box .content .icons{
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-bottom: 1.5rem;
   margin-bottom: 1rem;
   border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a{
   color: var(--light-color);
   font-size: 1.5rem;
}

.blogs .box-container .box .content .icons a:hover{
   color: var(--black);
}

.blogs .box-container .box .content .icons a i{
   color: var(--black);
   padding-right: .5rem;
}

.blogs .box-container .box .content h3{
   line-height: 1.8;
   color: var(--black);
   font-size: 2.2rem;
   padding: .5rem 0;
   height: 7.5rem;
}

.blogs .box-container .box .content p{
   line-height: 1.8;
   color: var(--light-color);
   font-size: 1.5rem;
   padding: .5rem 0;
}
/*FIN CARTAS blogs-PRESENTACIONES*/
 :root{
     --orange:#ff7800;
     --black: #120f40;
     --darkYellow: #F2B90C;
     --yellow: #F2CB05;
     --marine: #024873;
     --blue: #034C8C;
     --white: #F2F2F2;
     --light-color: #666;
     --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
     --border: .2rem solid rgba(0,0,0,.1);
     --outline: .1rem solid rgba(0,0,0,.1);
     --outline-hover: .2rem solid var(--black);
 }
 
 *{
     font-family: 'Poppins', sans-serif;
     margin: 0;padding: 0;
     box-sizing: border-box;
     outline:none;border: none;
     text-decoration: none;
     text-transform: capitalize;
     transition: all .2s linear;
 }
 
 /*BOTÓN QUE LLEVA AL INICIO*/
 .scroll-top-btn {
   position: fixed;
   bottom: 25px;
   right: 25px;
   background-color: var(--black);
   color: var(--white);
   padding: 20px;
   border-radius: 50%;
   font-size: 20px;
   transition: opacity 0.2s ease-in-out;
   opacity: 0;
   z-index: 9999;
 }
 
 .scroll-top-btn:hover {
   opacity: 0.8;
 }
 /*FIN BOTÓN QUE LLEVA AL INICIO*/
 
 html{
     font-size: 62.5%;
     overflow-x: hidden;
     scroll-behavior: smooth;
     scroll-padding-top: 7rem;
 }
 
 body{
     background: #f2f2f2;
 }
 
 .features .btn{
     border: .2rem solid var(--black);
     color: var(--darkYellow);               
 }
 
 .products .btn{
     border: .2rem solid var(--black);
     color: var(--yellow);
 }
 

 
 .review .btn{
     border: .2rem solid var(--black);
     color: var(--yellow);
 }
 
 .blogs .btn{
     border: .2rem solid var(--black);
     background-color: var(--black);
     width: 75%;
     height: 4.5rem;
     font-size: 1.8rem;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
     align-items: center;
     justify-content: center center;
     color: var(--white);
     padding-top: 7px;
 }
 
 .footer .btn{
     border: .2rem solid var(--black);
     color: var(--yellow);
 }
 .footer .box-container .box:last-child{
     display: flex;
     flex-direction: column;
 }
 
 .modal .btn{
     border: .2rem solid var(--black);
     color: var(--yellow);
 }
 
 .btn{
     margin-top: 1rem;
     display: inline-block;
     padding: .8rem 3rem;
     font-size: 2.5rem;
     font-weight: 400;
     border-radius: .5rem;
     border: .2rem solid var(--white);
     color: var(--yellow);
     cursor: pointer;
 }
 
 .btn:hover{
     background: var(--black);
     border: .2rem solid var(--white);
     color: #f2f2f2;
     transition: .5s;
     scale: 1.1;
 }
 
 /* NAVBAR GENERAL*/
 .header{
     position: fixed;
     top: 0;left: 0;right: 0;
     z-index: 1000;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 2rem 9%;
     background: #f2f2f2;
     box-shadow: var(--box-shadow);
 }
 
 .header .logo{
     min-width: 7.15rem;
     min-height: 7.15rem;
     /*max-height: 8rem;*/
     height: 50px;
     width: 50px;
     font-size: 3rem;
     text-align: center;
     border-radius: 50%;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
     text-decoration: none;
     color: var(--black);
 }
 .header .logo:hover{
     transition: .5s;
     scale: 1.2;
 }
 
 .header .logo i{
     color: var(--black);
 }
 
 .header .navbar a{
     font-size: 1.7rem;
     margin: 0 1rem;
     margin-top: 18px;
     color: var(--black);
     text-decoration: none;
 }
 
 .header .navbar a:hover{
     color: var(--black);
     scale: 1.2;
     padding-left: 20px;
     padding-right: 20px;
     transition: .5s;
 }
 
 
 .header .icons div{
     height: 4.5rem;
     width: 4.5rem;
     line-height: 4.5rem;
     border-radius: .5rem;
     background:#eee;
     color: var(--black);
     font-size: 2rem;
     margin-left: .3rem;
     cursor: pointer;
     text-align: center;
 }
 
 .header .icons div:hover{
     background: var(--black);
     color: #f2f2f2;
 }
 
 #menu-btn{
     display: none;
 }
 
 
 .header .search-form{
     position: absolute;
     top: 110%;right: -110%;
     width: 50rem;
     height: 5rem;
     background: #f2f2f2;
     border-radius: .5rem;
     overflow: hidden;
     display: flex;
     align-items: center;
     box-shadow: var(--box-shadow);
 }
 
 .header .search-form.active{
     right: 2rem;
     transition: .5s;
 }
 
 .header .search-form input{
     height: 100%;
     width: 100%;
     background: none;
     text-transform: none;
     font-size: 1.6rem;
     color: var(--black);
     padding: 0 1.5rem;
 }
 
 .header .search-form label{
     font-size: 2.2rem;
     padding-right: 1.5rem;
     color: var(--black);
     cursor: pointer;
 }
 
 .header .search-form label:hover{
     color: var(--black);
 }
 
 
 
 .header .shopping-cart{
     position: absolute;
     top: 100%;
     right: -110%;
     padding: 1rem;
     border-radius: .5rem;
     box-shadow: var(--box-shadow);
     width: 35rem;
     background: #f2f2f2;
 }
 
 .header .shopping-cart.active{
     right: 2rem;
     transition: .4s linear;
 }
 
 .header .shopping-cart .box{
     display: flex;
     align-items: center;
     gap: 1rem;
     position: relative;
     margin: 1rem 0;
 }
 
 .header .shopping-cart .box img{
     height: 10rem;
 }
 
 .header .shopping-cart .box .fa-trash{
     font-size: 2rem;
     position: absolute;
     top: 50%;right: 2rem;
     cursor: pointer;
     color: var(--light-color);
     transform: translateY(-50%);
 }
 
 .header .shopping-cart .box .fa-trash:hover{
     color: var(--black);
 }
 
 .header .shopping-cart .box .content h3{
     color: var(--black);
     font-size: 1.7rem;
     padding-bottom: 1rem;
 }
 
 .header .shopping-cart .box .content span{
     color: var(--light-color);
     font-size: 1.6rem;
     padding-bottom: 1rem;
 }
 
 .header .shopping-cart .box .content .quantity{
     padding-left: 1rem;
 
 }
 
 .header .shopping-cart .total{
     font-size: 2.5rem;
     padding: 1rem 0;
     text-align: center;
     color: var(--black);
 }
 
 
 .header .shopping-cart .btn{
     display: block;
     text-align: center;
     margin: 1rem;
 }
 
 @media(max-width: 991px){
      html{
         font-size: 55%;
      }   
      .header{
         padding: 2rem;
      }
      section{
         padding: 2rem;
     }
 
 }
 
 @media(max-width: 768px){
     .header .navbar{
         position: absolute;
         top: 110%;
         right: -110%;
         width: 30rem;
         box-shadow: var(--box-shadow);
         border-radius: .5rem;
         background: #f2f2f2;
     }
 
     .header .search-form{
         width: 90%;
     }
     #menu-btn{
         display: inline-block;
     }
 
     .header .navbar.active{
         right: 2rem;
         transition: .5s linear;
     }
 
     .header .navbar a{
         font-size: 2rem;
         margin: 2rem 2.5rem;
         display: block;
     }
 }
 
 @media(max-width: 450px){
     html{
         font-size: 50%;
     }
 
     .heading{
         font-size: 2.5rem;
     }
 
     .footer{
         text-align: center;
     }
 }
 
 
 section{
     padding: 2rem 9%;
 }
 
 
 .home{
     display: flex;
     align-items: center;
     justify-content: center;
     /*background: url()no-repeat;*/
     background-position: center center;
     background-size: cover;
     padding-top: 17rem;
     padding-bottom: 10rem;
 }
 
 .home .content{
     text-align: center;
     width: 90rem;
 }
 
 .home .content h3{
     color: var(--white);
     font-size: 6rem;
 }
 
 .home .content h3 span{
     color: var(--yellow);
 }
 
 .home .content p{
     color: var(--white);
     font-size: 1.9rem;
     padding: 1rem 0;
     line-height: 1.8;
 }
 
 .heading{
     text-align: center;
     padding: 2rem 0;
     padding-bottom: 3rem;
     font-size: 3.5rem;
     color: var(--darkYellow);
 }
 
 .heading span{
     background: var(--black);
     color: #f2f2f2;
     display: inline-block;
     padding: .5rem 3rem;
     clip-path: polygon(100% 0,93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
 }
 
 .features .box-container{
     display: grid;
     grid-template-columns: repeat(auto-fit,minmax(30rem,1fr));
     gap:1.5rem;
 }
 
 .features .box-container .box{
     padding: 3rem 2rem;
     background: #f2f2f2;
     outline: var(--outline);
     outline-style: -1rem;
     text-align: center;
     box-shadow: var(--box-shadow);
 }
 
 .features .box-container .box:hover{
     outline: var(--outline-hover);
     outline-offset: 0rem;
 }
 
 .features .box-container .box img{
     margin: 1rem 9;
     height: 15rem;
 }
 
 .features .box-container .box h3{
     font-size: 2.5rem;
     line-height: 1.8;
     color: var(--light-color);
     padding: 1rem 0;
 }
 
 .products .product-slider{
     padding: 1rem;
 }
 
 .products .product-slider:first-child{
     margin-bottom: 2rem;
 }
 
 .products .product-slider .box{
     background: #f2f2f2;
     border-radius: .5rem;
     text-align: center;
     padding: 3rem 2rem;
     outline-offset: -1rem;
     outline: var(--outline);
     box-shadow: var(--box-shadow);
 }
 
 .products .product-slider .box:hover{
     outline-offset: 0rem;
     outline: var(--outline-hover);
 }
 
 .products .product-slider .box img{
     height: 20rem;
 }
 
 .products .product-slider .box h3{
     font-size: 2.5rem;
     color: var(--black);
 }
 
 .products .product-slider .box .stars i{
     font-size: 1.7rem;
     color: var(--black);
     padding:.5rem 0 ;
 }
 
 

 
 .review .review-slider{
     padding: 1rem;
 }
 
 .review .review-slider .box{
     background: #f2f2f2;
     border-radius: .5rem;
     text-align: center;
     padding: 3rem 2rem;
     outline-offset: -1rem;
     outline: var(--outline);
     box-shadow: var(--box-shadow);
     transition: .2s linear;
 }
 
 .review .review-slider .box:hover{
     outline: var(--outline-hover);
     outline-offset: 0rem;
 }
 
 .review .review-slider .box img{
     height: 10rem;
     width: 10rem;
     border-radius: 50%;
 }
 
 .review .review-slider .box p{
     padding: 1rem 0;
     line-height: 1.8;
     color: var(--light-color);
     font-size: 1.5rem;
 }
 
 .review .review-slider .box h3{
     padding-bottom: .5rem;
     color: var(--black);
     font-size: 2.2rem;
 }
 
 .review .review-slider .box .stars i{
     color: var(--black);
     font-size: 1.7rem;
 }
 
 

 
 
 .footer{
     padding-top: 50px;
     background: #eee;
     box-shadow: var(--box-shadow );
     text-decoration: none;
 }
 
 .footer .box-container{
     display: grid;
     grid-template-columns: repeat(auto-fit,minmax(25rem,1fr));
     gap: 1.5rem;
 }
 
 .footer .box-container .box h3{
     font-size: 2.5rem;
     color: var(--black);
     padding: 1rem 0;
 }
 
 .footer .box-container .box h3 i{
     color: var(--black);
 }
 
 .footer .box-container .box .links{
     display: block;
     font-size: 1.5rem;
     color: var(--light-color);
     padding: 1rem 0;
 }
 
 .footer .box-container .box .links i{
     color: var(--black);
     padding-right: .5rem;
 }
 
 .footer .box-container .box .links:hover i{
     padding-right: 2rem;
     text-decoration: none;
 }
 
 .footer .box-container .box p{
     line-height: 1.8;
     font-size: 1.5rem;
     color: var(--light-color);
     padding: 1rem 0;
 }
 
 .footer .box-container .box .share a{
     height: 4rem;
     width: 4rem;
     line-height: 4rem;
     border-radius: .5rem;
     font-size: 2rem;
     color: var(--black);
     margin-left: .2rem;
     text-align: center;
 }
 
 .footer .box-container .box .share a:hover{
     background: var(--black);
     color: #f2f2f2;
 }
 
 .footer .box-container .box .email{
     margin: .7rem 0;
     padding: 1rem;
     border-radius: .5rem;
     border: .2rem solid var(--black);
     background: #eee;
     font-size: 1.6rem;
     color: var(--black);
     text-transform: none;
 } 
 
 .footer .box-container .box .payment-img{
     margin-top: 2rem;
     height: 3rem;
 
 }
 
 .footer .credit{
     text-align: center;
     margin-top: 2rem;
     padding:1rem;
     padding-top: 2.5rem;
     font-size: 2rem;
     color: var(--black);
     border-top: var(--border);
 }
 
 .footer .credit span{
     color: var(--black);
 }
 
 
 a{
     text-decoration: none;
 }
 
 
 /*ACABA CSS NILMAR*/
 
     .card-img-top {
         width: 100%;
         height: 15vw;
         object-fit: cover;
     }
     .gallery-block{
         padding-bottom: 60px;
         padding-top: 60px;
     
     }
     .row {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
     margin-right: 0px;
     margin-left: -0px;
     margin-top: 15px;
     }
     .gallery-block .heading{
         margin-bottom: 50px;
         text-align: center;
     }
   
     .gallery-block .heading h2{
         font-weight: bold;
         font-size: 1.4rem;
         text-transform: uppercase;
     }
   
     .gallery-block.cards-gallery h6 {
     font-size: 17px;
     font-weight: bold; 
     }
   
     .gallery-block.cards-gallery .card{
     transition: 0.4s ease; 
     }
   
     .gallery-block.cards-gallery .card img {
     box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); 
     }
   
     .gallery-block.cards-gallery .card-body {
     text-align: center; 
     }
   
     .gallery-block.cards-gallery .card-body p {
     font-size: 15px; 
     }
   
     .gallery-block.cards-gallery a {
     color: #212529; 
     }
   
     .gallery-block.cards-gallery a:hover {
     text-decoration: none; 
     }
   
     .gallery-block.cards-gallery .card {
     margin-bottom: 30px; 
     box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
     }
   
     @media (min-width: 576px) {
   
         .gallery-block .transform-on-hover:hover {
             transform: translateY(-10px) scale(1.02);
             box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important; 
         }
     }


     /*CARTAS BLOGS-PRESENTACIONES*/
 .heading1{
     text-align: center;
     padding: 1.5rem 1rem;
     border-top-left-radius: 20px;
     border-bottom-left-radius: 20px;
     border-top-right-radius: 20px;
     border-bottom-right-radius: 20px;
   }
 
 .blogs .btn{
   display: inline-flex;
   align-items: center;
   justify-content: center;
   gap: 5px;
   background-color: var(--black);
   color: var(--white);
   font-size: 1.3rem;
   font-weight: bold;
   padding: 10px 20px;
   border-radius: 5px;
   text-decoration: none;
   transition: background-color 0.3s ease-out;
   box-sizing: border-box; /* Agregamos esta propiedad */
 }
 .blogs .btn:hover{
       background: var(--black);
       border: .2rem solid var(--white);
       color: #f2f2f2;
       transition: .5s;
       transform: scale(1.2); /* corregimos la propiedad scale */
       width: 120%; /* añadimos la propiedad width */
 }
 
 .blogs .box-container{
     display: grid;
     grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
     gap: 1.5rem;
 }
 
 .blogs .box-container .box{
     overflow: hidden;
     border-radius: .5rem;
     box-shadow: var(--box-shadow);
     background: #f2f2f2;
     max-width: 50rem;
 }
 
 .blogs .box-container .box img{
     height: 25rem;
     width: 100%;
     object-fit: cover;
 }
 
 .blogs .box-container .box .content{
     padding: 2rem;
 }
 
 .blogs .box-container .box .content .icons{
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding-bottom: 1.5rem;
     margin-bottom: 1rem;
     border-bottom: var(--border);
 }
 
 .blogs .box-container .box .content .icons a{
     color: var(--light-color);
     font-size: 1.5rem;
 }
 
 .blogs .box-container .box .content .icons a:hover{
     color: var(--black);
 }
 
 .blogs .box-container .box .content .icons a i{
     color: var(--black);
     padding-right: .5rem;
 }
 
 .blogs .box-container .box .content h3{
     line-height: 1.8;
     color: var(--black);
     font-size: 2.2rem;
     padding: .5rem 0;
     height: 7.5rem;
 }
 
 .blogs .box-container .box .content p{
     line-height: 1.8;
     color: var(--light-color);
     font-size: 1.5rem;
     padding: .5rem 0;
 }
 /*FIN CARTAS BLOGS-PRESENTACIONES*/

 .box .btn{
   display: inline-flex;
   align-items: center;
   justify-content: center;
   gap: 5px;
   background-color: var(--black);
   color: var(--white);
   font-size: 1.3rem;
   font-weight: bold;
   padding: 10px 20px;
   border-radius: 5px;
   text-decoration: none;
   transition: background-color 0.3s ease-out;
   box-sizing: border-box; /* Agregamos esta propiedad */
 }
 
 .box .btn:hover {
       background: var(--white);
       border: .2rem solid var(--white);
       color: var(--black);
       transition: .5s;
       transform: scale(1.2); /* corregimos la propiedad scale */
       width: 120%; /* añadimos la propiedad width */
   }
   
   /*Arreglo botones de Admin*/
   .mensaje {
           display: none;
           margin-right: 5px;
           font-weight: normal;
           font-size: 10px;
           text-transform: uppercase;
       }
       
       .btn:hover .mensaje {
           transition: .5s;
           display: block;
       }
     /*Fin botones admin */

   </style>
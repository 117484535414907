import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import App from "./App.vue";
import "@fortawesome/fontawesome-free/css/all.css";

import Index from "./components/principal.vue";



import Tutoriales from "./components/Tutoriales.vue"; // Importa el componente Tutoriales




import PaginaInstitucion from "./components/PaginaInstitucion.vue"; // Importa el componente PaginaInstitucion

import ListaInstituciones from "./components/ListaInstituciones.vue";

import CalendarioCharlas from "./components/CalendarioCharlas.vue"; // Importa el componente Calendario charlas

import ListaCharlas from "./components/ListaCharlas.vue"; // Importa el componente InscripcionForm

import PaginaContactos from "./components/PaginaContactos.vue";

import PerfilUsuario from "./components/perfilUsuario.vue";

import LogIn from "./components/LogIn.vue";

import AnalisisRiesgos from "./components/Componentes_admin/AnalisisRiesgos.vue"

import EditarInstituciones from "./components/Componentes_admin/EditarInstituciones.vue";
import EditarCharlas from "./components/Componentes_admin/EditarCharlas.vue";
import Estadisticas from "./components/Componentes_admin/EstadisticasGrafico.vue";
import CalendarioRecordatorios from "./components/Componentes_admin/CalendarioRecordatorios.vue";

import PaginaDashboard from "./components/Componentes_admin/PaginaDashboard.vue";

//Prime Vue
import PrimeVue from "primevue/config";
import 'primevue/resources/themes/saga-blue/theme.css'; // Tema de PrimeVue (Puedes elegir otro tema si lo prefieres)
import 'primevue/resources/primevue.min.css'; // Estilos globales de PrimeVue

//componentes prime vue
import InputPassword from "primevue/password";
import FloatLabel from 'primevue/floatlabel';


import AdministrarEstudiantes from "./components/Componentes_admin/AdministrarEstudiantes.vue";


import axios from "axios";
import Cookies from "js-cookie";
import './assets/main.css'


const routes = [
  {
    name: "CalendarioRecordatorios",
    path: "/calendarioRecordatorios",
    component: CalendarioRecordatorios,
  },

  {
    name: "PaginaDashboard",
    path: "/PaginaDashboard",
    component: PaginaDashboard,
  },
  {
    path: "/estadisticas",
    name: "Estadisticas",
    component: Estadisticas,
  },

  {
    path: "/EditarCharlas/:Id_Institucion",
    name: "EditarCharlas",
    component: EditarCharlas,
    props: true,
  },
  {
    path: "/contactos",
    name: "PaginaContactos",
    component: PaginaContactos,
  },

  {
    path: "/lista_charlas",
    name: "ListaCharlas",
    component: ListaCharlas,
  },

  {
    path: "/calendario_charlas",
    name: "CalendarioCharlas",
    component: CalendarioCharlas,
  },
  {
    name: "Index",
    path: "/",
    component: Index,
  },

  {
    path: "/tutoriales",
    name: "Tutoriales",
    component: Tutoriales,
  },

  {
    path: "/instituciones",
    name: "ListaInstituciones",
    component: ListaInstituciones,
  },
  {
    path: "/PaginaInstitucion/:id",
    name: "PaginaInstitucion",
    component: PaginaInstitucion,
    props: true,
  },

  {
    name: "LogIn",
    path: "/login",
    component: LogIn,
  },

  {
    name: "perfilUsuario",
    path: "/MiPerfil",
    component: PerfilUsuario,
  },

  {
    name: "administrarInstituciones",
    path: "/administrarInstituciones",
    component: EditarInstituciones,
  },
  
    {
      name: "administrarEstudiantes",
      path: "/administrarEstudiantes",
      component: AdministrarEstudiantes,
    },
  {
    name: "AnalisisRiesgos",
    path: "/analisisRiesgos",
    component: AnalisisRiesgos,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

axios.interceptors.request.use(
  (config) => {
    const token =Cookies.get("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    console.log("Hubo un error vuelva a iniciar sesión");
    return Promise.reject(error);
  }
);




createApp(App).use(router).use(PrimeVue, { ripple: true }).component("InputPassword", InputPassword).component("FloatLabel", FloatLabel).mount("#app");



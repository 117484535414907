<template>
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
    crossorigin="anonymous"
  />
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
    crossorigin="anonymous"
  />

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
  />
  <AppNavbar />
  <section style="padding-top: 100px; min-height: 200px"></section>
  <EstadisticasInscripciones />

  
 <br />

  <AppFooter />
</template>

<script>

import AppNavbar from "./navbar-admin.vue";
import AppFooter from "./footer-admin.vue";
import EstadisticasInscripciones from "./Tableau/EstadisticasInscripciones.vue";
import { rutaString, puertoString } from "@/assets/apiconfig";



export default {
  components: { AppNavbar, AppFooter, EstadisticasInscripciones },

  data: () => ({
    superUsuario: sessionStorage.getItem("tipo") === null ? -1 : sessionStorage.getItem("tipo"),
    loaded: false,
    chartData: null,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          display: true,
        },
      },
    },
    responseData: null,
    selectedFilter: "totalInscritos",
    chartType: "bar",
    selectedChartType: "bar",

    ruta: rutaString,
    puerto: puertoString,
  }),
  async mounted() {
    /**SCRIPTS NAVBAR */
    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });

    window.addEventListener("scroll", () => {
      navbar.classList.remove("active");
    });

    this.loaded = false;

    
    if (this.superUsuario != 1){
      alert("Acceso denegado");
      this.$router.push("/");
    } else {
      this.loaded = false;


   

    }
    
    
  },

  
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.chart-container {
  width: 600px;
  height: 600px;
}

.filters-container {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

select {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
}

label {
  font-size: 1rem;
  margin-bottom: 0.2rem;
}
</style>

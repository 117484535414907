<template>
      <AppNavbar />
      <div class="spacer" style="height: 11rem;">

      </div>

    <div class="bg-white-500" style="margin-bottom: 5rem;">
      <div class="text-center py-12">
        <h1 class="text-4xl font-bold text-blue-900">
          Tutoriales Conecta tu futuro
        </h1>
      </div>
      <div class="max-w-full mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 px-4" style="padding-left: 5rem; padding-right: 5rem;">
        <div class="bg-white-100 p-8">
            <iframe             class="w-full h-full object-cover rounded-lg"  width="600" height="600" src="https://www.youtube.com/embed/8ES7aj80Bcw?si=_2R4Tg06PwaZJZ8Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </div>
        <div class="bg-fuchsia-600 p-8 text-center" style="height: 40rem;">
          <h2 class="text-2xl font-bold text-blue-100 mb-4">
            Qué es Conecta tu futuro?
          </h2>
          <p class="text-blue-900 mb-6">
            Aprende sobre la plataforma y cómo te puede ayudar
          </p>
          <button @click="goTo('/')"  class="bg-white text-blue-900 border border-blue-900 py-2 px-4 rounded-full">
            Ver Más
          </button>
        </div>
        
        
        
        
        
        <div class="bg-sky-500 p-8 text-center" style="height: 40rem;">
          <h2 class="text-2xl font-bold text-blue-100 mb-4">
            Mi Calendario
          </h2>
          <p class="text-blue-900 mb-6">
            Descubre cómo puedes usar el calendario de charlas
          </p>
          <button @click="goTo('/calendario_charlas')" class="bg-white text-blue-900 border border-blue-900 py-2 px-4 rounded-full">
            Calendario
          </button>
        </div>
        <div class="bg-white-100 p-8">
          
          <iframe             class="w-full h-full object-cover rounded-lg"  width="600" height="600" src="https://www.youtube.com/embed/YCJTep9sT1Q?si=lgMzeT5Mnw_0R2Qy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div class="bg-white-100 p-8">
            <iframe             class="w-full h-full object-cover rounded-lg"  width="600" height="600" src="https://www.youtube.com/embed/rdODFgbb74s?si=jb2dHSF9dNkaqrH3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </div>
        <div class="bg-fuchsia-600 p-8 text-center" style="height: 40rem;">
          <h2 class="text-2xl font-bold text-blue-100 mb-4">
            Ingreso a Instituciones
          </h2>
          <p class="text-blue-900 mb-6">
            Aprende cómo puedes ingresar a las instituciones
          </p>
          <button @click="goTo('/instituciones')"  class="bg-white text-blue-900 border border-blue-900 py-2 px-4 rounded-full">
            Instituciones
          </button>
        </div>



        <div class="bg-sky-500 p-8 text-center" style="height: 40rem;">
          <h2 class="text-2xl font-bold text-blue-100 mb-4">
            Cómo Ingresas al Sistema?
          </h2>
          <p class="text-blue-900 mb-6">
            Aprende cómo puedes ingresar al sistema
          </p>
          <button @click="goTo('/login')" class="bg-white text-blue-900 border border-blue-900 py-2 px-4 rounded-full">
            Log In
          </button>
        </div>
        <div class="bg-white-100 p-8">
          
          <iframe             class="w-full h-full object-cover rounded-lg"  width="600" height="600" src="https://www.youtube.com/embed/eGZbNJVkR6E?si=z4JQr8e5_uz-cuZ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div class="bg-white-100 p-8">
            <iframe             class="w-full h-full object-cover rounded-lg"  width="600" height="600" src="https://www.youtube.com/embed/O4_FKA5efp4?si=ejGhz5px8BRK0cps" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </div>
        <div class="bg-fuchsia-600 p-8 text-center" style="height: 40rem;">
          <h2 class="text-2xl font-bold text-blue-100 mb-4">
            Charlas Con Profesionales
          </h2>
          <p class="text-blue-900 mb-6">
            Aprende de los profesionales en el área
          </p>
          <button @click="goTo('/lista_charlas')" class="bg-white text-blue-900 border border-blue-900 py-2 px-4 rounded-full">
            Charlas
          </button>
        </div>
      </div>
    </div>
    <AppFooter />

  </template>
  
  <script>
    import AppNavbar from '@/components/navbar.vue'
    import AppFooter from '@/components/footer.vue'

  export default {
    name: 'PinterestDiscover',
    components: {
      AppNavbar,
      AppFooter
    },
    methods: {
      goTo(path) {
        this.$router.push(path)
      }
    },
    mounted(){
      const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });
    }
  }
  </script>
  
  <style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
  
  body {
    font-family: 'Roboto', sans-serif;
  }
  </style>
  
  
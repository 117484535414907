<template>
  <popup-modal ref="popup">
    <div
      class="total"
      style="
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 10%;
        border-radius: 25px;
      "
    >
      <div class="garbage" style="margin-left: 32%">
        <img
          src="https://cdn-icons-png.flaticon.com/512/2247/2247728.png"
          alt=""
          style="width: 50%; height: 50%"
        />
      </div>
      <h2 style="font-size: 5rem" class="title">¿{{ title }}?</h2>
      <p class="message">¿{{ message }}</p>
      <div class="btns" style="width: 100%">
        <button
          style="margin: 0 5%; width: 40%"
          class="cancel-btn"
          @click="_cancel"
        >
          {{ cancelButton }}
        </button>
        <button
          style="margin: 0 5%; width: 40%"
          class="ok-btn"
          @click="_confirm"
        >
          {{ okButton }}
        </button>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "ConfirmDialogue",

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Volver Atras", // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User canceled the dialogue'))
    },
  },
};
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  padding: 0.5em 1em;
  background-color: #56dc89;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.ok-btn:hover {
  background-color: #4cc190;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #357c90;
  border: 2px solid #0eb9c5;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.cancel-btn:hover {
  background-color: #b1c2d5;
}

.title {
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.message {
  font-size: 18px;
  text-align: center;
}
</style>

<template>
  <!--INICIO INICIO DE SESIÓN FINAL-->
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
        crossorigin="anonymous"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
      />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
        crossorigin="anonymous"
      />

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
      />

      <link
        href="https://fonts.googleapis.com/css?family=Raleway:900"
        rel="stylesheet"
      />

      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <title>Baristas</title>
    </head>
    <AppNavbar />
    <body style="overflow-y: scroll;">
      <!--INICIO BACKGROUND-->
      <div class="bod" v-show="outText">
        <div class="text-container">
          <h1>INICIA SESIÓN</h1>
        </div>
      </div>
      <!--FIN-->
      <div class="bg" v-show="showText"></div>
      <div class="bg bg2" v-show="showText"></div>
      <div class="bg bg3" v-show="showText"></div>
      <div
        class="content"
        style="background-color: transparent; border-color: transparent"
        v-show="showText"
      >
        <div class="container">
          <form class="signUp" @submit.prevent="register">
            <!--LOGIN-->
            <h3 style="margin-bottom: 5px">Bienvenido De Nuevo!</h3>
            <h3 style="margin: 1px; padding: 1px; color: #F93409; font-size: 20px;" v-if="this.variableTiempoBloqueo">{{ this.variableTiempoBloqueo }}</h3>
            <h3 style="font-size: 15px; margin: 0; padding: 0">
              Inicia Sesión
            </h3>
            <h3 style="font-size: 15px; margin: 0; padding: 0">
              Ingresa tu Carnet Identidad
            </h3>
            <h3
              style="
                font-size: 15px;
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
              "
            >
              en conjunto con tu contraseña
            </h3>
            <h3
              style="
                font-size: 15px;
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
              "
            >
              Las cuentas de la feria del empleo ya no son válidas.
            </h3>
            <input
              type="text"
              placeholder="Ingrese Carnet de Identidad"
              autocomplete="off"
              required
              v-model="CarnetIngresado"
            />
            <input
              type="password"
              placeholder="Ingrese contraseña"
              required
              v-model="PasswordIngresado"
            />

            <h3
              style="
                font-size: 15px;
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
                cursor: pointer;
              "
              @click="openRecoverModal"
            >
              ¿Olvidaste tu contraseña? Recupera tu contraseña
            </h3>
            <!--FIN LOGIN-->

            <button class="form-btn sx log-in" type="button">
              Ir a Registrarse
            </button>
            <button class="form-btn dx" type="submit" @click="doLogin" v-if="!variableTiempoBloqueo || variableTiempoBloqueo == null || variableTiempoBloqueo ==''">
              Iniciar Sesión
            </button>
          </form>
          <form class="signIn" @submit.prevent="login">
            <!--REGISTRARSE-->
            <h3>Crea Tu Nueva Cuenta!</h3>
            <input
              type="text"
              placeholder="Ingrese Nombre"
              autocomplete="off"
              required
              v-model="NombreIngresado"
            />
            <input
              class="w100"
              type="email"
              placeholder="Ingrese Correo"
              required
              autocomplete="off"
              style="text-transform: none"
              v-model="EmailIngresado"
            />
            <input
              type="text"
              placeholder="Ingrese Carnet de Identidad"
              required
              v-model="CarnetIngresado"
            />
            <input
              type="text"
              placeholder="Ingrese Número Celular"
              required
              v-model="NumeroIngresado"
            />
            <select v-model="carreraIngresada">
              <option value="" disabled selected>Seleccione su carrera</option>
              <option
                v-for="carrera in carreras"
                :key="carrera"
                :value="carrera"
              >
                {{ carrera }}
              </option>
            </select>
            <FloatLabel>
              <InputPassword
                inputId="password"
                type="password"
                name="password"
                class="inputPassword"
                v-model="password"
                weakLabel="Débil"
                mediumLabel="Media"
                strongLabel="Fuerte"
                inputStyle="text-transform: initial;"
                veryStrongLabel="Perfecto!"
                promptLabel="Contraseña"
                panelStyle="background: #f4f4f4; color: #333; border: 1px solid #ccc; padding: 10px; border-radius: 5px; width: 250px; max-height: 200px; overflow-y:auto; margin: 10px 0;"
                toggleMask
                mediumRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})"
                strongRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,;:'])(?=.{12,})"
              
              >
                <template #footer>
                  <p style="margin: 0; font-size: 1.1rem; color: #F93409;" v-if="this.result.score==0">
                    Complejidad: MUY DÉBIL.
                    <span v-if="this.result.feedback.suggestions.length>0">
                      Sugerencias:
                      <ul style="
                      line-height: 1.2;
                      font-size: 1rem;
                      list-style-type: square;
                      padding-left: 5px;



                      "
                      v-if="this.result.feedback.suggestions"
                    >
                        <li v-for="sugerencia in this.result.feedback.suggestions" v-bind:key="sugerencia">{{ sugerencia }}</li>
                        <li v-if="this.result.feedback.warning" >{{ this.result.feedback.warning }}</li>

                      </ul>
                    </span>
                  </p>
                  <p style="margin: 0; font-size: 1.1rem; color:tomato ;" v-if="this.result.score==1">
                    Complejidad: MALA
                    <span v-if="this.result.feedback.suggestions.length>0">
                      Sugerencias:
                      <ul style="
                      line-height: 1.2;
                      font-size: 1rem;
                      list-style-type: square;
                      padding-left: 5px;



                      "
                      v-if="this.result.feedback.suggestions"
                    >
                        <li v-for="sugerencia in this.result.feedback.suggestions" v-bind:key="sugerencia">{{ sugerencia }}</li>
                        <li v-if="this.result.feedback.warning" >{{ this.result.feedback.warning }}</li>

                      </ul>
                    </span>
                  </p>
                  <p style="margin: 0; font-size: 1.1rem; color: cornflowerblue;" v-if="this.result.score==2">
                    Complejidad: REGULAR
                    <span v-if="this.result.feedback.suggestions.length>0">
                      Sugerencias:
                      <ul style="
                      line-height: 1.2;
                      font-size: 1rem;
                      list-style-type: square;
                      padding-left: 5px;



                      "
                      v-if="this.result.feedback.suggestions"
                    >
                        <li v-for="sugerencia in this.result.feedback.suggestions" v-bind:key="sugerencia">{{ sugerencia }}</li>
                        <li v-if="this.result.feedback.warning" >{{ this.result.feedback.warning }}</li>

                      </ul>
                    </span>
                  </p>
                  <p style="margin: 0; font-size: 1.1rem; color: turquoise;" v-if="this.result.score==3">
                    Complejidad: BUENA
                    <span v-if="this.result.feedback.suggestions.length>0">
                      Sugerencias:
                      <ul style="
                      line-height: 1.2;
                      font-size: 1rem;
                      list-style-type: square;
                      padding-left: 5px;



                      "
                      v-if="this.result.feedback.suggestions"
                    >
                        <li v-for="sugerencia in this.result.feedback.suggestions" v-bind:key="sugerencia">{{ sugerencia }}</li>
                        <li v-if="this.result.feedback.warning" >{{ this.result.feedback.warning }}</li>

                      </ul>
                    </span>
                  </p>
                  <p style="margin: 0; font-size: 1.1rem; color: forestgreen;" v-if="this.result.score==4">
                    Complejidad: FUERTE
                    <span v-if="this.result.feedback.suggestions.length>0">
                      Sugerencias:
                      <ul style="
                      line-height: 1.2;
                      font-size: 1rem;
                      list-style-type: square;
                      padding-left: 5px;



                      "
                      v-if="this.result.feedback.suggestions"
                    >
                        <li v-for="sugerencia in this.result.feedback.suggestions" v-bind:key="sugerencia">{{ sugerencia }}</li>
                        <li v-if="this.result.feedback.warning" >{{ this.result.feedback.warning }}</li>

                      </ul>
                    </span>
                  </p>

                  <p style="margin: 0; font-size: 1.4rem">
                    La contraseña debe contener:
                  </p>
                  <ul
                    style="
                      line-height: 1.5;
                      font-size: 1.2rem;
                      list-style-type: none;
                    "
                  >
                    <li>Una minúscula</li>
                    <li>Una mayúscula</li>
                    <li>Un número</li>
                    <li>Un caracter especial: !@#$%^&*.,;:'</li>
                    <li>Debe ser de al menos 12 caracteres</li>
                  </ul>
                </template>
              </InputPassword>
              <label
                for="password"
                class="password-label"
                v-show="!password.length > 0"
                >Contraseña</label
              >
            </FloatLabel>
            <FloatLabel>
              <InputPassword
                inputId="password"
                type="password"
                name="password"
                class="inputPassword"
                v-model="PasswordConfirmar"
                weakLabel="Débil"
                mediumLabel="Media"
                strongLabel="Fuerte"
                inputStyle="text-transform: initial;"
                veryStrongLabel="Perfecto!"
                promptLabel="Confirme su contraseña"
                panelStyle="background: #f4f4f4; color: #333; border: 1px solid #ccc; padding: 10px; border-radius: 5px; width: 250px; margin: 10px 0;"
                toggleMask
                mediumRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})"
                strongRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,;:'])(?=.{12,})"
              
              >
                <template #footer>
                

                  <p style="margin: 0; font-size: 1.4rem">
                    La contraseña debe contener:
                  </p>
                  <ul
                    style="
                      line-height: 1.5;
                      font-size: 1.2rem;
                      list-style-type: none;
                    "
                  >
                    <li>Una minúscula</li>
                    <li>Una mayúscula</li>
                    <li>Un número</li>
                    <li>Un caracter especial: !@#$%^&*.,;:'</li>
                    <li>Debe ser de al menos 12 caracteres</li>
                  </ul>
                </template>
              </InputPassword>



              <label
                for="password"
                class="password-label"
                v-show="!PasswordConfirmar.length > 0"
                >Confirmar Contraseña</label
              >
        
            </FloatLabel>
            <!--FIN REGISTRARSE-->
            <button class="form-btn sx back" type="button">
              Ir a Iniciar Sesión
            </button>

            <button class="form-btn dx" @click="crearCuenta">
              Registrarse
            </button>
          </form>
        </div>
      </div>
    </body>
    <ModalRecoverPassword
      v-if="showModalRecover"
      @close="closeModalRecover"
      @next="openModalFormRecover"
    />
    <ModalFormRecoverPassword
      v-if="showFormRecoverModal"
      @close="closeModalFormRecover"
    />
  </html>

  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
  <!--FIN Inicio Sesión Final-->
</template>

<script>
import $ from "jquery";
import AppNavbar from "./navbar.vue";
import ModalRecoverPassword from "./modals/RecuperacionModal.vue";
import ModalFormRecoverPassword from "./modals/FormRecoverModal.vue";
import { rutaString, puertoString } from "@/assets/apiconfig";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import Cookies from "js-cookie";
import { zxcvbn, zxcvbnOptions,debounce } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEsEsPackage from '@zxcvbn-ts/language-es-es'


export default {
  setup() {
    const options = {
      translations: zxcvbnEsEsPackage.translations,
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEsEsPackage.dictionary,
      },



    }

    zxcvbnOptions.setOptions(options)

  },
 

  watch: {
    password() {
      this.debounce()
    },
     
  },


  data() {
    return {
      password: '',
      result: null,
      debounce: debounce(this.useZxcvbn, 200),


      bloqueoFaltanteSegundos: 0,




      showModalRecover: false,
      showFormRecoverModal: false,
      carreraIngresada: "",
      carreras: [
        "ADMINISTRACIÓN DE EMPRESAS",
        "ADMINISTRACIÓN TURÍSTICA",
        "ANTROPOLOGÍA",
        "ARQUITECTURA",
        "ARQUITECTURA DE INTERIORES",
        "CIENCIAS POLÍTICAS Y RELACIONES INTERNACIONALES",
        "COMUNICACIÓN MULTIMEDIA",
        "COMUNICACIÓN SOCIAL",
        "CONTADURÍA PÚBLICA",
        "CREACIÓN Y DESARROLLO DE EMPRESAS",
        "DERECHO",
        "DISEÑO DIGITAL",
        "DISEÑO GRÁFICO Y COMUNICACIÓN VISUAL",
        "ECONOMÍA",
        "ECONOMÍA E INTELIGENCIA DE NEGOCIOS",
        "ENFERMERÍA",
        "FILOSOFÍA Y LETRAS",
        "GESTIÓN Y EMPRENDIMIENTO",
        "INGENIERÍA AMBIENTAL",
        "INGENIERÍA BIOMÉDICA",
        "INGENIERÍA BIOQUÍMICA Y DE BIOPROCESOS",
        "INGENIERÍA CIVIL",
        "INGENIERÍA COMERCIAL",
        "INGENIERÍA DE AGRONEGOCIOS",
        "INGENIERÍA DE SISTEMAS",
        "INGENIERÍA DE TELECOMUNICACIONES",
        "INGENIERÍA EMPRESARIAL",
        "INGENIERÍA EN BIOTECNOLOGÍA",
        "INGENIERÍA EN ENERGÍA",
        "INGENIERÍA EN INNOVACIÓN EMPRESARIAL",
        "INGENIERÍA EN INTERNET DE LAS COSAS",
        "INGENIERÍA EN LOGÍSTICA Y ANALÍTICA DE LA CADENA DE SUMINISTRO",
        "INGENIERÍA EN MULTIMEDIA E INTERACTIVIDAD DIGITAL",
        "INGENIERÍA FINANCIERA",
        "INGENIERÍA INDUSTRIAL",
        "INGENIERÍA MECATRÓNICA",
        "INGENIERÍA QUÍMICA",
        "KINESIOLOGÍA Y FISIOTERAPIA",
        "MARKETING Y MEDIOS DIGITALES",
        "MEDICINA",
        "NEGOCIOS INTERNACIONALES",
        "NEGOCIOS Y CIENCIA DE DATOS",
        "NEGOCIOS Y DISEÑO",
        "NEGOCIOS Y TECNOLOGÍAS DE INFORMACIÓN",
        "ODONTOLOGÍA",
        "PSICOLOGÍA",
        "PSICOPEDAGOGÍA",
      ],
      showText: false,
      outText: true,
      email: "",



      NombreIngresado: "",
      EmailIngresado: "",
      CarnetIngresado: "",
      NumeroIngresado: "",
      PasswordIngresado: "",
      PasswordConfirmar: "",

      isLoading: false,
      fullPage: true,

      ruta: rutaString,
      puerto: puertoString,
      variableTiempoBloqueo: "",

    };
  },
  
  methods: {
    setResult(result) {
      this.result = result
    },
    async useZxcvbn() {
      if (this.password) {
        this.result = await zxcvbn(this.password)
      } else {
        this.result = null
      }
    },












    openRecoverModal() {
      this.showModalRecover = true;
    },
    closeModalRecover() {
      this.showModalRecover = false;
    },
    openModalFormRecover() {
      this.showModalRecover = false;
      this.showFormRecoverModal = true;
    },
    closeModalFormRecover() {
      this.showFormRecoverModal = false;
    },
    verificarCamposTexto() {
      if (
        this.NombreIngresado.trim() === "" ||
        this.EmailIngresado.trim() === "" ||
        this.CarnetIngresado.trim() === "" ||
        this.NumeroIngresado.trim() === "" ||
        this.password.trim() === "" ||
        this.PasswordConfirmar.trim() === "" ||
        this.carreraIngresada.trim() === ""
      ) {
        return false; // Al menos uno de los campos está vacío
      }
      return true; // Todos los campos tienen texto
    },
    alertaFalsa() {
      alert(
        "Los Registros a la pagina terminaron el 21/7/2023, gracias por participar. Si tienes algun problema comunicate con los numeros U.S.E.I."
      );
    },

    async crearCuenta() {
      this.PasswordIngresado = this.password;

      if (!this.verificarCamposTexto()) {
        alert("Por favor, complete todos los campos.");
        return;
      }



      if (this.PasswordIngresado === this.PasswordConfirmar) {
        if (this.PasswordIngresado.length < 12) {
          alert("La contraseña debe tener al menos 12 caracteres");
          return;
        } else if (!this.PasswordIngresado.match(/[a-z]/g)) {
          alert("La contraseña debe tener al menos una letra minúscula");
          return;
        } else if (!this.PasswordIngresado.match(/[A-Z]/g)) {
          alert("La contraseña debe tener al menos una letra mayúscula");
          return;
        } else if (!this.PasswordIngresado.match(/[0-9]/g)) {
          alert("La contraseña debe tener al menos un número");
          return;
        } else if (!this.PasswordIngresado.match(/[!@#$%^&*.,;:']/g)) {
          alert(
            "La contraseña debe tener al menos un caracter especial: !@#$%^&*.,;:'"
          );
          return;
        }

        this.isLoading = true;

        try {
          const cuenta = await axios.post(
            `http://${this.ruta}${this.puerto}/registro`,
            {
              ci: this.CarnetIngresado,
              nombre: this.NombreIngresado,
              correo: this.EmailIngresado,
              password: this.PasswordIngresado,
              telefono: this.NumeroIngresado,
              carrera: this.carreraIngresada,
              tipo: 0,
            }
          );
          this.isLoading = false;

          if (cuenta.data.data != 1) {
            alert("Cuenta creada con éxito");
          } else {
            alert("Cuenta ya existente");
          }

          this.doLogin();
        } catch (err) {
          console.log(err);
        }
      } else {
        alert("Las contraseñas no coinciden");
      }
    },

    async doLogin() {
      this.isLoading = true;

      try {
        /*
        const currentTime = new Date().getTime();
        const lastSuccessfulLoginTime = localStorage.getItem(
          "lastSuccessfulLoginTime"
        );

        // Verificar si ha pasado más de 30 segundos desde el último inicio de sesión exitoso
        if (
          lastSuccessfulLoginTime &&
          currentTime - lastSuccessfulLoginTime > 30 * 1000
        ) {
          localStorage.removeItem("loginAttempts"); // Reiniciar contador de intentos
        }

        // Verificar el número de intentos almacenado en localStorage
        let loginAttempts =
          parseInt(localStorage.getItem("loginAttempts")) || 0;

        // Si el número de intentos es mayor o igual a 3, mostrar alerta y salir de la función
        if (loginAttempts >= 3) {
          alert(
            "Ha excedido el límite de intentos de inicio de sesión. Por favor, espere 30 segundos antes de intentarlo de nuevo."
          );
          localStorage.removeItem("loginAttempts"); // Reiniciar contador de intentos
          localStorage.setItem("fechaHoraBloqueo", currentTime);
          this.isLoading = false;
          this.tiempoBloqueo();
          return;
        }*/

        const cuenta = await axios.post(
          `http://${this.ruta}${this.puerto}/login`,
          {
            ci: this.CarnetIngresado,
            password: this.PasswordIngresado,
          }
        );

        this.isLoading = false;

          if(cuenta.data.data ==  -2 ){

            this.bloqueoFaltanteSegundos =  cuenta.data.time;
            alert("Cuenta bloqueada, intente de nuevo en " + this.bloqueoFaltanteSegundos + " segundos");
            //calcular la hora de desbloqueo y guardarla en localStorage

            localStorage.setItem("bloqueoFaltanteSegundos", this.bloqueoFaltanteSegundos);

            this.tiempoBloqueoSegundos();
            return;

          }


        if (cuenta.data.data === 1) {
          alert(`Bienvenido ${cuenta.data.nombre}`);

          const usuario = {
            nombre: cuenta.data.nombre,
            correo: cuenta.data.correo,
            tipo: cuenta.data.tipo,
            ci: this.CarnetIngresado,
            token: cuenta.data.token,
          };

          // Restablecer el contador de intentos después de un inicio de sesión exitoso
          //localStorage.setItem("loginAttempts", "0"); // Reiniciar contador como cadena
          //localStorage.setItem("lastSuccessfulLoginTime", currentTime);

          this.submitForm(usuario);
        } else {
          alert("Cuenta inexistente");
          // Incrementar el contador de intentos y guardarlo en localStorage

          //loginAttempts++;

          //localStorage.setItem("loginAttempts", loginAttempts.toString());
          /*
          if(loginAttempts == 3){
            localStorage.removeItem("loginAttempts");
            localStorage.setItem("fechaHoraBloqueo", currentTime);
            this.tiempoBloqueo();
          }*/

        }
      } catch (err) {
        console.log(err);
      }
    },

    submitForm(usuario) {
      // Aquí iría la lógica para enviar los datos a un servidor y verificar si las credenciales son correctas
      // Si son correctas, redirigir al usuario a la página correspondiente
      // Si son incorrectas, mostrar un mensaje de error al usuario
      sessionStorage.setItem("nombre", usuario.nombre);
      sessionStorage.setItem("correo", usuario.correo);
      sessionStorage.setItem("tipo", usuario.tipo);
      sessionStorage.setItem("ci", usuario.ci);
      // que expire en 2 horas
      Cookies.set("token", usuario.token, { expires: 2 / 24 });

      if (usuario.tipo === 1) {
        this.$router.push("/PaginaDashboard");
      } else if (usuario.tipo === 0) {
        this.$router.push("/");
      }

      // if (this.email === "admin@admin" && this.password === "admin") {
      //   sessionStorage.setItem("name", this.email);

      //   this.$router.push("/PaginaDashboard");
      // } else if (this.email === "user@user" && this.password === "user") {
      //   sessionStorage.setItem("name", this.email);

      //   this.$router.push("/");
      // } else {
      //   alert("Credenciales incorrectas");
      // }
    },


    async tiempoBloqueo() {
      const fechaHoraBloqueo = localStorage.getItem("fechaHoraBloqueo");
      const currentTime = new Date().getTime();

      if (fechaHoraBloqueo) {
        const tiempoRestante = 30 - Math.floor((currentTime - fechaHoraBloqueo) / 1000);
        if (tiempoRestante > 0) {
          //devolver solo el tiempo en segundos
          this.variableTiempoBloqueo = tiempoRestante;
          return tiempoRestante;
        } else {
          localStorage.removeItem("fechaHoraBloqueo");
          this.variableTiempoBloqueo = "";
          //recargar la pagina NO CON EL LOCATION RELOAD
          window.location.reload();
        }
      }

      return "";
    },


    async tiempoBloqueoSegundos(){
      const segundosLeft = this.bloqueoFaltanteSegundos;
      if(segundosLeft > 0){
        this.variableTiempoBloqueo = segundosLeft;
        return segundosLeft;

      }else{
        this.variableTiempoBloqueo = "";
        return "";


        }
    },
  },
  mounted() {
    /**SCRIPTS NAVBAR */
    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });

    window.addEventListener("scroll", () => {
      navbar.classList.remove("active");
    });

    setTimeout(() => {
      this.showText = true;
      this.outText = false;
    }, 2500);

    $(".log-in").click(function () {
      $(".signIn").addClass("active-dx");
      $(".signUp").addClass("inactive-sx");
      $(".signUp").removeClass("active-sx");
      $(".signIn").removeClass("inactive-dx");
    });

    $(".back").click(function () {
      $(".signUp").addClass("active-sx");
      $(".signIn").addClass("inactive-dx");
      $(".signIn").removeClass("active-dx");
      $(".signUp").removeClass("inactive-sx");
    });

    if(localStorage.getItem("bloqueoFaltanteSegundos") <= 0){
      localStorage.removeItem("bloqueoFaltanteSegundos");
    }


    this.fechaHoraBloqueo = localStorage.getItem("fechaHoraBloqueo");
    this.bloqueoFaltanteSegundos= localStorage.getItem("bloqueoFaltanteSegundos");
    console.log(this.fechaHoraBloqueo);

    setInterval(() => {

    this.tiempoBloqueoSegundos();
    this.bloqueoFaltanteSegundos = this.bloqueoFaltanteSegundos - 1;
    localStorage.setItem("bloqueoFaltanteSegundos", this.bloqueoFaltanteSegundos);
    if(this.bloqueoFaltanteSegundos <= 0){
      this.bloqueoFaltanteSegundos = 0;
      localStorage.removeItem("bloqueoFaltanteSegundos");
      this.variableTiempoBloqueo = "";
    }

  }, 1000);


  },
  


  
  components: {
    AppNavbar,
    Loading,
    ModalRecoverPassword,
    ModalFormRecoverPassword,
  },
};
</script>

<style scoped>
@import "https://fonts.googleapis.com/css?family=Dosis|Roboto:300,400";
* {
  margin: 0;
  padding: 0;
}

.container {
  position: absolute;
  width: 100%;
  height: auto;
  top: calc(50% - 200px);
  left: calc(50% - 160px);
  animation: scaleAnimation 1s ease-in-out;
  transform-origin: center;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.signUp {
  position: absolute;
  text-align: center;
  background: #fff;
  width: 300px;
  min-height: 400px;
  height: 480px;
  border-radius: 5px;
  padding: 30px 20px 0 20px;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}
.signIn {
  position: absolute;
  text-align: center;
  background: #fff;
  width: 300px;
  height: 600px;
  border-radius: 5px;
  padding: 30px 20px 0 20px;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 12px;
  color: #671f82;
  margin-bottom: 40px;
}

p > span {
  padding-top: 3px;
  display: block;
  font-weight: 400;
  font-size: 9px;
}

h3 {
  font-family: "Dosis";
  font-size: 35px;
  text-transform: uppercase;
  color: #671f82;
  margin-bottom: 30px;
}

input,
button {
  outline: none !important;
}

button.fb {
  border: none;
  background: #671f82;
  width: 160px;
  height: 25px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #671f82;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.3s linear;
}
button.fb:hover {
  background: #fff;
  color: #671f82;
}

button.form-btn {
  position: absolute;
  width: 50%;
  height: 60px;
  border: 0;
  font-family: "Dosis";
  font-size: 15px;
  padding: 1% 2%;
  text-transform: uppercase;
  cursor: pointer;
}





  .signUp {
    position: absolute;
    text-align: center;
    background: #fff;
    width: 300px;
    min-height: 300px;
    height: 400px;
    border-radius: 5px;
    padding: 30px 20px 0 20px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
  }
  .signIn {
    position: absolute;
    text-align: center;
    background: #fff;
    width: 300px;
    height: 598px;
    border-radius: 5px;
    padding: 30px 20px 0 20px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 12px;
    color: #671f82;
    margin-bottom: 40px;
  }

  p > span {
    padding-top: 3px;
    display: block;
    font-weight: 400;
    font-size: 9px;
  }

  h3 {
    font-family: "Dosis";
    font-size: 35px;
    text-transform: uppercase;
    color: #671f82;
    margin-bottom: 30px;
  }

  input,
  button {
    outline: none !important;
  }

  button.fb {
    border: none;
    background: #671f82;
    width: 160px;
    height: 25px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid #671

  }

button.form-btn.sx {
  left: 0;
  border-radius: 0 0 0 5px;
  background-color: #58626c;
  color: #fff;
  transition: all 0.3s linear;
}

button.form-btn.sx:hover {
  background-color: #078bcc;
  color: #fff;
}

button.form-btn.sx.back {
  background-color: #58626c;
  color: #f6f4f7;
}

button.form-btn.sx.back:hover {
  background-color: #0970a2;
  color: #f6f4f7;
}

button.form-btn.dx {
  right: 0;
  border-radius: 0 0 5px 0;
  background-color: #671f82;
  color: #fff;
}
button.form-btn.dx:hover {
  right: 0;
  border-radius: 0 0 5px 0;
  background-color: #6ca9d1;
  color: #fff;
}

input {
  border: none;
  border-bottom: 1px solid #671f82;
  width: 100%;
  text-transform: initial;
  font-family: "Roboto";
  color: #671f82;
  font-size: 21px;
  font-weight: 100;
  margin-bottom: 25px;
}
.inputPassword {
  border: none;
  border-bottom: 1px solid #671f82;
  width: 100%;
  font-family: "Roboto";
  color: #671f82;
  text-align: center;
  font-size: 21px;
  font-weight: 100;
  margin-bottom: 25px;
}
.inputPassword:deep svg {
  width: 2rem;
  height: 2rem;
  top: 20%;
}
label {
  font-family: "Roboto";
  color: #671f82;
  text-align: center;
  font-size: 19px;
  font-weight: 100;
  top: 20%;
}
select {
  border: none;
  border-bottom: 1px solid #671f82;
  width: 100%;
  text-transform: initial;
  font-family: "Roboto";
  color: #671f82;
  text-align: center;
  font-size: 19px;
  font-weight: 100;
  margin-bottom: 25px;
}
::-webkit-input-placeholder {
  color: #671f82;
  font-family: "Roboto";
  font-weight: 100;
}

:-moz-placeholder {
  color: #671f82;
  font-family: "Roboto";
  font-weight: 100;
}

::-moz-placeholder {
  color: #671f82;
  font-family: "Roboto";
  font-weight: 100;
}

:-ms-input-placeholder {
  color: #671f82;
  font-family: "Roboto";
  font-weight: 100;
}

.signIn input,
.signUp .w100 {
  width: 100%;
}

.signIn {
  z-index: 1;
  transform: perspective(100px) translate3d(100px, 0px, -30px);
  opacity: 0.5;
}

.signUp {
  z-index: 2;
}

.active-dx {
  animation-name: foregrounding-dx;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}

.active-sx {
  animation-name: foregrounding-sx;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}

.inactive-dx {
  animation-name: overshadowing-dx;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}

.inactive-sx {
  animation-name: overshadowing-sx;
  animation-duration: 0.9s;
  animation-fill-mode: forwards;
}

@keyframes overshadowing-dx {
  0% {
    z-index: 2;
    transform: perspective(100px) translate3d(0px, 0px, 0px);
    opacity: 1;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
  }
  100% {
    z-index: 1;
    transform: perspective(100px) translate3d(100px, 0px, -30px);
    opacity: 0.5;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  }
}

@keyframes overshadowing-sx {
  0% {
    z-index: 2;
    transform: perspective(100px) translate3d(0px, 0px, 0px);
    opacity: 1;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
  }
  100% {
    z-index: 1;
    transform: perspective(100px) translate3d(-100px, 0px, -30px);
    opacity: 0.5;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  }
}

@keyframes foregrounding-dx {
  0% {
    z-index: 1;
    transform: perspective(100px) translate3d(100px, 0px, -30px);
    opacity: 0.5;
  }
  50% {
    z-index: 2;
    transform: perspective(100px) translate3d(400px, 0px, -30px);
  }
  100% {
    z-index: 2;
    transform: perspective(100px) translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

@keyframes foregrounding-sx {
  0% {
    z-index: 1;
    transform: perspective(100px) translate3d(-100px, 0px, -30px);
    opacity: 0.5;
  }
  50% {
    z-index: 2;
    transform: perspective(100px) translate3d(-400px, 0px, -30px);
  }
  100% {
    z-index: 2;
    transform: perspective(100px) translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

/*ANIMATED BACKGROUND*/

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(
    -60deg,
    #671f82 50%,
    #0970a2 50%,
    #078bcc 50%,
    #6ca9d1 50%
  );
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

.content {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.25em;
  /*box-shadow:0 0 .25em rgba(0,0,0,.25);*/
  box-sizing: border-box;
  left: 50%;
  position: fixed;
  text-align: center;
  top: 49%;
  transform: translate(-50%, -50%);
}

h1 {
  font-family: monospace;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

/*END ANIMATED BACKGROUND*/

/**ANIMACIÓN INICIO PANTALLA */
body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  background-color: #0970a2;
}

.bod {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  height: 100vh;
}

.text-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease;
}

.text-container.fade-out {
  transform: scale(10);
  opacity: 0;
}

.text-container h1 {
  margin: 0;
  font-size: 150px;
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://images.unsplash.com/photo-1606761568499-6d2451b23c66?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80");
  background-repeat: repeat;
  background-clip: text;
  animation: animate 10s ease-in-out infinite;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}

@media (max-width: 600px) {
  .text-container h1 {
    font-size: 100px;
  }
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/**FIN INICIO PANTALLA */

/**RESPONSIVE */
@media screen and (max-width: 660px) {
  .signIn h3 {
    font-size: 2.5rem;
  }
  .signIn {
    height: 540px;
  }
}
/**FIN RESPONSIVE */
</style>

<template>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous">
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
      />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ" crossorigin="anonymous">
  
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"> 
    <br>
    <br>
      <!-- Header Start -->
      <AppNavbar/>
      <!-- Header End -->
      <!--HOME-->
      <section style="padding-top: 100px;min-height: 120px; " ></section>

      <body>
            <div class="cards-grid">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80');display: flex; flex-direction: column;">
                            <!--CONTENIDOS-->
                            <div class="titulos" style="color: var(--white);">
                                <h1 style="padding-top: 5%;">UN <span>TOTAL</span></h1>
                                <h1>{{ CantidadInscritos[0].CantidadTotalInscritos}}</h1>
                                <div class="circulo" style="width: 20rem;height: 20rem;background-color: #a9957b;border-radius: 50%;padding: 5% 5%;margin: 5% 13.5%;display: flex;justify-content: center;align-items: center;text-align: center;">
                                    <a id="contador" style="opacity: 1; color: beige;font-size: 12rem;z-index: 10000;">{{parseInt(CantidadInscritos[0].CantidadTotalInscritos) }}</a>
                                </div>
                                <h1 style="font-weight:bold;font-size: 2.5rem;">INSCRITOS</h1>
                                <h1 style="font-weight:bold;font-size: 1.7rem;padding: 2rem;">Entre todas las charlas que se llevan a acabo!</h1>
                            </div>
                        </div>
                            <!--FIN CONTENIDOS-->
                        <div class="flip-card-back" style="font-size: 2rem;">
                            En USEI UCB, ofrecemos una serie de charlas diseñadas para ayudar a las organizaciones a conectarse con estudiantes talentosos y motivados. Nuestras charlas permiten a las organizaciones presentar sus proyectos, programas y oportunidades de empleo, y establecer relaciones duraderas con los estudiantes.
                        </div>

                        
                    </div>
                    <div class="container-btn" style="display: flex; justify-content: center; align-items: center; width: 100%;padding: 5rem 0;">   
                        <a style="justify-content: center;align-items: center; max-width: 100%;" @click="irAEstadisticas()" class="btn">
                                <span class="mensaje">Administrar </span>
                                <i class="fas fa-pencil-alt" style="vertical-align: middle;"></i>
                            </a>                        
                        </div>
                </div>

                <div class="flip-card">
                    
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://www.cef.tw/s_www/images/blog/Freshman/cover_04.jpg');display: flex; flex-direction: column;">
                            <!--CONTENIDOS-->
                            <div class="titulos" style="color: var(--white);">
                                <h1 style="padding-top: 5%;">YA <span>SOMOS</span></h1>
                                <h1>JUNTOS</h1>
                                <div class="circulo1" style="width: 20rem;height: 20rem;background-color: #a9957b;border-radius: 50%;padding: 5% 5%;margin: 5% 13.5%;display: flex;justify-content: center;align-items: center;text-align: center;">
                                    <a id="contador1" style="opacity: 1; color: beige;font-size: 12rem;z-index: 10000;">{{parseInt(CantidadInstituciones[0].Cantidad_Instituciones) }}</a>
                                </div>
                                <h1 style="font-weight:bold;font-size: 2.5rem;">ORGANIZACIONES</h1>
                            </div>
                            <div class="container-imagenes" style="padding: 0 5%;padding-top: 80%; display: flex;">
                                <img style="border-radius: 50%; height: 120%; width: 20%; object-fit: cover;" :src="TopInstituciones[0]?.URL_Foto" alt="organización afiliada 1">
                                <img style="border-radius: 50%; height: 120%; width: 20%; object-fit: cover;" :src="TopInstituciones[1]?.URL_Foto" alt="organización afiliada 1">
                                <img style="border-radius: 50%; height: 120%; width: 20%; object-fit: cover;" :src="TopInstituciones[2]?.URL_Foto" alt="organización afiliada 1">
                                <img style="border-radius: 50%; height: 120%; width: 20%; object-fit: cover;" :src="TopInstituciones[3]?.URL_Foto" alt="organización afiliada 1">
                            </div>
                        </div>
                            <!--FIN CONTENIDOS-->
                        <div class="flip-card-back" style="font-size: 2rem;">
                            En USEI UCB, contamos con una amplia variedad de organizaciones que ofrecen empleo y oportunidades de desarrollo profesional para estudiantes. Cada organización tiene su propia visión y objetivos, lo que permite a los estudiantes encontrar una que se ajuste a sus intereses y habilidades.
                        </div>

                        
                    </div>
                    <div class="container-btn" style="display: flex; justify-content: center; align-items: center; width: 100%;padding: 5rem 0;">   
                        <a style="justify-content: center;align-items: center; max-width: 100%;" @click="irAOrganizacionesAdmin()" class="btn">
                                <span class="mensaje">Administrar </span>
                                <i class="fas fa-pencil-alt" style="vertical-align: middle;"></i>
                            </a>                        
                        </div>
                </div>

                <div class="flip-card">
                    
                        <div class="flip-card-inner">
                            <div class="flip-card-front" style="background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://images.unsplash.com/photo-1544654262-e295983be0f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80');display: flex; flex-direction: column; height: 100%;">
                                <!--CONTENIDOS-->
                                <div class="titulos" style="color: var(--white);">
                                    <h1 style="padding:5%;">CANTIDAD <span>INSCRITOS</span></h1>
                                    <h1>TOP</h1>
                                    <h1 style="font-weight:bold;font-size: 2.5rem;">ORGANIZACIONES</h1>
                                    <div style="flex-grow: 1; overflow-y: auto; max-height: 70%;padding-top: 15%;">
                                        <ul style="list-style: none; padding: 0; margin: 0;">
                                            <li style="margin-bottom: 0.5rem;">{{TopInstituciones[0]?.Nombre_Institucion+": "}}<a>{{TopInstituciones[0]?.CantidadInscritos}}</a> Inscritos</li>
                                            <br>
                                            <li style="margin-bottom: 0.5rem;">{{TopInstituciones[1]?.Nombre_Institucion+": "}}<a>{{TopInstituciones[1]?.CantidadInscritos}}</a> Inscritos</li>
                                            <br>
                                            <li style="margin-bottom: 0.5rem;">{{TopInstituciones[2]?.Nombre_Institucion+": "}}<a>{{TopInstituciones[2]?.CantidadInscritos}}</a> Inscritos</li>
                                            <br>
                                            <li style="margin-bottom: 0.5rem;">{{TopInstituciones[3]?.Nombre_Institucion+": "}}<a>{{TopInstituciones[3]?.CantidadInscritos}}</a> Inscritos</li>
                                            <br>
                                            <li style="margin-bottom: 0.5rem;">{{TopInstituciones[4]?.Nombre_Institucion+": "}}<a>{{TopInstituciones[4]?.CantidadInscritos}}</a> Inscritos</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!--FIN CONTENIDOS-->
                        <div class="flip-card-back" style="font-size: 2rem;">
                            En USEI UCB, trabajamos con una gran cantidad de organizaciones líderes en diversos sectores. Cada una de estas organizaciones es cuidadosamente seleccionada y evaluada, lo que garantiza que los estudiantes tengan acceso a las mejores oportunidades profesionales y de desarrollo.
                        </div>
                    </div>
                    <div class="container-btn" style="display: flex; justify-content: center; align-items: center; width: 100%;padding: 5rem 0;">   
                        <a style="justify-content: center;align-items: center; max-width: 100%;" @click="irACharlasAdmin()" class="btn">
                                <span class="mensaje">Administrar </span>
                                <i class="fas fa-pencil-alt" style="vertical-align: middle;"></i>
                            </a>                        
                        </div>
                </div>
                <div class="flip-card">
                   
                        <div class="flip-card-inner">
                            <div class="flip-card-front" style="background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://images.unsplash.com/photo-1606242403120-6a3cf2a97b43?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80');display: flex; flex-direction: column; height: 100%;">
                                <!--CONTENIDOS-->
                                <div class="titulos" style="color: var(--white);">
                                    <h1 style="padding:5%;">NUESTRAS <span>PRÓXIMAS</span></h1>
                                    <h1 style="font-weight:bold;font-size: 2.5rem;">CHARLAS</h1>
                                    <div style="flex-grow: 1; overflow-y: auto; max-height: 70%;padding-top: 15%;">
                                        <ul style="list-style: none; padding: 0; margin: 0;">
                                            <li style="margin-bottom: 0.5rem; color: #17B6D2;">{{formatDate(ProximasCharlas[0]?.Fecha)+": "}}<a>{{ProximasCharlas[0]?.NombreCharla}}</a>  </li>
                                            <li style="margin-bottom: 0.5rem;">Inscritos: <a>{{ProximasCharlas[0]?.CantidadInscritosCharla}}</a></li>
                                            <li style="margin-bottom: 0.5rem;">Institucion: <a>{{ProximasCharlas[0]?.NombreInstitucion}}</a></li>
                                            <br>
                                            <li style="margin-bottom: 0.5rem; color: #17B6D2;">{{formatDate(ProximasCharlas[1]?.Fecha)+": "}}<a>{{ProximasCharlas[1]?.NombreCharla}}</a>  </li>
                                            <li style="margin-bottom: 0.5rem;">Inscritos: <a>{{ProximasCharlas[1]?.CantidadInscritosCharla}}</a></li>
                                            <li style="margin-bottom: 0.5rem;">Institucion: <a>{{ProximasCharlas[1]?.NombreInstitucion}}</a></li>
                                            <br>
                                            <li style="margin-bottom: 0.5rem; color: #17B6D2;">{{formatDate(ProximasCharlas[2]?.Fecha)+": "}}<a>{{ProximasCharlas[2]?.NombreCharla}}</a>  </li>
                                            <li style="margin-bottom: 0.5rem;">Inscritos: <a>{{ProximasCharlas[2]?.CantidadInscritosCharla}}</a></li>
                                            <li style="margin-bottom: 0.5rem;">Institucion: <a>{{ProximasCharlas[2]?.NombreInstitucion}}</a></li>
                                            <br>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!--FIN CONTENIDOS-->
                        <div class="flip-card-back" style="font-size: 1.5rem;">
                            En USEI UCB, organizamos regularmente charlas con organizaciones líderes en diversos sectores. Estas charlas son una oportunidad única para que los estudiantes aprendan más sobre las organizaciones y sus programas, y para que establezcan conexiones importantes que pueden conducir a futuras oportunidades de empleo y desarrollo profesional. Asegúrate de revisar nuestras próximas fechas de charlas y no te pierdas la oportunidad de conectarte con organizaciones líderes.

                        </div>
                    </div>
                    <div class="container-btn" style="display: flex; justify-content: center; align-items: center; width: 100%;padding: 5rem 0;">   
                        <a style="justify-content: center;align-items: center; max-width: 100%;" @click="irACalendarioRecordatorios()" class="btn">
                                <span class="mensaje">Administrar </span>
                                <i class="fas fa-pencil-alt" style="vertical-align: middle;"></i>
                            </a>                        
                        </div>
                </div>
            </div>                
        </body>
        <section style="padding-top: 100px;min-height: 120px; " ></section>

  
      <a href="#" class="scroll-top-btn">
          <i class="fas fa-arrow-up"></i>
      </a>
      <!-- Modal -->
      <!--Footer Start-->
      <AppFooter/>
      <!--Footer end-->
  </template>
     
  <script>
  import axios from "axios";
  import AppNavbar from "./navbar-admin.vue";
  import AppFooter from "./footer-admin.vue";
  import { rutaString,puertoString } from '@/assets/apiconfig';

  
  export default {
    name: "ProductList",
    data() {
      return {
        superUsuario: sessionStorage.getItem("tipo") === null ? -1 : sessionStorage.getItem("tipo"),
        items: [],
        CantidadInstituciones: [
            {
                Cantidad_Instituciones: 0,
            },
        ],
        TopInstituciones: [],
        CantidadInscritos: [],
        ProximasCharlas: [],
        name: sessionStorage.getItem('name'),

ruta: rutaString,
      puerto: puertoString,


      };
    },
    created() {
      this.getCantidadInstituciones();
      this.getTopInstituciones();
      this.getCantidadTotalInscritos();
      this.getProximasCharlas();
      console.log(this.name);
    },
    
  
   
  
    methods: {
        formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    

    return ""+day+"/"+month+"/"+year;
  },
      async getCantidadInstituciones() {
        try {
          const response = await axios.get(`http://${this.ruta}${this.puerto}/cantidadInstituciones`);
          this.CantidadInstituciones = response.data;
          console.log(this.CantidadInstituciones);
        } catch (err) {
          console.log(err);
        }
      },
      async getTopInstituciones() {
        try {
          const response = await axios.get(`http://${this.ruta}${this.puerto}/topInstituciones`);
          this.TopInstituciones = response.data;
          console.log(this.TopInstituciones);
        } catch (err) {
          console.log(err);
        }
      },
      async getCantidadTotalInscritos() {
        try {
          const response = await axios.get(`http://${this.ruta}${this.puerto}/totalInscritos`);
          this.CantidadInscritos = response.data;
          console.log(this.CantidadInscritos);
        } catch (err) {
          console.log(err);
        }
      },
      async getProximasCharlas() {
        try {
          const response = await axios.get(`http://${this.ruta}${this.puerto}/proximasCharlas`);
          this.ProximasCharlas = response.data;
          console.log(this.ProximasCharlas);
        } catch (err) {
          console.log(err);
        }
      },
      
  
      verPagina(id) {
        // Abrir otra página y enviar el ID del item como parámetro en la URL
        //window.open(`/PaginaInstitucion?id=${id}`, "_blank");
        window.open(`/PaginaInstitucion/${id}`);
      },
      /*Métodos Navbar*/
      irACalendarioCharlas() {
        this.$router.push("/calendario_charlas");
      },
      /*Fin métodos navbar*/
      irAOrganizacionesAdmin(){
        this.$router.push('/administrarInstituciones');
      },
      irACalendarioRecordatorios(){
        this.$router.push('/CalendarioRecordatorios');
      },
      irAEstadisticas(){
        this.$router.push('/Estadisticas');
      },
      irACharlasAdmin(){
        this.$router.push('/administrarInstituciones');
      },
      

    },
  
    /*SCRIPTS NAVBAR*/
     mounted() {


        if (this.superUsuario != 1){
            alert("Acceso denegado");
            this.$router.push("/");
        } else {
            
        /*SCRIPTS NAVBAR*/
    const navbar = document.querySelector('.navbar');
    const menuBtn = document.querySelector('#menu-btn');
    menuBtn.addEventListener('click', () => {
      navbar.classList.toggle('active');
    });

    window.addEventListener('scroll', () => {
      navbar.classList.remove('active');
    });


        const circulo = document.querySelector('.circulo');
        const numero = circulo.querySelector('a').innerHTML;
        let contador = 0;
        const interval = setInterval(() => {
            if (contador <= numero) {
            circulo.querySelector('a').innerHTML = contador;
            contador++;
            } else {
            clearInterval(interval);
            }
        }, 30);
        const circulo1 = document.querySelector('.circulo1');
        const numero1 = circulo1.querySelector('a').innerHTML;
        let contador1 = 0;
        const interval1 = setInterval(() => {
            if (contador1 <= numero1) {
            circulo1.querySelector('a').innerHTML = contador1;
            contador1++;
            } else {
            clearInterval(interval1);
            }
        }, 30);


        }


        },
  
    components:{
      AppNavbar,
      AppFooter,
    }
  };
  </script>

<style scoped>
  /*EMPIEZA CSS NILMAR*/
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');
  
  :root{
      --orange:#ff7800;
      --black: #120f40;
      --darkYellow: #F2B90C;
      --yellow: #F2CB05;
      --marine: #024873;
      --blue: #034C8C;
      --white: #F2F2F2;
      --light-color: #666;
      --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
      --border: .2rem solid rgba(0,0,0,.1);
      --outline: .1rem solid rgba(0,0,0,.1);
      --outline-hover: .2rem solid var(--black);
  }
  
  *{
      font-family: 'Poppins', sans-serif;
      margin: 0;padding: 0;
      box-sizing: border-box;
      outline:none;border: none;
      text-decoration: none;
      text-transform: capitalize;
      transition: all .2s linear;
  }
  
  /*BOTÓN FOOTER */
  .feria-empleo .btn{
      margin-top: 1rem;
      display: inline-block;
      padding: .8rem 3rem;
      font-size: 2.5rem;
      font-weight: 400;
      border-radius: .5rem;
      border: .2rem solid var(--dark);
      background:var(--black);
      color: var(--white);
      width: 50%;
      cursor: pointer;
  }
  
  .feria-empleo .btn:hover{
      background: var(--white);
      color: var(--black);
      transition: .5s;
      scale: 1.1;
  }
  /**FIN BOTÓN */
  
  /*BOTÓN QUE LLEVA AL INICIO*/
  .scroll-top-btn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    background-color: var(--black);
    color: var(--white);
    padding: 20px;
    border-radius: 50%;
    font-size: 20px;
    /*transition: opacity 0.2s ease-in-out;*/
    opacity: 0;
    z-index: 9999;
  }
  
  .scroll-top-btn:hover {
    opacity: 0.8;
    color: var(--white);
  }
  /*FIN BOTÓN QUE LLEVA AL INICIO*/
  
  html{
      font-size: 62.5%;
      overflow-x: hidden;
      scroll-behavior: smooth;
      scroll-padding-top: 7rem;
  }
  
  body{
      background: #f2f2f2;
  }
  
  .features .btn{
      border: .2rem solid var(--black);
      color: var(--darkYellow);               
  }
  
  .products .btn{
      border: .2rem solid var(--black);
      color: var(--yellow);
  }
  
  .blogs .btn{
      border: .2rem solid var(--black);
      color: var(--yellow);
  }
  
  .review .btn{
      border: .2rem solid var(--black);
      color: var(--yellow);
  }
  
  .container-btn .btn{
      border: .2rem solid var(--black);
      background-color: var(--black);
      width: 75%;
      height: 3.5rem;
      font-size: 1.3rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      align-items: center;
      justify-content: center center;
      color: var(--white);
      padding-top: 7px;
      animation: salto 2s ease-in-out infinite;
  }

  @keyframes salto {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
  }
  
  .footer .btn{
      border: .2rem solid var(--black);
      color: var(--yellow);
  }
  .footer .box-container .box:last-child{
      display: flex;
      flex-direction: column;
  }
  
  .modal .btn{
      border: .2rem solid var(--black);
      color: var(--yellow);
  }
  
  
  .btn:hover{
      background: var(--black);
      border: .2rem solid var(--white);
      color: #f2f2f2;
      transition: .5s;
      scale: 1.1;
  }
  
  /* NAVBAR GENERAL*/
  .header{
      position: fixed;
      top: 0;left: 0;right: 0;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 9%;
      background: #f2f2f2;
      box-shadow: var(--box-shadow);
  }
  
  .header .logo{
      min-width: 7.15rem;
      min-height: 7.15rem;
      /*max-height: 8rem;*/
      height: 50px;
      width: 50px;
      font-size: 3rem;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      text-decoration: none;
      color: var(--black);
  }
  .header .logo:hover{
      transition: .5s;
      scale: 1.2;
  }
  
  .header .logo i{
      color: var(--black);
  }
  
  .header .navbar a{
      font-size: 1.7rem;
      margin: 0 1rem;
      margin-top: 18px;
      color: var(--black);
      text-decoration: none;
  }
  
  .header .navbar a:hover{
      color: var(--black);
      scale: 1.2;
      padding-left: 20px;
      padding-right: 20px;
      transition: .5s;
  }
  
  
  .header .icons div{
      height: 4.5rem;
      width: 4.5rem;
      line-height: 4.5rem;
      border-radius: .5rem;
      background:#eee;
      color: var(--black);
      font-size: 2rem;
      margin-left: .3rem;
      cursor: pointer;
      text-align: center;
  }
  
  .header .icons div:hover{
      background: var(--black);
      color: #f2f2f2;
  }
  
  #menu-btn{
      display: none;
  }
  
  
  .header .search-form{
      position: absolute;
      top: 110%;right: -110%;
      width: 50rem;
      height: 5rem;
      background: #f2f2f2;
      border-radius: .5rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      box-shadow: var(--box-shadow);
  }
  
  .header .search-form.active{
      right: 2rem;
      transition: .5s;
  }
  
  .header .search-form input{
      height: 100%;
      width: 100%;
      background: none;
      text-transform: none;
      font-size: 1.6rem;
      color: var(--black);
      padding: 0 1.5rem;
  }
  
  .header .search-form label{
      font-size: 2.2rem;
      padding-right: 1.5rem;
      color: var(--black);
      cursor: pointer;
  }
  
  .header .search-form label:hover{
      color: var(--black);
  }
  
  
  
  .header .shopping-cart{
      position: absolute;
      top: 100%;
      right: -110%;
      padding: 1rem;
      border-radius: .5rem;
      box-shadow: var(--box-shadow);
      width: 35rem;
      background: #f2f2f2;
  }
  
  .header .shopping-cart.active{
      right: 2rem;
      transition: .4s linear;
  }
  
  .header .shopping-cart .box{
      display: flex;
      align-items: center;
      gap: 1rem;
      position: relative;
      margin: 1rem 0;
  }
  
  .header .shopping-cart .box img{
      height: 10rem;
  }
  
  .header .shopping-cart .box .fa-trash{
      font-size: 2rem;
      position: absolute;
      top: 50%;right: 2rem;
      cursor: pointer;
      color: var(--light-color);
      transform: translateY(-50%);
  }
  
  .header .shopping-cart .box .fa-trash:hover{
      color: var(--black);
  }
  
  .header .shopping-cart .box .content h3{
      color: var(--black);
      font-size: 1.7rem;
      padding-bottom: 1rem;
  }
  
  .header .shopping-cart .box .content span{
      color: var(--light-color);
      font-size: 1.6rem;
      padding-bottom: 1rem;
  }
  
  .header .shopping-cart .box .content .quantity{
      padding-left: 1rem;
  
  }
  
  .header .shopping-cart .total{
      font-size: 2.5rem;
      padding: 1rem 0;
      text-align: center;
      color: var(--black);
  }
  
  
  .header .shopping-cart .btn{
      display: block;
      text-align: center;
      margin: 1rem;
  }
  
  @media(max-width: 991px){
       html{
          font-size: 55%;
       }   
       .header{
          padding: 2rem;
       }
       section{
          padding: 2rem;
      }
  
  }
  
  @media(max-width: 768px){
      .header .navbar{
          position: absolute;
          top: 110%;
          right: -110%;
          width: 30rem;
          box-shadow: var(--box-shadow);
          border-radius: .5rem;
          background: #f2f2f2;
      }
  
      .header .search-form{
          width: 90%;
      }
      #menu-btn{
          display: inline-block;
      }
  
      .header .navbar.active{
          right: 2rem;
          transition: .5s linear;
      }
  
      .header .navbar a{
          font-size: 2rem;
          margin: 2rem 2.5rem;
          display: block;
      }
  }
  
  @media(max-width: 450px){
      html{
          font-size: 50%;
      }
  
      .heading{
          font-size: 2.5rem;
      }
  
      .footer{
          text-align: center;
      }
  }
  
  
  section{
      padding: 2rem 9%;
  }
  
  
  .home{
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80)no-repeat rgba(0, 0, 0, 0.5);
      background-position: center center;
      background-size: cover;
      padding-top: 17rem;
      padding-bottom: 10rem;
      position: relative;
  }
  
  .home::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }
  
  .home .content{
      text-align: center;
      width: 90rem;
  }
  
  .home .content h3{
      color: var(--white);
      font-size: 6rem;
  }
  
  .home .content h3 span{
      color: var(--yellow);
  }
  
  .home .content p{
      color: var(--white);
      font-size: 1.9rem;
      padding: 1rem 0;
      line-height: 1.8;
  }
  
  .heading{
      text-align: center;
      padding: 2rem 0;
      padding-bottom: 3rem;
      font-size: 3.5rem;
      color: var(--darkYellow);
  }
  
  .heading span{
      background: var(--black);
      color: #f2f2f2;
      display: inline-block;
      padding: .5rem 3rem;
      clip-path: polygon(100% 0,93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
  }
  
  .features .box-container{
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(30rem,1fr));
      gap:1.5rem;
  }
  
  .features .box-container .box{
      padding: 3rem 2rem;
      background: #f2f2f2;
      outline: var(--outline);
      outline-style: -1rem;
      text-align: center;
      box-shadow: var(--box-shadow);
  }
  
  .features .box-container .box:hover{
      outline: var(--outline-hover);
      outline-offset: 0rem;
  }
  
  .features .box-container .box img{
      margin: 1rem 9;
      height: 15rem;
  }
  
  .features .box-container .box h3{
      font-size: 2.5rem;
      line-height: 1.8;
      color: var(--light-color);
      padding: 1rem 0;
  }
  
  .products .product-slider{
      padding: 1rem;
  }
  
  .products .product-slider:first-child{
      margin-bottom: 2rem;
  }
  
  .products .product-slider .box{
      background: #f2f2f2;
      border-radius: .5rem;
      text-align: center;
      padding: 3rem 2rem;
      outline-offset: -1rem;
      outline: var(--outline);
      box-shadow: var(--box-shadow);
  }
  
  .products .product-slider .box:hover{
      outline-offset: 0rem;
      outline: var(--outline-hover);
  }
  
  .products .product-slider .box img{
      height: 20rem;
  }
  
  .products .product-slider .box h3{
      font-size: 2.5rem;
      color: var(--black);
  }
  
  .products .product-slider .box .stars i{
      font-size: 1.7rem;
      color: var(--black);
      padding:.5rem 0 ;
  }
  
  
  .categories .box-container{
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
      gap: 1.5rem;
  }
  
  .categories .box-container .box{
      padding: 3rem 2rem;
      border-radius: .5rem;
      background: #f2f2f2;
      box-shadow: var(--box-shadow);
      outline: var(--outline);
      outline-offset: -1rem;
      text-align: center;
  
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  
  .categories .box-container .box:hover{
      outline: var(--outline-hover);
      outline-offset: 0rem;
  }
  
  .categories .box-container img{
      margin: 1rem 0;
      max-height: 25rem;
      min-height: 25rem;
      object-fit: cover;
      width: 95%;
  }
  
  .categories .box-container .box h3{
      font-size: 2rem;
      color: var(--black);
      line-height: 1.8;
      height: 7rem;
      display: flex;
      align-items: center;
  }
  
  .categories .box-container .box p{
      font-size: 1.7rem;
      color: var(--black);
      line-height: 1.8;
      padding: 1rem 0;
      height: 9rem;
  }
  
  .review .review-slider{
      padding: 1rem;
  }
  
  .review .review-slider .box{
      background: #f2f2f2;
      border-radius: .5rem;
      text-align: center;
      padding: 3rem 2rem;
      outline-offset: -1rem;
      outline: var(--outline);
      box-shadow: var(--box-shadow);
      transition: .2s linear;
  }
  
  .review .review-slider .box:hover{
      outline: var(--outline-hover);
      outline-offset: 0rem;
  }
  
  .review .review-slider .box img{
      height: 10rem;
      width: 10rem;
      border-radius: 50%;
  }
  
  .review .review-slider .box p{
      padding: 1rem 0;
      line-height: 1.8;
      color: var(--light-color);
      font-size: 1.5rem;
  }
  
  .review .review-slider .box h3{
      padding-bottom: .5rem;
      color: var(--black);
      font-size: 2.2rem;
  }
  
  .review .review-slider .box .stars i{
      color: var(--black);
      font-size: 1.7rem;
  }
  
  
  .blogs .box-container{
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
      gap: 1.5rem;
  }
  
  .blogs .box-container .box{
      overflow: hidden;
      border-radius: .5rem;
      box-shadow: var(--box-shadow);
      background: #f2f2f2;
  }
  
  .blogs .box-container .box img{
      height: 25rem;
      width: 100%;
      object-fit: cover;
  }
  
  .blogs .box-container .box .content{
      padding: 2rem;
  }
  
  .blogs .box-container .box .content .icons{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.5rem;
      margin-bottom: 1rem;
      border-bottom: var(--border);
  }
  
  .blogs .box-container .box .content .icons a{
      color: var(--light-color);
      font-size: 1.5rem;
  }
  
  .blogs .box-container .box .content .icons a:hover{
      color: var(--black);
  }
  
  .blogs .box-container .box .content .icons a i{
      color: var(--black);
      padding-right: .5rem;
  }
  
  .blogs .box-container .box .content h3{
      line-height: 1.8;
      color: var(--black);
      font-size: 2.2rem;
      padding: .5rem 0;
  }
  
  .blogs .box-container .box .content p{
      line-height: 1.8;
      color: var(--light-color);
      font-size: 1.5rem;
      padding: .5rem 0;
  }
  
  
  .footer{
      padding-top: 50px;
      background: #eee;
      box-shadow: var(--box-shadow );
      text-decoration: none;
  }
  
  .footer .box-container{
      display: grid;
      grid-template-columns: repeat(auto-fit,minmax(25rem,1fr));
      gap: 1.5rem;
  }
  
  .footer .box-container .box h3{
      font-size: 2.5rem;
      color: var(--black);
      padding: 1rem 0;
  }
  
  .footer .box-container .box h3 i{
      color: var(--black);
  }
  
  .footer .box-container .box .links{
      display: block;
      font-size: 1.5rem;
      color: var(--light-color);
      padding: 1rem 0;
  }
  
  .footer .box-container .box .links i{
      color: var(--black);
      padding-right: .5rem;
  }
  
  .footer .box-container .box .links:hover i{
      padding-right: 2rem;
      text-decoration: none;
  }
  
  .footer .box-container .box p{
      line-height: 1.8;
      font-size: 1.5rem;
      color: var(--light-color);
      padding: 1rem 0;
  }
  
  .footer .box-container .box .share a{
      height: 4rem;
      width: 4rem;
      line-height: 4rem;
      border-radius: .5rem;
      font-size: 2rem;
      color: var(--black);
      margin-left: .2rem;
      text-align: center;
  }
  
  .footer .box-container .box .share a:hover{
      background: var(--black);
      color: #f2f2f2;
  }
  
  .footer .box-container .box .email{
      margin: .7rem 0;
      padding: 1rem;
      border-radius: .5rem;
      border: .2rem solid var(--black);
      background: #eee;
      font-size: 1.6rem;
      color: var(--black);
      text-transform: none;
  } 
  
  .footer .box-container .box .payment-img{
      margin-top: 2rem;
      height: 3rem;
  
  }
  
  .footer .credit{
      text-align: center;
      margin-top: 2rem;
      padding:1rem;
      padding-top: 2.5rem;
      font-size: 2rem;
      color: var(--black);
      border-top: var(--border);
  }
  
  .footer .credit span{
      color: var(--black);
  }
  
  
  a{
      text-decoration: none;
  }
  
  
  /*ACABA CSS NILMAR*/
  
      .card-img-top {
          width: 100%;
          height: 15vw;
          object-fit: cover;
      }
      .gallery-block{
          padding-bottom: 60px;
          padding-top: 60px;
      
      }
      .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: 0px;
      margin-left: -0px;
      margin-top: 15px;
      }
      .gallery-block .heading{
          margin-bottom: 50px;
          text-align: center;
      }
    
      .gallery-block .heading h2{
          font-weight: bold;
          font-size: 1.4rem;
          text-transform: uppercase;
      }
    
      .gallery-block.cards-gallery h6 {
      font-size: 17px;
      font-weight: bold; 
      }
    
      .gallery-block.cards-gallery .card{
      transition: 0.4s ease; 
      }
    
      .gallery-block.cards-gallery .card img {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); 
      }
    
      .gallery-block.cards-gallery .card-body {
      text-align: center; 
      }
    
      .gallery-block.cards-gallery .card-body p {
      font-size: 15px; 
      }
    
      .gallery-block.cards-gallery a {
      color: #212529; 
      }
    
      .gallery-block.cards-gallery a:hover {
      text-decoration: none; 
      }
    
      .gallery-block.cards-gallery .card {
      margin-bottom: 30px; 
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      }
    
      @media (min-width: 576px) {
    
          .gallery-block .transform-on-hover:hover {
              transform: translateY(-10px) scale(1.02);
              box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important; 
          }
      }


      /*Estilo tarjetitas*/
            /*
            ** All elements border-box
        */
        *,
        *:before,
        *::after {
        box-sizing: border-box;
        }

        /*
        ** Root element font size
        ** No default margins and paddings
        */
        html,
        body {
        font-size: .7rem;
        margin: 0;
        padding: 0;
        }
        ul {
            min-width: 50%;
            font-size: 1.5rem;
        }

        /*
        **root elements display block
        */
        html,
        body {
        display: block;
        }

        /*
        ** Body main style
        */
        body {
        line-height: 1.317101995;
        scroll-behavior: smooth;
        font-family: sans-serif;
        background-color: #ffffff;
        }

        button:focus {
        outline: 0;
        }

        img,
        embed,
        svg,
        audio,
        canvas,
        iframe,
        video {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        }

        hr {
        height: 0;
        border: 0;
        border-bottom: 1px solid #dfdfdf;
        margin: 0;
        }

        body {
        color: #5c7470;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 0;
        }

        h1 {
        font-size: 4rem;
        margin-top: 0;
        }

        h1 span {
        color: #17B6D2;
        }

        p {
        margin: 0;
        }

        .cards-grid {
        /*display: flex;
        grid-gap: 80px;
        padding-bottom: 60px;*/

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15rem;
        margin: 2rem 0;
        }

        /* FLIP CARD */
        .flip-card {
        width: 320px;
        height: 504px;
        perspective: 2000px;

        }

        .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        border-radius: 28px;
        transition: all 550ms cubic-bezier(0.1, 0.22, 0.8, 1.13);
        transform-style: preserve-3d;
        animation: rotate-card 12s infinite;
        animation-delay: 6s;
        }

        .flip-card:hover .flip-card-inner {
            animation-play-state: paused;
            transform: rotateY(-180deg);
        }

        .flip-card-front,
        .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 28px;
        backface-visibility: hidden;
        }

        .flip-card-front {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        }

        .flip-card-back {
        font-size: 1.25rem;
        text-align: center;
        display: grid;
        place-items: center;
        padding: 5%;
        background-color: #b1cfc8;
        transform: rotateY(180deg);
        }

        .flip-card-back.container-btn.btn{
            border: .2rem solid var(--black);
            background-color: var(--black);
            width: 75%;
            height: 4.5rem;
            font-size: 1.8rem;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            align-items: center;
            justify-content: center center;
            color: var(--white);
            padding-top: 7px;
        } 

        .flip-card:hover .flip-card-inner {
        transform: rotateY(-180deg);
        }

        @keyframes rotate-card {
        0% {
            transform: rotateY(0);
        }
        50% {
            transform: rotateY(180deg);
        }
        100% {
            transform: rotateY(360deg);
        }
        }



        /*Imágenes tarjetas */
        .container-imagenes {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 60%;
            margin-top: -75%;
            }
        /*Fin imágenes tarjetas */

        
      /*fin tarjetitas*/

      /*botones*/
      .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background-color: var(--black);
        color: var(--white);
        font-size: 16px;
        font-weight: bold;
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s ease-out;
    }
    
    .btn:hover {
        transition: .5s;
        background-color: var(--black);
        color: var(--white);
    }

    /*TÍTULOS */
    .titulos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        }

      /*FIN BOTONES */
    
    </style>
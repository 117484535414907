<template>
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
    crossorigin="anonymous"
  />
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
    crossorigin="anonymous"
  />
  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
    rel="stylesheet"
  />

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
  />
  <br />
  <br />
  <!-- Header Start -->
  <AppNavbar />

  <!-- Header End -->
  <body>
    <main style="margin-top: 100px">
      <section class="glass">
        <div class="dashboard">
          <div class="user">
            <img
              src="https://www.pngkey.com/png/full/115-1150152_default-profile-picture-avatar-png-green.png"
              alt=""
            />
            <h3>ESTUDIANTE</h3>
            <p>{{ this.Persona.nombre }}</p>
          </div>
          <div class="links">
            <div class="link">
              <img
                src="https://cdn.icon-icons.com/icons2/1473/PNG/512/027id_101525.png"
                alt=""
              />
              <h2>C.I.:{{ this.name }}</h2>
            </div>
            <div class="link">
              <img
                src="https://cdn-icons-png.flaticon.com/512/482/482138.png"
                alt=""
              />
              <h4 style="text-transform: initial; color: #658ec6" >Correo: {{ this.Persona.correo }}</h4>
            </div>
            <div class="link">
              <img
                src="https://cdn-icons-png.flaticon.com/512/126/126341.png"
                alt=""
              />
              <h2>Teléfono: {{ this.Persona.telefono }}</h2>
            </div>
            <div class="link">
              <img
                src="https://cdn-icons-png.flaticon.com/512/10546/10546142.png"
                alt=""
              />
              <h2>Sede:{{ this.Persona.sede }}</h2>
            </div>
          </div>
          <div class="link">
            <div class="container-btn" style="display: flex; justify-content: center; align-items: center; width: 100%;padding: 1rem 0;">
              <a class="btn" @click="showEditarUsuarioModal=true">Modificar mi información</a>
            </div>
          </div>

          <div class="pro">
            
            <h2 style="color: #426696">Tú eres cato</h2>
            <img
              src="https://tja.ucb.edu.bo/wp-content/uploads/2020/09/logo-UCB.png"
              alt=""
            />
          </div>
          
        </div>
        <div class="games"> 
          <div class="status">
            <h1>Mis Charlas</h1>
            
          </div>


          <section style="padding-bottom: 1rem; ">
              <div class="wrapper2" style="z-index: 1000;">
                <div class="search_box">
                  <div class="dropdown" @click="toggleDropdown">
                    <div class="default_option">{{ selectedOption }}</div>
                    <ul  :class="{ active: dropdownActive }">
                      <li v-for="option in listaInstituciones" :key="option" @click="selectOption(option)">
                        {{ option }}
                      </li>
                    </ul>
                  </div>
                  <div class="search_field">
                    <input type="text" class="input" v-model="this.searchBox" placeholder="Buscar">
                    <i class="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </section>




          <div class="cards">
            <div class="shadow overflow" id="tarjetas" v-for="item in filteredCharlas" :key="item.Id_Charla">
              <div id="header" :style="{ background: 'url(' + item.URL_FotoCharla + ')' }"></div>
              <div id="profile">
                <div class="image">
                  <a :href="item.Link" >
                    <img style="height: 100%; width: 100%; background-color: white; "
                      src="https://companieslogo.com/img/orig/ZM-b326e02f.png?t=1647979587"
                      alt=""
                    />
                  </a>
                </div>
                
                <div class="name">{{ item.Nombre }}</div>
                <div class="nickname">{{'Presiona la camara para ir a tu reunion'}}</div>
                <div class="location">
                  <i class="material-icons">{{item.NombreInstitucion}}</i>
                  <br>
                  <i class="material-icons">{{item.Hora}}</i>

                </div>
                <div class="bottom">
                  <span class="following">
                    <span class="count">{{ new Date(item.Fecha).toLocaleDateString("es-AR") }}</span>
                  </span>
                 
                </div>
                <button class="link-reu" @click="unirseReunion(item.Link)">Entrar a la reunion</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <div class="circle1"></div>
    <div class="circle2"></div>
  </body>
  <!--Footer Start-->
  <ModificarUsuario
      v-if="showEditarUsuarioModal"
      @close="showEditarUsuarioModal = false"
      @isLoadingModal="isLoading = $event"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
      class="modal"
      :usuario="this.Persona"
      @guardar-cambios="handleGuardarCambios" 

  />


  <AppFooter />
  <!--Footer End-->
</template>
<script>
import AppNavbar from "./navbar.vue";
import AppFooter from "./footer.vue";
import axios from "axios";
import { rutaString,puertoString } from '@/assets/apiconfig';
import ModificarUsuario from "@/components/Componentes_user/Modals/ModificarUsuario.vue";

export default {
  data() {
    return {
      name: sessionStorage.getItem("ci"),
      ruta: rutaString,
      puerto: puertoString,
      Charlas: [],
      Persona: [],
      searchBox: "",
      selectedOption: "Todos",
      listaInstituciones: [],
      dropdownActive: false,
      showEditarUsuarioModal: false,
    
    };
  },
  methods: {
    async handleGuardarCambios(usuarioEditado,contrasenia1) {
      this.showEditarUsuarioModal = false;
      console.log(usuarioEditado);
      console.log(contrasenia1);
      let response;
      if(contrasenia1 != "" && contrasenia1 != null){
       response =  await axios.put(`http://${this.ruta}${this.puerto}/datauser/${this.name}`, {
          nombre: usuarioEditado.nombre,
          correo: usuarioEditado.correo,
          telefono: usuarioEditado.telefono,
          password: contrasenia1,
        });
      }else{
        response = await axios.put(`http://${this.ruta}${this.puerto}/datauser/${this.name}`, {
          nombre: usuarioEditado.nombre,
          correo: usuarioEditado.correo,
          telefono: usuarioEditado.telefono,
        });
      }
      console.log(response);
 

      //reload de la pagina
      location.reload();




    },
    toggleDropdown() {
      this.dropdownActive = !this.dropdownActive;
      console.log(this.dropdownActive);
    },
    selectOption(option) {
      this.selectedOption = option;
      console.log(this.dropdownActive);
    },
 //Función para obtener el id de la cuenta
    unirseReunion(link){
      window.open(link,'_blank');
    },
    createListaInstituciones() {
      this.listaInstituciones = this.Charlas.map((charla) => {
        return charla.NombreInstitucion;
      });
      this.listaInstituciones = ["Todos", ...new Set(this.listaInstituciones)];
    },
    ordenarCharlas() {
      this.Charlas.sort((a, b) => {
        return new Date(a.Fecha) - new Date(b.Fecha);
      });
    },
  },

  computed: {
      filteredCharlas() {
        const normalize = (str) => {
          return str
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        };

        // Filter by searchbox and selected option
        if (this.selectedOption === "Todos") {
          return this.Charlas.filter((charla) => {
            return normalize(charla.Nombre).includes(normalize(this.searchBox));
          });
        } else {
          return this.Charlas.filter((charla) => {
            return (
              normalize(charla.Nombre).includes(normalize(this.searchBox)) &&
              normalize(charla.NombreInstitucion).includes(normalize(this.selectedOption))
            );
          });
        }
      }
    },


  async mounted() {


    
    const responsecharlas = await axios.get(
      `http://${this.ruta}${this.puerto}/inscripcionCharlaEs/${this.name}`
    );
    this.Charlas = responsecharlas.data;
    console.log(this.Charlas);
    this.createListaInstituciones();
    this.ordenarCharlas();


    const responsePersona = await axios.get(
      `http://${this.ruta}${this.puerto}/datauser/${this.name}`
    );
    this.Persona = responsePersona.data;
    console.log(this.Persona);










    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });

    window.addEventListener("scroll", () => {
      navbar.classList.remove("active");
    });

    /*script botón que lleva al inicio de la página*/
  },

  components: {
    AppNavbar,
    AppFooter,
    ModificarUsuario,
  },
};
</script>
<style scoped>

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #0070a3;
    color: var(--white);
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-out;
  }
  .btn:hover {
    background: #426696;
    border: 0.2rem solid var(--white);
    color: #f2f2f2;
    transition: 0.5s;
    scale: 1.1;
  }
  

.wrapper2 {
  position: relative;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translate(-50%, -50%);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  color: #426696;
  font-weight: 600;
  font-size: 3rem;
  opacity: 0.8;
}
h2,
p {
  color: #658ec6;
  font-weight: 500;
  opacity: 0.8;
}

h3 {
  color: #426696;
  font-weight: 600;
  opacity: 0.8;
}

main {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  background: linear-gradient(to right top, #65dfc9, #6cdbeb);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user img {
  margin: 1%;
  margin-bottom: 5%;
  padding: 2%;
  width: 25%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 2px 4px;
}
.user {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
 
}

.glass {
  background: white;
  min-height: 80vh;
  width: 85%;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 2rem;
  z-index: 2;
  backdrop-filter: blur(2rem);
  display: flex;
  flex-wrap: wrap;
}

.circle1,
.circle2 {
  background: white;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.3)
  );
  height: 20rem;
  width: 20rem;
  position: absolute;
  border-radius: 50%;
}

.circle1 {
  top: 5%;
  right: 15%;
}
.circle2 {
  bottom: 5%;
  left: 10%;
}

.dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 2rem;
}

.link {
  display: flex;
  margin: 2rem 0rem;
  padding: 1rem 5rem;
  align-items: center;
}

.link img {
  width: 12%;
  height: auto;
  margin-left: 5%;
}

.link h2 {
  padding: 0rem 2rem;

}

.games {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5% 5%;
}

.pro {
  background: linear-gradient(to right top, #65dfc9, #6cdbeb);
  border-radius: 2rem;
  color: white;
  padding: 1rem;
  position: relative;
  margin: 0 5%;
  margin-bottom: 1rem;
}

.pro img {
  position: absolute;
  top: 10%;
  right: 0%;
  margin: 0;
  width: 50%;
  height: auto;
}
.pro h2 {
  width: 40%;
  color: white;
  font-weight: 600;
}

.status {
  margin-bottom: 3rem;
}

.status input {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border: none;
  width: 50%;
  padding: 0.5rem;
  border-radius: 2rem;
}

.card {
  display: flex;
  background: linear-gradient(
    to left top,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5)
  );
  border-radius: 1rem;
  margin: 2rem 0rem;
  padding: 2rem;
  box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
  justify-content: space-between;
}

.progress {
  background: linear-gradient(to right top, #65dfc9, #6cdbeb);
  width: 100%;
  height: 25%;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}

.progress::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgb(236, 236, 236);
  position: absolute;
  left: 60%;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.percentage {
  font-weight: bold;
  background: linear-gradient(to right top, #65dfc9, #6cdbeb);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Responsive Styles */

#tarjetas {
  width: 25%;
  height: 52%;
  margin: 2% 1.25%;
}

@media screen and (max-width: 968px) {
  #tarjetas {
    width: 40%;
    margin: 2% 1.25%;
  }
}
@media screen and (min-width: 660px) {
  .cards {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }
}

@media screen and (min-width: 1372px) {
  .user img {
    margin: 1%;
    margin-bottom: 5%;
    padding: 2%;
    width: 25%;
    height: auto;
    border-radius: 50%;
    box-shadow: 0 2px 4px;
  }
}

@media screen and (max-width: 768px) {
  .glass {
    width: 90%;
  }

  .dashboard {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }

  .link {
    padding: 1rem 2rem;
  }

  .games {
    margin: 2rem;
  }

  .status input {
    width: 100%;
  }
  .pro img {
    position: absolute;
    top: 10%;
    right: 2%;
    margin: 0;
    width: 50%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .dashboard {
    padding: 1rem;
  }

  .pro h2 {
    width: 60%;
  }

  .cards {
    flex-direction: column;
    align-items: center;
  }

  .card-info {
    margin-top: 1rem;
  }
  .pro img {
    position: absolute;
    top: -50%;
    right: -10%;
    margin: 0;
  }
  .pro {
    background: linear-gradient(to right top, #65dfc9, #6cdbeb);
    border-radius: 2rem;
    color: white;
    padding: 2rem 1rem;
    position: relative;
    margin: 0 5%;
  }
  #tarjetas {
    width: 100%;
  }
}

@media screen and (max-width: 660px) {
  .cards {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  #tarjetas {
    width: 100%;
  }
  .dashboard {
    width: 100%;
  }
  .games {
    width: 90%;
  }
  .glass {
    width: 90%;
    margin: 5% 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }

  #profile .image {
    position: absolute;
    border: 3px solid white;
    top: -55px;
    left: 20px;
    height: 25px;
    width: 25px;
    border-radius: 10px;
  }
  #profile .image a img{
    width: inherit;
    height: inherit;
    border-radius: 8px;
  }

  .pro img {
    position: absolute;
    top: -20%;
    right: -2%;
    margin: 0;
    width: 50%;
    height: auto;
  }
}

/**ESTILO TARJETAS */
#header {
  width: 100%;
  max-width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center center;
  transition: all 0.08s linear;
}

#profile {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: white;
  position: relative;
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: 25px;
  font-family: "Open Sans", sans-serif;
}
#profile .image {
  position: absolute;
  border: 3px solid white;
  top: -55px;
  left: 20px;
  height: 80px;
  width: 80px;
  border-radius: 10px;
}
#profile .image img {
  width: inherit;
  height: inherit;
  border-radius: 8px;
}
#profile .name {
  font-size: 1.3rem;
  font-weight: 500;
  color: #444;
}
#profile .nickname {
  color: #888;
  font-size: 0.9rem;
  padding-bottom: 7px;
}
#profile .location {
  color: #555;
  font-size: 0.9rem;
  padding-left: 0px;
  position: relative;
  left: -5px;
}
#profile .location .material-icons {
  position: relative;
  top: 3px;
  font-size: 1rem;
}

.shadow {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
}

.overflow {
  overflow: hidden;
}

.following,
.followers {
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  color: #bbb;
  font-weight: 300;
}

.followers {
  float: right;
  padding-right: 30px;
}

.bottom {
  margin-top: 10px;
}

.count {
  color: black;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
}
/**FIN DE ESTILOS TARJETAS */

.games .cards #tarjetas #profile .link-reu {
  height: 12%;
  width: auto; 
  margin: 1% 5%; 
  padding: 1% 5%;
  background-color: #0070a3;
  border: 0.2rem solid #0070a3;
  border-radius: 10px;
  color: white;
  transform: translateX(18%);
  font-size: 1.2rem;
}
.games .cards #tarjetas #profile .link-reu:hover{
  background-color: white;
  border: 0.2rem solid #0070a3;
  color: #0070a3;
  scale: 1.2;
}
</style>

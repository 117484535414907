<template>
    <section style="padding-top: 3rem;" class="footer">
   
   <div class="box-container">
       <div class="box">
           <h3 style="color: #0070A3;">Conecta tu Futuro UCB <i class=""></i></h3>
           <p>Conecta tu Futuro es un evento que facilita el contacto entre instituciones empleadoras y personas interesadas en obtener una PASANTÍA O PRÁCTICA PRE PROFESIONAL.</p>
           <div class="share">
               <a href="https://www.facebook.com/UCB.BOLIVIA?locale=es_LA" class="fab fa-facebook-f"></a>
               <a href="https://twitter.com/UCBLaPaz?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" class="fab fa-twitter"></a>
               <a href="https://www.instagram.com/ucb.lapaz/?hl=es" class="fab fa-instagram"></a>
               <a href="https://www.linkedin.com/company/ucb-lapaz/?originalSubdomain=bo" class="fab fa-linkedin"></a>
           </div>
       </div>
       <div class="box">
           <h3 style="color: #0070A3;">Contactos</h3>
           <a class="links"><i class="fas fa-phone"></i>2 2692723</a>
           <a class="links"><i class="fas fa-phone"></i>+591 76521445</a>
           <a class="links"><i class="fas fa-envelope"></i>usei.lpz@ucb.edu.bo</a>
           <a class="links"><i class="fas fa-map-marker-alt"></i>La Paz, Bolivia</a>
       </div>
       <div class="box">
           <h3 style="color: #0070A3;">Navegación</h3>
             <router-link to="/"><a class="links"><i class="fas fa-arrow-right"></i>Inicio</a></router-link>
             <router-link to="/instituciones"><a class="links"><i class="fas fa-arrow-right"></i>Instituciones</a></router-link>
             <router-link to="/calendario_charlas"><a class="links"><i class="fas fa-arrow-right"></i>Calendario</a></router-link>
             <router-link to="/lista_charlas"><a class="links"><i class="fas fa-arrow-right"></i>Charlas</a></router-link>
             <router-link to="/contactos"><a class="links"><i class="fas fa-arrow-right"></i>Contactos</a></router-link>
       </div>
       <div class="box">
           <h3 style="color: #0070A3;">¡Notifícame!</h3>
           <p>Envíanos un correo para mantenerte actualizado</p>
           <input type="email" placeholder="email" class="email" id="email">
           <input style="background-color: #0070A3;" type="submit" value="Notificame" class="btn" @click="openEmailClient">
           <img src="" alt="" class="payment-img">
       </div>
   </div>
   <div class="credit" style="color: #0070A3;" >Conecta tu Futuro UCB<span style="color: #0070A3;">- Todos los derechos reservados</span></div>

</section>
</template>

<script>
export default {
 name: "AppFooter",
 methods:{
     openEmailClient(){
         const email = document.getElementById('email').value;
         const usei = 'usei.lpz@ucb.edu.bo';

         console.log('Correo: ' + email + '\nUsei: ' + usei);
         window.location.href = "mailto:" + usei + "?subject=Solicitud de subscripcion &body=El correo " + email + " se ha suscrito a la página de USEI UCB";
     }
 }
};
</script>

<style scoped>
/*EMPIEZA CSS NILMAR*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');

:root{
 --orange:#ff7800;
 --black: #120f40;
 --darkYellow: #F2B90C;
 --yellow: #F2CB05;
 --marine: #024873;
 --blue: #034C8C;
 --white: #F2F2F2;
 --light-color: #666;
 --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
 --border: .2rem solid rgba(0,0,0,.1);
 --outline: .1rem solid rgba(0,0,0,.1);
 --outline-hover: .2rem solid var(--black);
}

*{
 font-family: 'Poppins', sans-serif;
 margin: 0;padding: 0;
 box-sizing: border-box;
 outline:none;border: none;
 text-decoration: none;
 text-transform: none;
 transition: all .2s linear;
}

/*BOTÓN QUE LLEVA AL INICIO*/
.scroll-top-btn {
position: fixed;
bottom: 25px;
right: 25px;
background-color: var(--black);
color: var(--white);
padding: 20px;
border-radius: 50%;
font-size: 20px;
/*transition: opacity 0.2s ease-in-out;*/
opacity: 0;
z-index: 9999;
}

.scroll-top-btn:hover {
opacity: 0.8;
color: var(--white);
}
/*FIN BOTÓN QUE LLEVA AL INICIO*/

html{
 font-size: 62.5%;
 overflow-x: hidden;
 scroll-behavior: smooth;
 scroll-padding-top: 7rem;
}

body{
 background: #f2f2f2;
}

.features .btn{
 border: .2rem solid var(--black);
 color: var(--darkYellow);               
}

.products .btn{
 border: .2rem solid var(--black);
 color: var(--yellow);
}

.blogs .btn{
 border: .2rem solid var(--black);
 color: var(--yellow);
}

.review .btn{
 border: .2rem solid var(--black);
 color: var(--yellow);
}

.categories .btn{
 border: .2rem solid var(--yellow);
 background-color: var(--black);
 width: 75%;
 height: 4.5rem;
 font-size: 1.8rem;
 box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 align-items: center;
 justify-content: center center;
 color: var(--white);
 padding-top: 7px;
}

.footer .btn{
 border: .2rem solid #0070A3;
 color: var(--yellow);
}
.footer .box-container .box:last-child{
 display: flex;
 flex-direction: column;
}

.modal .btn{
 border: .2rem solid var(--black);
 color: var(--yellow);
}

.btn{
 margin-top: 1rem;
 display: inline-block;
 padding: .8rem 3rem;
 font-size: 2.5rem;
 font-weight: 400;
 border-radius: .5rem;
 border: .2rem solid var(--white);
 color: var(--yellow);
 cursor: pointer;
}

.btn:hover{
 background: var(--black);
 border: .2rem solid var(--white);
 color: #f2f2f2;
 transition: .5s;
 scale: 1.1;
}

/* NAVBAR GENERAL*/
.header{
 position: fixed;
 top: 0;left: 0;right: 0;
 z-index: 1000;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 2rem 9%;
 background: #f2f2f2;
 box-shadow: var(--box-shadow);
}

.header .logo{
 min-width: 7.15rem;
 min-height: 7.15rem;
 /*max-height: 8rem;*/
 height: 50px;
 width: 50px;
 font-size: 3rem;
 text-align: center;
 border-radius: 50%;
 box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
 text-decoration: none;
 color: var(--black);
}
.header .logo:hover{
 transition: .5s;
 scale: 1.2;
}

.header .logo i{
 color: var(--black);
}

.header .navbar a{
 font-size: 1.7rem;
 margin: 0 1rem;
 margin-top: 18px;
 color: var(--black);
 text-decoration: none;
}

.header .navbar a:hover{
 color: var(--black);
 scale: 1.2;
 padding-left: 20px;
 padding-right: 20px;
 transition: .5s;
}


.header .icons div{
 height: 4.5rem;
 width: 4.5rem;
 line-height: 4.5rem;
 border-radius: .5rem;
 background:#eee;
 color: var(--black);
 font-size: 2rem;
 margin-left: .3rem;
 cursor: pointer;
 text-align: center;
}

.header .icons div:hover{
 background: var(--black);
 color: #f2f2f2;
}

#menu-btn{
 display: none;
}


.header .search-form{
 position: absolute;
 top: 110%;right: -110%;
 width: 50rem;
 height: 5rem;
 background: #f2f2f2;
 border-radius: .5rem;
 overflow: hidden;
 display: flex;
 align-items: center;
 box-shadow: var(--box-shadow);
}

.header .search-form.active{
 right: 2rem;
 transition: .5s;
}

.header .search-form input{
 height: 100%;
 width: 100%;
 background: none;
 text-transform: none;
 font-size: 1.6rem;
 
 padding: 0 1.5rem;
}

.header .search-form label{
 font-size: 2.2rem;
 padding-right: 1.5rem;
 color: var(--black);
 cursor: pointer;
}

.header .search-form label:hover{
 color: var(--black);
}



.header .shopping-cart{
 position: absolute;
 top: 100%;
 right: -110%;
 padding: 1rem;
 border-radius: .5rem;
 box-shadow: var(--box-shadow);
 width: 35rem;
 background: #f2f2f2;
}

.header .shopping-cart.active{
 right: 2rem;
 transition: .4s linear;
}

.header .shopping-cart .box{
 display: flex;
 align-items: center;
 gap: 1rem;
 position: relative;
 margin: 1rem 0;
}

.header .shopping-cart .box img{
 height: 10rem;
}

.header .shopping-cart .box .fa-trash{
 font-size: 2rem;
 position: absolute;
 top: 50%;right: 2rem;
 cursor: pointer;
 color: var(--light-color);
 transform: translateY(-50%);
}

.header .shopping-cart .box .fa-trash:hover{
 color: var(--black);
}

.header .shopping-cart .box .content h3{
 color: var(--black);
 font-size: 1.7rem;
 padding-bottom: 1rem;
}

.header .shopping-cart .box .content span{
 color: var(--light-color);
 font-size: 1.6rem;
 padding-bottom: 1rem;
}

.header .shopping-cart .box .content .quantity{
 padding-left: 1rem;

}

.header .shopping-cart .total{
 font-size: 2.5rem;
 padding: 1rem 0;
 text-align: center;
 color: var(--black);
}


.header .shopping-cart .btn{
 display: block;
 text-align: center;
 margin: 1rem;
}

@media(max-width: 991px){
  html{
     font-size: 55%;
  }   
  .header{
     padding: 2rem;
  }
  section{
     padding: 2rem;
 }

}

@media(max-width: 768px){
 .header .navbar{
     position: absolute;
     top: 110%;
     right: -110%;
     width: 30rem;
     box-shadow: var(--box-shadow);
     border-radius: .5rem;
     background: #f2f2f2;
 }

 .header .search-form{
     width: 90%;
 }
 #menu-btn{
     display: inline-block;
 }

 .header .navbar.active{
     right: 2rem;
     transition: .5s linear;
 }

 .header .navbar a{
     font-size: 2rem;
     margin: 2rem 2.5rem;
     display: block;
 }
}

@media(max-width: 450px){
 html{
     font-size: 50%;
 }

 .heading{
     font-size: 2.5rem;
 }

 .footer{
     text-align: center;
 }
}


section{
 padding: 2rem 9%;
}


.home{
 display: flex;
 align-items: center;
 justify-content: center;
 background: url(https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80)no-repeat;
 background-position: center center;
 background-size: cover;
 padding-top: 17rem;
 padding-bottom: 10rem;
}

.home .content{
 text-align: center;
 width: 90rem;
}

.home .content h3{
 color: var(--white);
 font-size: 6rem;
}

.home .content h3 span{
 color: var(--yellow);
}

.home .content p{
 color: var(--white);
 font-size: 1.9rem;
 padding: 1rem 0;
 line-height: 1.8;
}

.heading{
 text-align: center;
 padding: 2rem 0;
 padding-bottom: 3rem;
 font-size: 3.5rem;
 color: var(--darkYellow);
}

.heading span{
 background: var(--black);
 color: #f2f2f2;
 display: inline-block;
 padding: .5rem 3rem;
 clip-path: polygon(100% 0,93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
}

.features .box-container{
 display: grid;
 grid-template-columns: repeat(auto-fit,minmax(30rem,1fr));
 gap:1.5rem;
}

.features .box-container .box{
 padding: 3rem 2rem;
 background: #f2f2f2;
 outline: var(--outline);
 outline-style: -1rem;
 text-align: center;
 box-shadow: var(--box-shadow);
}

.features .box-container .box:hover{
 outline: var(--outline-hover);
 outline-offset: 0rem;
}

.features .box-container .box img{
 margin: 1rem 9;
 height: 15rem;
}

.features .box-container .box h3{
 font-size: 2.5rem;
 line-height: 1.8;
 color: var(--light-color);
 padding: 1rem 0;
}

.products .product-slider{
 padding: 1rem;
}

.products .product-slider:first-child{
 margin-bottom: 2rem;
}

.products .product-slider .box{
 background: #f2f2f2;
 border-radius: .5rem;
 text-align: center;
 padding: 3rem 2rem;
 outline-offset: -1rem;
 outline: var(--outline);
 box-shadow: var(--box-shadow);
}

.products .product-slider .box:hover{
 outline-offset: 0rem;
 outline: var(--outline-hover);
}

.products .product-slider .box img{
 height: 20rem;
}

.products .product-slider .box h3{
 font-size: 2.5rem;
 color: var(--black);
}

.products .product-slider .box .stars i{
 font-size: 1.7rem;
 color: var(--black);
 padding:.5rem 0 ;
}


.categories .box-container{
 display: grid;
 grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
 gap: 1.5rem;
}

.categories .box-container .box{
 padding: 3rem 2rem;
 border-radius: .5rem;
 background: #f2f2f2;
 box-shadow: var(--box-shadow);
 outline: var(--outline);
 outline-offset: -1rem;
 text-align: center;


 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.categories .box-container .box:hover{
 outline: var(--outline-hover);
 outline-offset: 0rem;
}

.categories .box-container img{
 margin: 1rem 0;
 max-height: 25rem;
 min-height: 25rem;
 object-fit: cover;
 width: 95%;
}

.categories .box-container .box h3{
 font-size: 2rem;
 color: var(--black);
 line-height: 1.8;
 height: 7rem;
 display: flex;
 align-items: center;
}

.categories .box-container .box p{
 font-size: 1.7rem;
 color: var(--black);
 line-height: 1.8;
 padding: 1rem 0;
 height: 9rem;
}

.review .review-slider{
 padding: 1rem;
}

.review .review-slider .box{
 background: #f2f2f2;
 border-radius: .5rem;
 text-align: center;
 padding: 3rem 2rem;
 outline-offset: -1rem;
 outline: var(--outline);
 box-shadow: var(--box-shadow);
 transition: .2s linear;
}

.review .review-slider .box:hover{
 outline: var(--outline-hover);
 outline-offset: 0rem;
}

.review .review-slider .box img{
 height: 10rem;
 width: 10rem;
 border-radius: 50%;
}

.review .review-slider .box p{
 padding: 1rem 0;
 line-height: 1.8;
 color: var(--light-color);
 font-size: 1.5rem;
}

.review .review-slider .box h3{
 padding-bottom: .5rem;
 color: var(--black);
 font-size: 2.2rem;
}

.review .review-slider .box .stars i{
 color: var(--black);
 font-size: 1.7rem;
}


.blogs .box-container{
 display: grid;
 grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
 gap: 1.5rem;
}

.blogs .box-container .box{
 overflow: hidden;
 border-radius: .5rem;
 box-shadow: var(--box-shadow);
 background: #f2f2f2;
}

.blogs .box-container .box img{
 height: 25rem;
 width: 100%;
 object-fit: cover;
}

.blogs .box-container .box .content{
 padding: 2rem;
}

.blogs .box-container .box .content .icons{
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding-bottom: 1.5rem;
 margin-bottom: 1rem;
 border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a{
 color: var(--light-color);
 font-size: 1.5rem;
}

.blogs .box-container .box .content .icons a:hover{
 color: var(--black);
}

.blogs .box-container .box .content .icons a i{
 color: var(--black);
 padding-right: .5rem;
}

.blogs .box-container .box .content h3{
 line-height: 1.8;
 color: var(--black);
 font-size: 2.2rem;
 padding: .5rem 0;
}

.blogs .box-container .box .content p{
 line-height: 1.8;
 color: var(--light-color);
 font-size: 1.5rem;
 padding: .5rem 0;
}


.footer{
 padding-top: 50px;
 background: #eee;
 box-shadow: var(--box-shadow );
 text-decoration: none;
}

.footer .box-container{
 display: grid;
 grid-template-columns: repeat(auto-fit,minmax(25rem,1fr));
 gap: 1.5rem;
}

.footer .box-container .box h3{
 font-size: 2.5rem;
 color: var(--black);
 padding: 1rem 0;
}

.footer .box-container .box h3 i{
 color: var(--black);
}

.footer .box-container .box .links{
 display: block;
 font-size: 1.5rem;
 color: var(--light-color);
 padding: 1rem 0;
}

.footer .box-container .box .links i{
 color: var(--black);
 padding-right: .5rem;
}

.footer .box-container .box .links:hover i{
 padding-right: 2rem;
 text-decoration: none;
}

.footer .box-container .box p{
 line-height: 1.8;
 font-size: 1.5rem;
 color: var(--light-color);
 padding: 1rem 0;
}

.footer .box-container .box .share a{
 height: 4rem;
 width: 4rem;
 line-height: 4rem;
 border-radius: .5rem;
 font-size: 2rem;
 color: var(--black);
 margin-left: .2rem;
 text-align: center;
}

.footer .box-container .box .share a:hover{
 background: var(--black);
 color: #f2f2f2;
}

.footer .box-container .box .email{
 margin: .7rem 0;
 padding: 1rem;
 border-radius: .5rem;
 border: .2rem solid #0070A3;
 background: #eee;
 font-size: 1.6rem;
 color: var(--black);
 text-transform: none;
} 

.footer .box-container .box .payment-img{
 margin-top: 2rem;
 height: 3rem;

}

.footer .credit{
 text-align: center;
 margin-top: 2rem;
 padding:1rem;
 padding-top: 2.5rem;
 font-size: 2rem;
 color: var(--black);
 border-top: var(--border);
}

.footer .credit span{
 color: var(--black);
}


a{
 text-decoration: none;
}


/*ACABA CSS NILMAR*/

 .card-img-top {
     width: 100%;
     height: 15vw;
     object-fit: cover;
 }
 .gallery-block{
     padding-bottom: 60px;
     padding-top: 60px;
 
 }
 .row {
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
 -ms-flex-wrap: wrap;
 flex-wrap: wrap;
 margin-right: 0px;
 margin-left: -0px;
 margin-top: 15px;
 }
 .gallery-block .heading{
     margin-bottom: 50px;
     text-align: center;
 }

 .gallery-block .heading h2{
     font-weight: bold;
     font-size: 1.4rem;
     text-transform: uppercase;
 }

 .gallery-block.cards-gallery h6 {
 font-size: 17px;
 font-weight: bold; 
 }

 .gallery-block.cards-gallery .card{
 transition: 0.4s ease; 
 }

 .gallery-block.cards-gallery .card img {
 box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); 
 }

 .gallery-block.cards-gallery .card-body {
 text-align: center; 
 }

 .gallery-block.cards-gallery .card-body p {
 font-size: 15px; 
 }

 .gallery-block.cards-gallery a {
 color: #212529; 
 }

 .gallery-block.cards-gallery a:hover {
 text-decoration: none; 
 }

 .gallery-block.cards-gallery .card {
 margin-bottom: 30px; 
 box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
 }

 @media (min-width: 576px) {

     .gallery-block .transform-on-hover:hover {
         transform: translateY(-10px) scale(1.02);
         box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important; 
     }
 }

</style>
<template>
  <div>
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
      />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
        crossorigin="anonymous"
      />

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
      />

      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
        crossorigin="anonymous"
      />
      <title>{{ paginaInstitucion.Nombre }}</title>
    </head>
    <body>
      <AppNavbar />
      <section style="height: 11rem"></section>
      <!--<a href="#seccion-id" class="boton boton-rebote">Ir a la sección</a>-->
      <br />
      <br />
      <br />

      <section class="dos-columnas">
        <div class="columna1" id="tarjeta_institucion">
          
          <img :src="paginaInstitucion.Logo" :alt="paginaInstitucion.Nombre" />
        </div>
        <div class="columna2">

          <!--
          <h2 class="bienvenida" style="font-weight: bolder; text-transform: none; text-align: center;  margin-bottom: 2rem;" >
            {{ paginaInstitucion.Bienvenida }}
          </h2>
          -->
          <!--<h2 class="heading1" style=" width: 50%; font-size: 1.5rem; color: var(--white); text-align: left;background-color: var(--black);padding-left: 15px;">Tu Banco de confianza</h2>
          -->
          <h2 class="titulo-seccion" style="color: #0070a3" v-if="paginaInstitucion.Nosotros">Nosotros</h2>
          <p class="desc" style="font-weight: bolder" v-if="paginaInstitucion.Nosotros">
            {{ paginaInstitucion.Nosotros }}
          </p>
          <h2 class="titulo-seccion" style="color: #0070a3" v-if="paginaInstitucion.Mision">Misión</h2>
          <p class="desc" style="font-weight: bolder" v-if="paginaInstitucion.Mision">
            {{ paginaInstitucion.Mision }}
          </p>
          <h2 class="titulo-seccion" style="color: #0070a3" v-if="paginaInstitucion.Vision">Visión</h2>
          <p class="desc" style="font-weight: bolder" v-if="paginaInstitucion.Vision">
            {{ paginaInstitucion.Vision }}
          </p>
        </div>
      </section>

      

      <br />
      <section id="body">
        <div class="titulo-video" style="display: flex; margin: 5% 0%">
          <h1 class="heading" style="color: #0070a3">
            Conoce nuestras
            <span style="background-color: #0070a3"> REDES! </span>
          </h1>

        </div>
        <div class="wrapper">
          <div class="icon facebook">
            <a
              v-bind:href="paginaInstitucion.Facebook"
              v-if="paginaInstitucion.Facebook"
              target="_blank"
            >
              <div class="tooltip">Facebook</div>
              <span><i class="fab fa-facebook-f"></i></span>
            </a>
          </div>
          <div class="icon twitter">
            <a
              v-bind:href="paginaInstitucion.Twitter"
              v-if="paginaInstitucion.Twitter"
              target="_blank"
            >
              <div class="tooltip">Twitter</div>
              <span><i class="fab fa-twitter"></i></span>
            </a>
          </div>
          <div class="icon instagram">
            <a
              v-bind:href="paginaInstitucion.Instagram"
              v-if="paginaInstitucion.Instagram !== ''"
              target="_blank"
            >
              <div class="tooltip">Instagram</div>
              <span><i class="fab fa-instagram"></i></span>
            </a>
          </div>
          <div class="icon linkedin">
            <a
              v-bind:href="paginaInstitucion.Linkedin"
              v-if="paginaInstitucion.Linkedin"
              target="_blank"
            >
              <div class="tooltip">Linkedin</div>
              <span><i class="fab fa-linkedin"></i></span>
            </a>
          </div>
          <div class="icon marker">
            <a
              v-bind:href="paginaInstitucion.BolsaTrabajo"
              v-if=" paginaInstitucion.BolsaTrabajo"
              target="_blank"
            >
              <div id="esp" class="tooltip" style="font-size: 1.5rem">BDT</div>
              <span><i class="fas fa-marker"></i></span>
            </a>
          </div>
        </div>
      </section>
      <section id="body" v-if="(paginaInstitucion.Video1 && paginaInstitucion.Video1.length > 5) ||  (paginaInstitucion.Video2 && paginaInstitucion.Video2.length > 5) ||   (paginaInstitucion.Video3 && paginaInstitucion.Video3.length > 5) ">
        <div class="titulo-video" style="display: flex; margin: 5% 0%">
          <h1 class="heading" style="color: #0070a3">
            Conoce Más de Nosotros!
            <span style="background-color: #0070a3"> NUESTROS VIDEOS </span>
          </h1>
        </div>
        <div class="wrapper-videos">
          <iframe width="560" height="315" v-if="paginaInstitucion.Video1 && paginaInstitucion.Video1.length > 5" :src="loadURL(paginaInstitucion.Video1)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <iframe width="560" height="315" v-if="paginaInstitucion.Video2 && paginaInstitucion.Video2.length > 5" :src="loadURL(paginaInstitucion.Video2)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <iframe width="560" height="315" v-if="paginaInstitucion.Video3 && paginaInstitucion.Video3.length > 5" :src="loadURL(paginaInstitucion.Video3)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

          <!--
          <div class="icon facebook section-videos1">
            <a
            v-bind:href="paginaInstitucion.Video1"
              v-if="paginaInstitucion.Video1 && paginaInstitucion.Video1.length > 5"
              target="_blank"
            >
              <div class="tooltip">VIDEO_1</div>
              <span><i class="fas fa-play"></i></span>
            </a>
          </div>
          <div class="icon twitter section-videos2">
            <a
            v-bind:href="paginaInstitucion.Video2"
              v-if="paginaInstitucion.Video2 && paginaInstitucion.Video2.length > 5"
              target="_blank"
            >
              <div class="tooltip">VIDEO_2</div>
              <span><i class="fas fa-play"></i></span>
            </a>
          </div>
          <div class="icon instagram section-videos3">
            <a
            v-bind:href="paginaInstitucion.Video3"
              v-if="paginaInstitucion.Video3 && paginaInstitucion.Video3.length > 5"
              target="_blank"
            >
              <div class="tooltip">VIDEO_3</div>
              <span><i class="fas fa-play"></i></span>
            </a>
          </div>
                  -->

        </div>
      </section>
      <!--Botón que lleva al inicio de la página-->
      <a href="#" class="scroll-top-btn">
        <i class="fas fa-arrow-up"></i>
      </a>

      <section class="blogs" id="blogs">
        <h1 class="heading" style="color: #0070a3">
          {{ paginaInstitucion.Bienvenida
          }}<span style="background-color: #0070a3"> NUESTRAS CHARLAS </span>
        </h1>
        <div class="box-container">
          <div
            class="box"
            v-for="itemcharla in Charlas"
            :key="itemcharla.Id_Charla"
          >
            <img :src="itemcharla.URL_FotoCharla" alt="Imagen Charla" />
            <div class="content">
              <div class="icons">
                <a
                  ><i class="fas fa-calendar" style="color: #0070a3"></i
                  >{{ formatDate(itemcharla.Fecha) }}</a
                >
                <a
                  ><i class="fas fa-clock" style="color: #0070a3"></i
                  >{{ itemcharla.Hora }}</a
                >
              </div>
              <h3 style="color: #0070a3">{{ itemcharla.Nombre }}</h3>
              <p>Cupos: {{ itemcharla.Cantidad_Cupos }}</p>
              <p>Cupos Disponibles: {{ itemcharla.Cupos_Disponibles }}</p>
              <button
                v-if="name"
                href=""
                class="btn"
                @click="doInscripcion(itemcharla.Id_Charla)"
                :disabled="itemcharla.Cupos_Disponibles === 0"
              >
                Inscribirme
              </button>
            </div>
          </div>
        </div>
      </section>
      <br />
      <!--<h3>Presentaciones</h3>-->

      <br />
      <AppFooter />
    </body>
  </div>
  <InscripcionCharla ref="confirmDialogue"></InscripcionCharla>
</template>

<script>
import axios from "axios";
import AppNavbar from "./navbar.vue";
import AppFooter from "./footer.vue";
import InscripcionCharla from "./Componentes_user/Modals/ConfirmDialogue.vue";

import { rutaString, puertoString } from "@/assets/apiconfig";

export default {
  name: "PaginaInstitucion",
  data() {
    return {
      name: sessionStorage.getItem("ci"),

      paginaInstitucion: {
        Nombre: "",
        Logo: "",
        Bienvenida: "",
        Nosotros: "",
        Mision: "",
        Vision: "",
        Telefono: "",
        Correo: "",
        Direccion: "",
      },
      currentImage: 0,
      /*Charlas: {
        Id_Charla: "",
        Nombre: "",
        Fecha: "",
        Hora: "",
        Cantidad_Cupos: "",
        Cupos_Disponibles: "",
      },*/
      Charlas: [],
      Presentaciones: [],
      ruta: rutaString,
      puerto: puertoString,
    };
  },

  async mounted() {
    if (this.name == null) {
      alert("Debes iniciar Sesion");
      this.$router.push(`/instituciones`);
    }
    const id = this.$route.params.id;
    const response = await axios.get(
      `http://${this.ruta}${this.puerto}/pagina_institucion/${id}`
    );
    this.paginaInstitucion = response.data;
    console.log(this.paginaInstitucion);
    if (this.paginaInstitucion == "") {
      alert("Institucion inexistente");
      this.$router.push(`/instituciones`);
    }

    if (this.paginaInstitucion.BolsaTrabajo.length < 5) {
      this.paginaInstitucion.BolsaTrabajo = "";
    }
    if (this.paginaInstitucion.Facebook.length < 5) {
      this.paginaInstitucion.Facebook = "";
    }
    if (this.paginaInstitucion.Twitter.length < 5) {
      this.paginaInstitucion.Twitter = "";
    }
    if (this.paginaInstitucion.Instagram.length < 5) {
      this.paginaInstitucion.Instagram = "";
    }
    if (this.paginaInstitucion.Linkedin.length < 5) {
      this.paginaInstitucion.Linkedin = "";
    }


    const responsecharla = await axios.get(
      `http://${this.ruta}${this.puerto}/charla_inst/${id}`
    );
    console.log(responsecharla);
    this.Charlas = responsecharla.data;

    /*SCRIPTS NAVBAR*/
    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });

    window.addEventListener("scroll", () => {
      navbar.classList.remove("active");
    });

    /*script botón que lleva al inicio de la página*/
    const scrollBtn = document.querySelector(".scroll-top-btn");
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 300) {
        scrollBtn.style.opacity = "1";
      } else {
        scrollBtn.style.opacity = "0";
      }
    });

    scrollBtn.addEventListener("click", function (e) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
    /*FIN DE BOTÓN*/
  },

  methods: {
   loadURL(youtubeURL) {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = youtubeURL.split(
        /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      console.log("url", url);
      const YId =
        undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      console.log("YId", YId);
      if (YId === url[0]) {
        console.log("not a youtube link");
      } else {
        console.log("it's  a youtube video");
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      console.log("topOfQueue", topOfQueue);
      const result = topOfQueue;
      return result;
     
    },

  
     

    async obtenerNombreZoom(auxCi) {
      const response = await axios.get(
        `http://${this.ruta}${this.puerto}/nameuser/${auxCi}`
      );
      return response.data.nombre;
    },
    //Función para obtener el id de la cuenta
    async obtenerIdCuenta(auxCi) {
      const response = await axios.get(
        `http://${this.ruta}${this.puerto}/iduser/${auxCi}`
      );
      return response.data.cuenta_id;
    },

    async processInscripcion(auxCharlaId, auxCi) {
      const auxnombre = await this.obtenerNombreZoom(auxCi);
      const auxid = await this.obtenerIdCuenta(auxCi);
      try {
        const response = await axios.post(
          `http://${this.ruta}${this.puerto}/inscripcionCharla`,
          {
            Fecha: new Date().toISOString().slice(0, 19).replace("T", " "),
            NombreZoom: auxnombre,
            Charlas_Id_Charlas: auxCharlaId,
            cuenta_cuenta_id: auxid,
          }
        );
        if (response.data.affectedRows == 0) {
          alert("Usted ya se encuentra inscrito");
        }

        location.reload();

        // Resto del código
      } catch (err) {
        console.log(err);
      }
    },

    async doInscripcion(auxCharlaId) {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Inscribir Charla",
        message: "Estas seguro que deseas inscribirte esta Charla?",
        okButton: "Inscribir",
      });
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        this.processInscripcion(auxCharlaId, this.name);
      }
    },

    formatDate(dateString) {
        const trimmedDate = dateString.substring(0, 10); // Recortar los primeros 10 caracteres de la cadena
        const parts = trimmedDate.split("-");
        const reversedDate = parts.reverse().join("-");
          return reversedDate;
      },

    prevImage() {
      this.currentImage = (this.currentImage - 1 + 3) % 3;
    },
    nextImage() {
      this.currentImage = (this.currentImage + 1) % 3;
    },

    alertaFalsa(){
      alert("Las incripciones terminaron el 21/7/2023, gracias por participar");
    }


  },
  components: {
    AppFooter,
    AppNavbar,
    InscripcionCharla,
  },
};
</script>

<style scoped>
/*estilos para el sitio*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

:root {
  --orange: #ff7800;
  --black: #120f40;
  /*--black: #221266;*/
  --blackFDE: #221266;
  --darkYellow: #120f40 /*#f2b90c*/;
  --yellow: #120f40 /*#f2cb05*/;
  --marine: #024873;
  --blue: #034c8c;
  --white: #f2f2f2;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2s linear;
}

/**html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
} */

body {
  background: #f2f2f2;
  width: 100vw;
  min-height: 70vh;
}
.cuerpo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

/*responsive presentación de la organización*/
@media screen and (max-width: 768px) {
  .inicio,
  .bienvenido,
  .mision {
    flex-basis: 100%;
  }
}
/*fin del responsive de organización*/

/*Botón que va asecciones por id*/
.boton {
  position: fixed;
  bottom: 20px;
  left: 50%;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  text-decoration: none;
  z-index: 9999;
}

.boton:hover {
  scale: 1.2;
  transition: 0.5s;
  transform: translateY(5px);
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.boton-rebote {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: var(--black);
  color: var(--white);
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  animation: bounce 1s infinite;
}
/*Fin botón */

/*sección dos-columnas*/
.dos-columnas {
  background-color: #ececec;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.columna1 {
  flex-basis: 100%;
  max-width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.columna1 img {
  max-width: 70%;
  max-height: 100%;
  border-radius: 0%;
}

.columna2 {
  flex-basis: 100%;
  max-width: 100%;
  min-width: 80vw;
  padding: 0 20px;
  margin-bottom: 2rem;
}

.columna2 h2 {
  font-size: 3rem;
  width: 100%;
}
.dos-columnas .columna2 .desc {
  font-size: 2rem;
  text-align: justify;
}

@media screen and (max-width: 660px) {
  .columna2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .columna2 .bienvenida {
    font-size: 2rem;
  }
  .columna2 .desc {
    font-size: 1rem;
  }
  .columna2 .titulo-seccion {
    font-size: 2rem;
  }
  
  
}
@media screen and (max-width: 767px) {
  .columna2 .bienvenida {
    font-size: 2rem;
  }
  .columna2 .desc {
    font-size: 1.4rem;
  }
  .columna2 .titulo-seccion {
    font-size: 2rem;
  }
}

.titulo-seccion {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 1rem 0;
}

.titulo-seccion::before,
.titulo-seccion::after {
  content: "●";
  margin-right: 0.5rem;
  color: var(--black);
}

.titulo-seccion::after {
  margin-left: 0.5rem;
}

@media screen and (min-width: 768px) {
  .columna1 {
    flex-basis: 50%;
    max-width: 50%;
    text-align: left;
  }

  .columna2 {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 40px;
  }
}

/*fin de la sección */
.card {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.inicio {
  background-color: #f1f1f1;
  grid-row: 1 / span 2;
}
.logo {
  width: 100%;
}
.bienvenido {
  background-color: rgb(0, 0, 53);
  color: white;
  grid-column: 2;
  grid-row: 1;
}
.mision {
  background-color: rgb(17, 46, 81);
  color: white;
  grid-column: 2;
  grid-row: 2;
}
.title {
  font-size: 70px;
  margin-bottom: 0.5rem;
}
p {
  font-size: 30px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 100%) {
  .cuerpo {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
  }
  .inicio,
  .bienvenido,
  .mision {
    grid-column: 1;
  }
  .bienvenido {
    grid-row: 2;
  }
  .mision {
    grid-row: 3;
  }
}
.carousel {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  overflow: hidden;
}
.carousel-images {
  display: flex;
  width: 300%;
  transition: transform 0.5s ease;
  overflow: hidden; /* Asegura que solo se muestre una imagen a la vez */
}
.carousel-image {
  width: 100%;
  flex-shrink: 0;
}
.carousel-image img {
  width: 100%;
  object-fit: cover;
  height: 400px;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 10;
}
.arrow-left {
  left: 10px;
}
.arrow-right {
  right: 10px;
}
/* Transición basada en la imagen actual */
.carousel-images {
  transform: translateX(calc(-33.33% * var(--current-image)));
}
.contacto {
  flex-basis: 300px;
  background-color: #0074d9; /* Cambiar a azul */
}
.contacto-tarjeta {
  margin: 0;
  padding: 2rem;
  background-color: #000ece;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff;
}
.contacto-tarjeta h2 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}
.contacto-tarjeta ul {
  font-size: 2.5rem;

  list-style: none;
  padding: 0;
  margin: 0;
}
.contacto-tarjeta li {
  margin-bottom: 0.5rem;
}
.registro {
  margin-top: 2rem;
}
.registro button {
  padding: 1rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0074d9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.registro button:hover {
  background-color: #00549e;
}

/*CARTAS BLOGS-PRESENTACIONES*/
.heading1 {
  text-align: center;
  padding: 1.5rem 1rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.blogs {
  width: 100%;
  height: auto;
  padding: 2% 3%;
}
.blogs .btn {
  border: 0.2rem solid #0070a3;
  background-color: #0070a3;
  color: var(--white) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
}
.blogs .btn:hover {
  border: 0.2rem solid #0070a3;
  background-color: var(--white);
  color: var(--black) !important;
  transition: 0.5s;
  width: 100%;
  scale: 1.1;
}

.blogs .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
}

.blogs .box-container .box {
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  background: #f2f2f2;
  max-width: 50rem;
}

.blogs .box-container .box img {
  height: 25rem;
  width: 100%;
  object-fit: cover;
}

.blogs .box-container .box .content {
  padding: 2rem;
}

.blogs .box-container .box .content .icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a {
  color: var(--light-color);
  font-size: 1.5rem;
}

.blogs .box-container .box .content .icons a:hover {
  color: var(--black);
}

.blogs .box-container .box .content .icons a i {
  color: var(--black);
  padding-right: 0.5rem;
}

.blogs .box-container .box .content h3 {
  line-height: 1.8;
  color: var(--black);
  font-size: 2.2rem;
  padding: 0.5rem 0;
  height: 7.5rem;
}

.blogs .box-container .box .content p {
  line-height: 1.8;
  color: var(--light-color);
  font-size: 1.5rem;
  padding: 0.5rem 0;
}
/*FIN CARTAS BLOGS-PRESENTACIONES*/

/*BOTÓN QUE TE LLEVA AL INICIO*/
/*BOTÓN QUE LLEVA AL INICIO*/
.scroll-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: var(--black);
  color: var(--white);
  padding: 20px;
  border-radius: 50%;
  font-size: 20px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: 9999;
}

.scroll-top-btn:hover {
  opacity: 0.8;
}
/*FIN BOTÓN QUE LLEVA AL INICIO*/
/*FIN BOTÓN */
/*INICIO FOOTER*/

.footer {
  padding-top: 50px;
  background: #eee;
  box-shadow: var(--box-shadow);
  text-decoration: none;
}

.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;
}

.footer .box-container .box:last-child {
  display: flex;
  flex-direction: column;
}

.footer .box-container .box h3 {
  font-size: 2.5rem;
  color: var(--black);
  padding: 1rem 0;
}

.footer .box-container .box h3 i {
  color: var(--black);
}

.footer .box-container .box .links {
  display: block;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box .links i {
  color: var(--black);
  padding-right: 0.5rem;
}

.footer .box-container .box .links:hover i {
  padding-right: 2rem;
  text-decoration: none;
}

.footer .box-container .box p {
  line-height: 1.8;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box .share a {
  height: 4rem;
  width: 4rem;
  line-height: 4rem;
  border-radius: 0.5rem;
  font-size: 2rem;
  color: var(--black);
  margin-left: 0.2rem;
  text-align: center;
}

.footer .box-container .box .share a:hover {
  background: var(--black);
  color: #f2f2f2;
}

.footer .box-container .box .email {
  margin: 0.7rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--black);
  background: #eee;
  font-size: 1.6rem;
  color: var(--black);
  text-transform: none;
}

.footer .box-container .box .payment-img {
  margin-top: 2rem;
  height: 3rem;
}

.footer .credit {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 2.5rem;
  font-size: 2rem;
  color: var(--black);
  border-top: var(--border);
}

.footer .credit span {
  color: var(--black);
}

.footer .btn {
  font-size: 20px;
  border: 0.2rem solid var(--black);
  color: var(--white) !important;
  background-color: var(--black);
}
.footer .btn:hover {
  scale: 1.2;
  transition: 0.5s;
  color: var(--black) !important;
}

@media (max-width: 450px) {
  html {
    font-size: 1.5rem;
  }

  .heading {
    font-size: 2.5rem;
  }

  .footer {
    text-align: center;
  }
  #body .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
/*FIN FOOTER*/

/* BOTONES REDER SOCIALES */
#body {
  margin: 2% 0;
  display: flex;
  height: 25%;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.wrapper-videos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  width: 100vw;
  padding: 1rem;
}

  iframe {
    width: 30%;
    height: 30vh; /* Establece la altura relativa al ancho para mantener la proporción en dispositivos móviles */
    margin-bottom: 10px; /* Añade un espacio entre los iframes */
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    .wrapper-videos{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 5%;
    }
    iframe {
      width: 100%;
    }
  }

.wrapper .icon {
  margin: 5%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.5, 0.3, 1.5);
}

.wrapper .icon span {
  display: block;
  height: 60px;
  width: 60px;
  background: white;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.5, 0.3, 1.5);
}

.wrapper .icon span i {
  line-height: 60px;
  font-size: 25px;
}

.wrapper .icon .tooltip {
  position: absolute;
  top: 0;
  z-index: 1;
  background: white;
  color: white;
  padding: 10px 18px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 25px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.5, 0.3, 1.5);
}

.wrapper .icon:hover .tooltip {
  top: -70px;
  opacity: 1;
  pointer-events: auto;
}

.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: white;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.5, 0.3, 1.5);
}

.wrapper .icon:hover span {
  color: #fff;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper .facebook:hover span,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip:before {
  background: #3b5999;
}

.wrapper .facebook:hover .tooltip {
  transform: translateX(-24%);
}
.wrapper .twitter:hover .tooltip {
  transform: translateX(-18.5%);
}
.wrapper .instagram:hover .tooltip {
  transform: translateX(-24%);
}
.wrapper .linkedin:hover .tooltip {
  transform: translateX(-24%);
}
.wrapper .marker:hover .tooltip {
  transform: translateX(-8%);
}

.wrapper .twitter:hover span,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip:before {
  background: #46c1f6;
}
.wrapper .instagram:hover span,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip:before {
  background: #e1303c;
}
.wrapper .linkedin:hover span,
.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip:before {
  background: #0a66c2;
}
.wrapper .marker:hover span,
.wrapper .marker:hover .tooltip,
.wrapper .marker:hover .tooltip:before {
  background: #46c1f6;
}
/*FIN BOTONES*/
.wrapper .section-videos1:hover span,
.wrapper .section-videos1:hover .tooltip,
.wrapper .section-videos1:hover .tooltip:before {
  background: #e1303c;
}
.wrapper .section-videos2:hover span,
.wrapper .section-videos2:hover .tooltip,
.wrapper .section-videos2:hover .tooltip:before {
  background: #e1303c;
}
.wrapper .section-videos3:hover span,
.wrapper .section-videos3:hover .tooltip,
.wrapper .section-videos3:hover .tooltip:before {
  background: #e1303c;
}
</style>
<template>
    <div class="container-tarjeta">
        <div class="cookiesContent" id="cookiesPopup">
            <button class="close" @click="$emit('close-modal')">✖</button>
            <input type="text" id="mensaje" name="mensaje"  v-model="linkImagenModificado" required>
            <button class="custom-button" @click="openPopup()">
                                    <div class="icon">
                                      <div class="arrow-up"></div>
                                      <div class="base"></div>
                                    </div>
                                    Subir imagen
                                  </button>
                                  <IframePopup ref="iframePopup" />
            <img :src="this.linkImagenModificado" alt="cookies-img" />
            <input type="text" id="mensaje" name="mensaje"  v-model="mensajeModificado" required>
            <button class="accept" @click="saveAnuncio()">Guardar!</button>
        </div>
        </div>
</template>
<script>
  import IframePopup from "../IframePopup.vue";
  import axios from "axios";
  import { rutaString } from "@/assets/apiconfig";

export default {
	name: "ModalAnuncio",
    props: {
        mensaje: {
            type: String,
            required: true
        },
        linkImagen: {
            type: String,
            required: true
        }
    },
    components: {
        IframePopup
    },
    data() {
        return {
            mensajeModificado: "",
            linkImagenModificado: ""
        }
    },
    methods: {
          openPopup() {
              window.open('https://uploadr.thijs.gg');
            },

        async saveAnuncio() {
            this.$emit('loading-active');
            console.log(`http://${rutaString}/anuncio/1`);
            await axios.put(`http://${rutaString}/anuncio/1`, {
                Id_anuncio:1,
                texto_anuncio: this.mensajeModificado,
                link_imagen: this.linkImagenModificado
            })
            .then(response => {
                console.log(response);
                this.$emit('loading-inactive');
                this.$emit('close-modal');
            })
            .catch(error => {
                console.log(error);
                this.$emit('loading-inactive');
                this.$emit('close-modal');
            });
            
        }

        
    },
    mounted() {
        this.mensajeModificado = this.mensaje;
        this.linkImagenModificado = this.linkImagen;
    }
   


    
}
</script>

<style scoped>
 .custom-button {
  background-color: #008CBA; /* Color de fondo */
  border: none; /* Remueve los bordes */
  color: white; /* Color del texto */
  padding: 7.5px 16px; /* Espacio alrededor del texto, reducido a la mitad */
  text-align: center; /* Centra el texto */
  text-decoration: none; /* Remueve el subrayado */
  display: inline-block; 
  font-size: 12px; /* Tamaño del texto, reducido a la mitad */
  margin: 2px 1px; /* Margen, reducido a la mitad */
  transition-duration: 0.2s; /* Duración de la transición, reducido a la mitad */
  cursor: pointer; /* Cambia el cursor cuando pasas el mouse encima */
}

.container-tarjeta {

  width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

   
}

.cookiesContent {
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
}

.cookiesContent img {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.cookiesContent p {
  margin-bottom: 20px;
  font-size: 18px;
}
.cookiesContent label {
  margin-bottom: 10px;
}
.cookiesContent input {
  margin-bottom: 20px;
  font-size: 17px;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(0, 139, 203);
  text-transform: none;
}
.close {
    width: 30px;
    font-size: 20px;
    color: #000000;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
  }
  .close:hover {
        color: rgb(120, 48, 131);
}

.accept {
    background-color: rgb(0, 139, 203);
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: white;
    box-shadow: 0px 6px 18px -5px rgba(0, 139, 203, 1);
  }
  .accept:hover {
        background-color: rgb(0, 119, 183);
    }

/* Media Query para pantallas más pequeñas, ajustar según sea necesario */
@media (max-width: 600px) {
  .cookiesContent {
    width: 90%;
  }
}

/* Media Query para pantallas más grandes, ajustar según sea necesario */
@media (min-width: 1200px) {
  .cookiesContent {
    width: 60%;
  }
}
</style>

<template>
	<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous"/>
	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"/>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ" crossorigin="anonymous"/>

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"/>
	<!-- Header Start -->
    <AppNavbar/>
    <!-- Header End -->
	<section style="padding-top: 150px;min-height: 140px; "></section>

	<div id="app1" style="flex-wrap: wrap;justify-content: center;">
		<div class="calendar-controls">
            
                <a class="btn" @click="irAListaCharlas()">Lista Charlas</a>
  

			<div v-if="message" class="notification is-success">
                
                
				<section class="blogs" id="blogs">
				<h1 class="heading"  style="color: #0070A3;" v-if="this.aux.NombreInstitucion!='' ">SOMOS <span style="background-color: #0070A3;" v-if="this.aux.NombreInstitucion!='' "> {{ this.aux.NombreInstitucion }} </span></h1>
				<div class="box-container">
					<div class="box" v-if="this.aux.NombreInstitucion!=''">

						<img :src="this.aux.URL_FotoCharla" alt="Imagen Charla">
						<div class="content">
							<div class="icons">
								<a><i class="fas fa-calendar" v-if="this.aux.Fecha"></i>{{convertDateTimeNormal(this.aux.Fecha)}}</a>
								<a><i class="fas fa-clock" v-if="this.aux.Hora"></i>{{this.aux.Hora}}</a>
							</div>
							<h3 v-if="this.aux.Nombre">{{this.aux.Nombre}}</h3>

							<p v-if="this.aux.Cantidad_Cupos " >Cupos: {{this.aux.Cantidad_Cupos}}</p>
							<p v-if="this.aux.Cupos_Disponibles">Cupos Disponibles: {{this.aux.Cupos_Disponibles}}</p>

                            <button v-if="name" href="" class="btn" @click="doInscripcion(this.aux.Id_Charla)" :disabled="this.aux.Cupos_Disponibles === 0">Inscribirme</button>
						</div>
					</div>
				</div>
			</section>
            </div>
			
			<div class="box">
				<h4 class="title is-5">Charlas</h4>

				

				<div class="field">
					<label class="label">Cantidad de períodos</label>
					<div class="control">
						<div class="select">
							<select v-model="displayPeriodCount">
								<option :value="1">1 mes</option>
								<option :value="2">2 meses</option>
							</select>
						</div>
					</div>
				</div>

				<div class="field">
					<label class="label">Día de inicio de la semana</label>
					<div class="control">
						<div class="select">
							<select v-model="startingDayOfWeek">
								<option v-for="(d, index) in dayNames" :key="index" :value="index">
									{{ d }}
								</option>
							</select>
						</div>
					</div>
				</div>

				

				<div class="field">
					<label class="checkbox">
						<input v-model="displayWeekNumbers" type="checkbox" />
						Mostrar número de semana
					</label>
				</div>

				<div class="field">
					<label class="checkbox">
						<input v-model="showTimes" type="checkbox" />
						Mostrar horas
					</label>
				</div>

				<div class="field">
					<label class="checkbox">
						<input v-model="useDefaultTheme" type="checkbox" />
						Contraste
					</label>
				</div>

				
			</div>

			
		</div>
		<div class="calendar-parent">
			<calendar-view
				:items="items"
				:show-date="showDate"
				:time-format-options="{ hour: 'numeric', minute: '2-digit' }"
				:enable-drag-drop="true"
				:disable-past="disablePast"
				:disable-future="disableFuture"
				:show-times="showTimes"
				:date-classes="myDateClasses"
				:display-period-uom="displayPeriodUom"
				:display-period-count="displayPeriodCount"
				:starting-day-of-week="startingDayOfWeek"
				:class="themeClasses"
				:period-changed-callback="periodChanged"
				:current-period-label="useTodayIcons ? 'icons' : ''"
				:displayWeekNumbers="displayWeekNumbers"
				:enable-date-selection="false"
				:selection-start="selectionStart"
				:selection-end="selectionEnd"
				@date-selection-start="setSelection"
				@date-selection="setSelection"
				@date-selection-finish="finishSelection"
				@drop-on-date="onDrop"
				@click-date="onClickDay"
				@click-item="onClickItem"
			>
            <template v-slot:header="{ headerProps }">
                <calendar-view-header :header-props="headerProps" @input="setShowDate" />
            </template>


			</calendar-view>
		</div>
	</div>
    <br>
    <br>
    <AppFooter/>
      <InscripcionCharla ref="confirmDialogue"></InscripcionCharla>

</template>
<script>
// Load CSS from the published version
import AppNavbar from './navbar.vue';
  import AppFooter from './footer.vue';

// Load CSS from the local repo
import '../../node_modules/vue-simple-calendar/dist/style.css';
import '../../node_modules/vue-simple-calendar/dist/css/default.css';
import '../../node_modules/vue-simple-calendar/dist/css/holidays-us.css';
import InscripcionCharla from "./Componentes_user/Modals/ConfirmDialogue.vue";

//import "../node_modules/vue-simple-calendar/dist/style.css"
//import "../node_modules/vue-simple-calendar/static/css/default.css"
//import "../node_modules/vue-simple-calendar/static/css/holidays-us.css"

//import { CalendarView, CalendarViewHeader, CalendarMath } from "vue-simple-calendar" // published version
import { CalendarView, CalendarViewHeader, CalendarMath } from "vue-simple-calendar" 
import axios from 'axios';
import { rutaString,puertoString } from '@/assets/apiconfig';


export default {
	name: "App",
	components: {
		CalendarView,
		CalendarViewHeader,
        AppFooter,
      AppNavbar,
      InscripcionCharla,
	},
    
	data() {
		return {
            name: sessionStorage.getItem("tipo"),
            cisession: sessionStorage.getItem("ci"),

			/* Show the current month, and give it some fake items to show */
			showDate: this.thisMonth(1),
			message: "",
			startingDayOfWeek: 1,
			disablePast: false,
			disableFuture: false,
			displayPeriodUom: "month",
			displayPeriodCount: 1,
			displayWeekNumbers: false,
			showTimes: true,
			selectionStart: null,
			selectionEnd: null,
			newItemTitle: "",
			newItemStartDate: "",
			newItemEndDate: "",
			useDefaultTheme: true,
			useHolidayTheme: true,
			useTodayIcons: false,
			items: [
				/*{
					id: "e0",
					startDate: "2018-01-05",
				},*/
				
			],
            ruta: rutaString,
      puerto: puertoString,
                aux: {
                    NombreInstitucion: "",
                    Fecha: "",
                },
		}
	},
	computed: {
		userLocale() {
			return CalendarMath.getDefaultBrowserLocale
		},
		dayNames() {
            // en español
            return ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
		},
		themeClasses() {
			return {
				"theme-default": this.useDefaultTheme,
				"holiday-us-traditional": this.useHolidayTheme,
				"holiday-us-official": this.useHolidayTheme,
			}
		},
		myDateClasses() {
			// This was added to demonstrate the dateClasses prop. Note in particular that the
			// keys of the object are `yyyy-mm-dd` ISO date strings (not dates), and the values
			// for those keys are strings or string arrays. Keep in mind that your CSS to style these
			// may need to be fairly specific to make it override your theme's styles. See the
			// CSS at the bottom of this component to see how these are styled.
			const o = {}
			const theFirst = this.thisMonth(1)
			const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 13
			const idesDate = this.thisMonth(ides)
			o[CalendarMath.isoYearMonthDay(idesDate)] = "ides"
			o[CalendarMath.isoYearMonthDay(this.thisMonth(21))] = ["do-you-remember", "the-21st"]
			return o
		},
	},
    async mounted() {

        const navbar = document.querySelector('.navbar');
        const menuBtn = document.querySelector('#menu-btn');
        menuBtn.addEventListener('click', () => {
            navbar.classList.toggle('active');
        });
    
        window.addEventListener('scroll', () => {
            navbar.classList.remove('active');
        });
    
    const responsecharla = await axios.get(`http://${this.ruta}${this.puerto}/charlas`);
    this.Charlas = responsecharla.data;
    this.llenarCalendario(this.Charlas);
    this.newItemStartDate = CalendarMath.isoYearMonthDay(CalendarMath.today())
	this.newItemEndDate = CalendarMath.isoYearMonthDay(CalendarMath.today())
    
  },
	

	methods: {
        irAListaCharlas(){
            this.$router.push(`/lista_charlas`);

        },
      
    //Función para obtener el nombre del zoom
    async obtenerNombreZoom(auxCi) {
      const response = await axios.get(
        `http://${this.ruta}${this.puerto}/nameuser/${auxCi}`
      );
      return response.data.nombre;
    },
    //Función para obtener el id de la cuenta
    async obtenerIdCuenta(auxCi) {
      const response = await axios.get(
        `http://${this.ruta}${this.puerto}/iduser/${auxCi}`
      );
      return response.data.cuenta_id;
    },
    async processInscripcion(auxCharlaId,auxCi) {
     const auxnombre=await this.obtenerNombreZoom(auxCi);
      const auxid=await this.obtenerIdCuenta(auxCi);
      console.log(auxnombre);
      console.log(auxid);
    try {
      const response = await axios.post(`http://${this.ruta}${this.puerto}/inscripcionCharla`, {
        
        Fecha: new Date().toISOString().slice(0, 19).replace('T', ' '),
        NombreZoom: auxnombre,
        Charlas_Id_Charlas: auxCharlaId,
        cuenta_cuenta_id: auxid,
      });

      if (response.data.affectedRows==0){
        alert("Usted ya se encuentra inscrito");
      }
        //reload the page
        location.reload();      


      // Resto del código
    } catch (err) {
      console.log(err);
    }
  },

    async doInscripcion(auxCharlaId) {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Inscribir Charla',
                message: 'Estas seguro que deseas inscribirte esta Charla?',
                okButton: 'Inscribir',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.processInscripcion(auxCharlaId,this.cisession);
            } 
    },

        llenarCalendario(Charlas){
            Charlas.forEach((charla) => {
                //console.log(""+this.obtenerFecha(new Date(charla.Fecha))+" "+charla.Hora);
                this.items.push({
                    
				startDate: this.convertDateTime(""+this.obtenerFecha(new Date(charla.Fecha))+" "+charla.Hora),
				title: charla.NombreInstitucion+": "+charla.Nombre,
				id: charla.Id_Charla,
                 
                style: `${this.obtenerColor(charla.Color)}` // Comprobación por ternario corregida
                
			})
            });
            

            
        },
        obtenerColor(color){
                if(color != null){
                    return 'background-color: '+color+'; color: #ffffff';
                }else{
                    return 'background-color: #a4a4d9';
                }
            },
        obtenerFecha(fechaISO) {
            return fechaISO.toISOString().substring(0, 10);
        },
		periodChanged() {
			// range, eventSource) {
			// Demo does nothing with this information, just including the method to demonstrate how
			// you can listen for changes to the displayed range and react to them (by loading items, etc.)
			//console.log(eventSource)
			//console.log(range)
		},
        convertDateTimeNormal(dateTimeString) {
            console.log(dateTimeString);
            const trimmedDate = dateTimeString.substring(0, 10); // Recortar los primeros 10 caracteres de la cadena
        const parts = trimmedDate.split("-");
        const reversedDate = parts.reverse().join("-");
          return reversedDate;
        },
        convertDateTime(dateTimeString) {
            const dateParts = dateTimeString.split(/[- :]/);
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1;
            const day = parseInt(dateParts[2]);
            const hour = parseInt(dateParts[3]);
            const minute = parseInt(dateParts[4]);
            //console.log(new Date(year, month, day, hour, minute));
            return new Date(year, month, day, hour, minute);
        },

		thisMonth(d, h, m) {
			const t = new Date()
			return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
		},
		onClickDay() {
			this.selectionStart = null
			this.selectionEnd = null
			this.message = false;
            
		},
		onClickItem(e) {
			this.message = true;
            this.Charlas.forEach(itemCharla => {

                if (itemCharla.Id_Charla==e.id){
                    //console.log(itemCharla)
                    this.aux=itemCharla;
                }
                });

            //scroll to the top
            window.scrollTo(0, 0);
		},
		setShowDate(d) {
			this.message = `Changing calendar view to ${d.toLocaleDateString()}`
			this.showDate = d
		},
		setSelection(dateRange) {
			this.selectionEnd = dateRange[1]
			this.selectionStart = dateRange[0]
		},
		finishSelection(dateRange) {
			this.setSelection(dateRange)
			this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`
		},
		onDrop(/*item, date*/) {
            /*
			this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`
			// Determine the delta between the old start date and the date chosen,
			// and apply that delta to both the start and end date to move the item.
			const eLength = CalendarMath.dayDiff(item.startDate, date)
			item.originalItem.startDate = CalendarMath.addDays(item.startDate, eLength)
			item.originalItem.endDate = CalendarMath.addDays(item.endDate, eLength)*/
		},
		clickTestAddItem() {
			this.items.push({
				startDate: this.newItemStartDate,
				endDate: this.newItemEndDate,
				title: this.newItemTitle,
				id: "e" + Math.random().toString(36).substr(2, 10),
			})
			this.message = "You added a calendar item!"
		},
	},
    
}
</script>

<style scoped>
html,
body {
	height: 100%;
	margin: 0;
	background-color: #f7fcff;
}

#app1 {
	display: flex;
	flex-direction: row;
	font-family: Calibri, sans-serif;
	width: 90%;
	min-width: 30rem;
	min-height: 40rem;
	margin-left: auto;
	margin-right: auto;
}

.calendar-controls {
	margin-right: 1rem;
	min-width: 14rem;
	max-width: 30rem;
    font-size: 1.5rem;

}

.calendar-parent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
    font-size: 1.5rem;
	overflow-x: hidden;
	overflow-y: hidden;
    min-height: 80vh;
	max-height: 150vh;
	background-color: white;
}

/* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
.cv-wrapper.period-month.periodCount-2 .cv-week,
.cv-wrapper.period-month.periodCount-3 .cv-week,
.cv-wrapper.period-year .cv-week {
	min-height: 6rem;
}

/* These styles are optional, to illustrate the flexbility of styling the calendar purely with CSS. */

/* Add some styling for items tagged with the "birthday" class */
.theme-default .cv-item.birthday {
	background-color: #e0f0e0;
	border-color: #d7e7d7;
}

.theme-default .cv-item.birthday::before {
	content: "\1F382"; /* Birthday cake */
	margin-right: 0.5em;
}

/* The following classes style the classes computed in myDateClasses and passed to the component's dateClasses prop. */
.theme-default .cv-day.ides {
	background-color: #ffe0e0;
}

.ides .cv-day-number::before {
	content: "\271D";
}

.cv-day.do-you-remember.the-21st .cv-day-number::after {
	content: "\1F30D\1F32C\1F525";
}




























/*EMPIEZA CSS NILMAR*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');

:root{
    --orange:#ff7800;
    --black: #120f40;
    --darkYellow: #F2B90C;
    --yellow: #F2CB05;
    --marine: #024873;
    --blue: #034C8C;
    --white: #F2F2F2;
    --light-color: #666;
    --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
    --border: .2rem solid rgba(0,0,0,.1);
    --outline: .1rem solid rgba(0,0,0,.1);
    --outline-hover: .2rem solid var(--black);
}

*{
    font-family: 'Poppins', sans-serif;
    margin: 0;padding: 0;
    box-sizing: border-box;
    outline:none;border: none;
    text-decoration: none;
    text-transform: capitalize;
    transition: all .2s linear;
}

/*BOTÓN QUE LLEVA AL INICIO*/
.scroll-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: var(--black);
  color: var(--white);
  padding: 20px;
  border-radius: 50%;
  font-size: 20px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: 9999;
}

.scroll-top-btn:hover {
  opacity: 0.8;
}
/*FIN BOTÓN QUE LLEVA AL INICIO*/

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-padding-top: 7rem;
}

body{
    background: #f2f2f2;
}

.features .btn{
    border: .2rem solid var(--black);
    color: var(--darkYellow);               
}

.products .btn{
    border: .2rem solid var(--black);
    color: var(--yellow);
}



.review .btn{
    border: .2rem solid var(--black);
    color: var(--yellow);
}

.categories .btn{
    border: .2rem solid var(--yellow);
    background-color: var(--black);
    width: 75%;
    height: 4.5rem;
    font-size: 1.8rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    align-items: center;
    justify-content: center center;
    color: var(--white);
    padding-top: 7px;
}

.footer .btn{
    border: .2rem solid var(--black);
    color: var(--yellow);
}

.modal .btn{
    border: .2rem solid var(--black);
    color: var(--yellow);
}

.btn{
    margin-top: 1rem;
    display: inline-block;
    padding: .8rem 3rem;
    font-size: 2.5rem;
    font-weight: 400;
    border-radius: .5rem;
    border: .2rem solid var(--white);
    color: var(--yellow);
    cursor: pointer;
}

.btn:hover{
    background: var(--black);
    border: .2rem solid var(--white);
    color: #f2f2f2;
    transition: .5s;
    scale: 1.1;
}

/* NAVBAR GENERAL*/
.header{
    position: fixed;
    top: 0;left: 0;right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 9%;
    background: #f2f2f2;
    box-shadow: var(--box-shadow);
}

.header .logo{
    min-width: 7.15rem;
    min-height: 7.15rem;
    /*max-height: 8rem;*/
    height: 50px;
    width: 50px;
    font-size: 3rem;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    color: var(--black);
}
.header .logo:hover{
    transition: .5s;
    scale: 1.2;
}

.header .logo i{
    color: var(--black);
}

.header .navbar a{
    font-size: 1.7rem;
    margin: 0 1rem;
    margin-top: 18px;
    color: var(--black);
    text-decoration: none;
}

.header .navbar a:hover{
    color: var(--black);
    scale: 1.2;
    padding-left: 20px;
    padding-right: 20px;
    transition: .5s;
}


.header .icons div{
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    border-radius: .5rem;
    background:#eee;
    color: var(--black);
    font-size: 2rem;
    margin-left: .3rem;
    cursor: pointer;
    text-align: center;
}

.header .icons div:hover{
    background: var(--black);
    color: #f2f2f2;
}

#menu-btn{
    display: none;
}


.header .search-form{
    position: absolute;
    top: 110%;right: -110%;
    width: 50rem;
    height: 5rem;
    background: #f2f2f2;
    border-radius: .5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow);
}

.header .search-form.active{
    right: 2rem;
    transition: .5s;
}

.header .search-form input{
    height: 100%;
    width: 100%;
    background: none;
    text-transform: none;
    font-size: 1.6rem;
    color: var(--black);
    padding: 0 1.5rem;
}

.header .search-form label{
    font-size: 2.2rem;
    padding-right: 1.5rem;
    color: var(--black);
    cursor: pointer;
}

.header .search-form label:hover{
    color: var(--black);
}



.header .shopping-cart{
    position: absolute;
    top: 100%;
    right: -110%;
    padding: 1rem;
    border-radius: .5rem;
    box-shadow: var(--box-shadow);
    width: 35rem;
    background: #f2f2f2;
}

.header .shopping-cart.active{
    right: 2rem;
    transition: .4s linear;
}

.header .shopping-cart .box{
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    margin: 1rem 0;
}

.header .shopping-cart .box img{
    height: 10rem;
}

.header .shopping-cart .box .fa-trash{
    font-size: 2rem;
    position: absolute;
    top: 50%;right: 2rem;
    cursor: pointer;
    color: var(--light-color);
    transform: translateY(-50%);
}

.header .shopping-cart .box .fa-trash:hover{
    color: var(--black);
}

.header .shopping-cart .box .content h3{
    color: var(--black);
    font-size: 1.7rem;
    padding-bottom: 1rem;
}

.header .shopping-cart .box .content span{
    color: var(--light-color);
    font-size: 1.6rem;
    padding-bottom: 1rem;
}

.header .shopping-cart .box .content .quantity{
    padding-left: 1rem;

}

.header .shopping-cart .total{
    font-size: 2.5rem;
    padding: 1rem 0;
    text-align: center;
    color: var(--black);
}


.header .shopping-cart .btn{
    display: block;
    text-align: center;
    margin: 1rem;
}

@media(max-width: 991px){
     html{
        font-size: 55%;
     }   
     .header{
        padding: 2rem;
     }
     section{
        padding: 2rem;
    }

}

@media(max-width: 768px){
    .header .navbar{
        position: absolute;
        top: 110%;
        right: -110%;
        width: 30rem;
        box-shadow: var(--box-shadow);
        border-radius: .5rem;
        background: #f2f2f2;
    }

    .header .search-form{
        width: 90%;
    }
    #menu-btn{
        display: inline-block;
    }

    .header .navbar.active{
        right: 2rem;
        transition: .5s linear;
    }

    .header .navbar a{
        font-size: 2rem;
        margin: 2rem 2.5rem;
        display: block;
    }
}

@media(max-width: 450px){
    html{
        font-size: 50%;
    }

    .heading{
        font-size: 2.5rem;
    }

    .footer{
        text-align: center;
    }
}


section{
    padding: 2rem 9%;
}


.home{
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: url()no-repeat;*/
    background-position: center center;
    background-size: cover;
    padding-top: 17rem;
    padding-bottom: 10rem;
}

.home .content{
    text-align: center;
    width: 90rem;
}

.home .content h3{
    color: var(--white);
    font-size: 6rem;
}

.home .content h3 span{
    color: var(--yellow);
}

.home .content p{
    color: var(--white);
    font-size: 1.9rem;
    padding: 1rem 0;
    line-height: 1.8;
}

.heading{
    text-align: center;
    padding: 2rem 0;
    padding-bottom: 3rem;
    font-size: 3.5rem;
    color: var(--darkYellow);
}

.heading span{
    background: var(--black);
    color: #f2f2f2;
    display: inline-block;
    padding: .5rem 3rem;
    clip-path: polygon(100% 0,93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
}

.features .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(30rem,1fr));
    gap:1.5rem;
}

.features .box-container .box{
    padding: 3rem 2rem;
    background: #f2f2f2;
    outline: var(--outline);
    outline-style: -1rem;
    text-align: center;
    box-shadow: var(--box-shadow);
}

.features .box-container .box:hover{
    outline: var(--outline-hover);
    outline-offset: 0rem;
}

.features .box-container .box img{
    margin: 1rem 9;
    height: 15rem;
}

.features .box-container .box h3{
    font-size: 2.5rem;
    line-height: 1.8;
    color: var(--light-color);
    padding: 1rem 0;
}

.products .product-slider{
    padding: 1rem;
}

.products .product-slider:first-child{
    margin-bottom: 2rem;
}

.products .product-slider .box{
    background: #f2f2f2;
    border-radius: .5rem;
    text-align: center;
    padding: 3rem 2rem;
    outline-offset: -1rem;
    outline: var(--outline);
    box-shadow: var(--box-shadow);
}

.products .product-slider .box:hover{
    outline-offset: 0rem;
    outline: var(--outline-hover);
}

.products .product-slider .box img{
    height: 20rem;
}

.products .product-slider .box h3{
    font-size: 2.5rem;
    color: var(--black);
}

.products .product-slider .box .stars i{
    font-size: 1.7rem;
    color: var(--black);
    padding:.5rem 0 ;
}


.categories .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
    gap: 1.5rem;
}

.categories .box-container .box{
    padding: 3rem 2rem;
    border-radius: .5rem;
    background: #f2f2f2;
    box-shadow: var(--box-shadow);
    outline: var(--outline);
    outline-offset: -1rem;
    text-align: center;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categories .box-container .box:hover{
    outline: var(--outline-hover);
    outline-offset: 0rem;
}

.categories .box-container img{
    margin: 1rem 0;
    max-height: 25rem;
    min-height: 25rem;
    object-fit: cover;
    width: 95%;
}

.categories .box-container .box h3{
    font-size: 2rem;
    color: var(--black);
    line-height: 1.8;
    height: 7rem;
    display: flex;
    align-items: center;
}

.categories .box-container .box p{
    font-size: 1.7rem;
    color: var(--black);
    line-height: 1.8;
    padding: 1rem 0;
    height: 9rem;
}

.review .review-slider{
    padding: 1rem;
}

.review .review-slider .box{
    background: #f2f2f2;
    border-radius: .5rem;
    text-align: center;
    padding: 3rem 2rem;
    outline-offset: -1rem;
    outline: var(--outline);
    box-shadow: var(--box-shadow);
    transition: .2s linear;
}

.review .review-slider .box:hover{
    outline: var(--outline-hover);
    outline-offset: 0rem;
}

.review .review-slider .box img{
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
}

.review .review-slider .box p{
    padding: 1rem 0;
    line-height: 1.8;
    color: var(--light-color);
    font-size: 1.5rem;
}

.review .review-slider .box h3{
    padding-bottom: .5rem;
    color: var(--black);
    font-size: 2.2rem;
}

.review .review-slider .box .stars i{
    color: var(--black);
    font-size: 1.7rem;
}

.boton .btn{
    border: .2rem solid var(--black);
    background-color: var(--black);
    color: var(--white)!important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 3.5rem;
    font-size: 1.7rem;
}
.boton .btn:hover{
    border: .2rem solid var(--white);
    color: var(--black) !important;
    transition: .5s;
    scale: 1.1;
}


/*CARTAS BLOGS-PRESENTACIONES*/




.blogs .btn{
    border: .2rem solid var(--yellow);
    background-color: var(--black);
    color: var(--white)!important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 3.5rem;
    font-size: 1.7rem;
}
.blogs .btn:hover{
    border: .2rem solid var(--white);
    color: var(--black) !important;
    transition: .5s;
    scale: 1.1;
}

.blogs .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(26rem,1fr));
    gap: 1.5rem;
}

.blogs .box-container .box{
    overflow: hidden;
    border-radius: .5rem;
    box-shadow: var(--box-shadow);
    background: #f2f2f2;
    max-width: 50rem;
}

.blogs .box-container .box img{
    height: 25rem;
    width: 100%;
    object-fit: cover;
}

.blogs .box-container .box .content{
    padding: 2rem;
}

.blogs .box-container .box .content .icons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a{
    color: var(--light-color);
    font-size: 1.5rem;
}

.blogs .box-container .box .content .icons a:hover{
    color: var(--black);
}

.blogs .box-container .box .content .icons a i{
    color: var(--black);
    padding-right: .5rem;
}

.blogs .box-container .box .content h3{
    line-height: 1.8;
    color: var(--black);
    font-size: 2.2rem;
    padding: .5rem 0;
    height: auto;
}

.blogs .box-container .box .content p{
    line-height: 1.8;
    color: var(--light-color);
    font-size: 1.5rem;
    padding: .5rem 0;
}
/*FIN CARTAS BLOGS-PRESENTACIONES*/


.footer{
    padding-top: 50px;
    background: #eee;
    box-shadow: var(--box-shadow );
    text-decoration: none;
}

.footer .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(25rem,1fr));
    gap: 1.5rem;
}

.footer .box-container .box h3{
    font-size: 2.5rem;
    color: var(--black);
    padding: 1rem 0;
}

.footer .box-container .box h3 i{
    color: var(--black);
}

.footer .box-container .box .links{
    display: block;
    font-size: 1.5rem;
    color: var(--light-color);
    padding: 1rem 0;
}

.footer .box-container .box .links i{
    color: var(--black);
    padding-right: .5rem;
}

.footer .box-container .box .links:hover i{
    padding-right: 2rem;
    text-decoration: none;
}

.footer .box-container .box p{
    line-height: 1.8;
    font-size: 1.5rem;
    color: var(--light-color);
    padding: 1rem 0;
}

.footer .box-container .box .share a{
    height: 4rem;
    width: 4rem;
    line-height: 4rem;
    border-radius: .5rem;
    font-size: 2rem;
    color: var(--black);
    margin-left: .2rem;
    text-align: center;
}

.footer .box-container .box .share a:hover{
    background: var(--black);
    color: #f2f2f2;
}

.footer .box-container .box .email{
    margin: .7rem 0;
    padding: 1rem;
    border-radius: .5rem;
    border: .2rem solid var(--black);
    background: #eee;
    font-size: 1.6rem;
    color: var(--black);
    text-transform: none;
} 

.footer .box-container .box .payment-img{
    margin-top: 2rem;
    height: 3rem;

}

.footer .credit{
    text-align: center;
    margin-top: 2rem;
    padding:1rem;
    padding-top: 2.5rem;
    font-size: 2rem;
    color: var(--black);
    border-top: var(--border);
}

.footer .credit span{
    color: var(--black);
}


a{
    text-decoration: none;
}


/*ACABA CSS NILMAR*/

    .card-img-top {
        width: 100%;
        height: 15vw;
        object-fit: cover;
    }
    .gallery-block{
        padding-bottom: 60px;
        padding-top: 60px;
    
    }
    .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: -0px;
    margin-top: 15px;
    }
    .gallery-block .heading{
        margin-bottom: 50px;
        text-align: center;
    }
  
    .gallery-block .heading h2{
        font-weight: bold;
        font-size: 1.4rem;
        text-transform: uppercase;
    }
  
    .gallery-block.cards-gallery h6 {
    font-size: 17px;
    font-weight: bold; 
    }
  
    .gallery-block.cards-gallery .card{
    transition: 0.4s ease; 
    }
  
    .gallery-block.cards-gallery .card img {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); 
    }
  
    .gallery-block.cards-gallery .card-body {
    text-align: center; 
    }
  
    .gallery-block.cards-gallery .card-body p {
    font-size: 15px; 
    }
  
    .gallery-block.cards-gallery a {
    color: #212529; 
    }
  
    .gallery-block.cards-gallery a:hover {
    text-decoration: none; 
    }
  
    .gallery-block.cards-gallery .card {
    margin-bottom: 30px; 
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }
  
    @media (min-width: 576px) {
  
        .gallery-block .transform-on-hover:hover {
            transform: translateY(-10px) scale(1.02);
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important; 
        }
    }

    .calendar-controls .btn{
        border: .2rem solid #0070A3;
        background-color: #0070A3;
        width: 100%;
        height: 4.5rem;
        font-size: 1.8rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        align-items: center;
        justify-content: center center;
        color: var(--white);
        padding-top: 7px;
    }

.calendar-controls .btn:hover{
    border: .2rem solid var(--white);
    background-color: var(--white);
    color: var(--black) !important;
    transition: .5s;
    scale: 1.1;
}
</style>

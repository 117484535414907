<template>
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
    crossorigin="anonymous"
  />
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
    crossorigin="anonymous"
  />

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
  />
  <br />
  <br />
  <!-- Header Start -->
  <AppNavbar />
  <!-- Header End -->
  <section style="padding-top: 100px; min-height: 110px">
    <img src="../assets/banner2.png" style="width: 80vw;" alt="" />

  </section>
  

  <section style="padding-bottom: 9rem; ">
    <div class="wrapper2">
      <div class="search_box">
        <div class="dropdown" @click="toggleDropdown">
          <div class="default_option">{{ selectedOption }}</div>
          <ul :class="{ active: dropdownActive }">
            <li v-for="option in options" :key="option" @click="selectOption(option)">
              {{ option }}
            </li>
          </ul>
        </div>
        <div class="search_field">
          <input type="text" class="input" v-model="this.searchBox" placeholder="Buscar">
          <i class="fas fa-search"></i>
        </div>
      </div>
    </div>
  </section>


  <section class="categories" id="categories">
    <h3 v-if="!name">Debe iniciar sesion para más Información</h3>
    <h1 class="heading" style="color: #0070a3; z-index: 100;">
      Organizaciones<span style="background-color: #0070a3; ">Afiliadas</span>
    </h1>
    
   
    <div class="box-container">
      <div class="box" v-for="item in filteredItems" :key="item.Id_institucion">
        <img :src="item.URL_Foto" alt="" />
        <h3>{{ item.Nombre }}</h3>
        <p>{{ item.Descripcion }}</p>
        <a v-if="name" @click="verPagina(item.Id_institucion)" class="btn"
          >+ Información</a
        >
      </div>
    </div>
  </section>

  <AppFooter />
  <!--<div>
      <div class="row">
        <div class="col-md-6 col-lg-4 align-items-stretch" v-for="item in items" :key="item.Id_institucion">
          <div class="card border-0 transform-on-hover">
            <a class="lightbox" href="item.URL_Foto">
              <img :src="item.URL_Foto" alt="Card Image" class="card-img-top" >
            </a>
            <div class="card-body">
              <h6><a href="#">{{item.Nombre}}</a></h6>
              <p class="text-muted card-text">{{item.Descripcion}}</p>
              <button class="btn btn-primary" @click="verPagina(item.Id_institucion)">Ver Pagina</button>
            </div>
          </div>
        </div>
      </div>
    </div>-->

  <a href="#" class="scroll-top-btn">
    <i class="fas fa-arrow-up"></i>
  </a>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="container-fluid">
            <div class="row" style="padding: 25px; border-radius: 15px">
              <div
                class="col-lg-5 col-sm-20"
                style="
                  background-position: center center;
                  background-repeat: none;
                  background-size: cover;
                  background-image: url(https://images.unsplash.com/photo-1586769852044-692d6e3703f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGNvbnRhY3QlMjB1c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60);
                  min-height: 300px;
                "
              ></div>
              <div class="col-lg-7">
                <form class="p-lg-5 col-15 row g-3">
                  <div>
                    <h1
                      style="
                        padding-top: 10px;
                        padding-bottom: 10px;
                        font-size: 50px;
                      "
                    >
                      CONFERENCIA ¡Nombre!
                    </h1>
                    <h3>
                      EXPOSITORES: ESTO ESS UN TEXTO DE PRUEBA PARA LA
                      INSCRIPCIÓN A LA CONFERENCIA QUE SEA EXTRAÍDA DE LA BDD
                    </h3>
                  </div>
                  <div class="col-lg-12">
                    <!--<label for="userName" class="form-label">First name</label>
                                          <input type="text" class="form-control" placeholder="Jon" id="userName"
                                              aria-describedby="emailHelp">-->
                    <h1>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Excepturi tempora odit labore, nostrum sequi harum soluta
                      ab aliquid? Dolor alias laboriosam praesentium ab quia
                      officiis sequi dignissimos itaque eum ea.
                    </h1>
                  </div>
                  <div class="col-lg-6">
                    <!--<label for="userName" class="form-label">Last name</label>
                                          <input type="text" class="form-control" placeholder="Doe" id="userName"
                                              aria-describedby="emailHelp">-->
                  </div>
                  <div class="col-12">
                    <!--<label for="userName" class="form-label">Email address</label>
                                          <input type="email" class="form-control" placeholder="Johndoe@example.com" id="userName"
                                              aria-describedby="emailHelp">-->
                  </div>
                  <div class="col-12">
                    <!--<label for="exampleInputEmail1" class="form-label">Enter Message</label>
                                          <textarea name="" placeholder="This is looking great and nice." class="form-control" id=""  rows="4"></textarea>-->
                  </div>

                  <div class="col-15">
                    <button
                      style="width: 100%"
                      type="submit"
                      class="btn btn-brand"
                    >
                      Inscribirme!
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End Modal-->
</template>

<script>
import axios from "axios";
import AppNavbar from "./navbar.vue";
import AppFooter from "./footer.vue";
import { rutaString, puertoString } from "@/assets/apiconfig";

export default {
  name: "ProductList",

  data() {
    return {
      items: [],
      name: sessionStorage.getItem("tipo"),
      ruta: rutaString,
      puerto: puertoString,
      dropdownActive: false,
      selectedOption: 'Todos',
      options: ["Todos"],
      searchBox: "",
    };
  },

  created() {
    this.getSectores();
    this.getInstituciones();
  },


  computed: {
    filteredItems() {
        const normalize = (str) => {
          return str
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        };

        // Filter by searchbox and selected option
        if (this.selectedOption === "Todos") {
          return this.items.filter((item) => {
            return normalize(item.Nombre).includes(normalize(this.searchBox));
          });
        } else {
          return this.items.filter((item) => {
            return (
              normalize(item.Nombre).includes(normalize(this.searchBox)) &&
              normalize(item.Sector).includes(normalize(this.selectedOption))
            );
          });
        }
      }

  },

  methods: {
    toggleDropdown() {
      this.dropdownActive = !this.dropdownActive;
      console.log(this.dropdownActive);
    },
    selectOption(option) {
      this.selectedOption = option;
      console.log(this.dropdownActive);
    },

    async getSectores() {
      try {
        const response = await axios.get(
          `http://${this.ruta}${this.puerto}/instituciones/sectores`
        );

        const sectorNames = response.data.map(item => item.Sector);

        this.options.push(...sectorNames);

        console.log(this.options);
      } catch (err) {
        console.log(err);
      }
    },
    



    async getInstituciones() {
      try {
        const response = await axios.get(
          `http://${this.ruta}${this.puerto}/instituciones`
        );
        this.items = response.data;
      } catch (err) {
        console.log(err);
      }
    },

    

    verPagina(id) {
      // Abrir otra página y enviar el ID del item como parámetro en la URL
      //window.open(`/PaginaInstitucion?id=${id}`, "_blank");
      window.open(`/PaginaInstitucion/${id}`);
    },
  },

  /*SCRIPTS NAVBAR*/
  mounted() {
    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });

    window.addEventListener("scroll", () => {
      navbar.classList.remove("active");
    });

    /*script botón que lleva al inicio de la página*/
    const scrollBtn = document.querySelector(".scroll-top-btn");
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 300) {
        scrollBtn.style.opacity = "1";
      } else {
        scrollBtn.style.opacity = "0";
      }
    });

    scrollBtn.addEventListener("click", function (e) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
    /*FIN DE BOTÓN*/
  },
  /*Componentes*/
  components: {
    AppNavbar,
    AppFooter,
  },
};
</script>
<style >
/*EMPIEZA CSS NILMAR*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

:root {
  --orange: #ff7800;
  --black: #120f40;
  --darkYellow: #120f40 /*#F2B90C*/;
  --yellow: #120f40 /*#F2CB05*/;
  --marine: #024873;
  --blue: #034c8c;
  --white: #f2f2f2;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2s linear;
}

.wrapper2 {
  margin-top: 5rem;
  position: absolute;
  left: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translate(-50%, -50%);
}

.search_box {
  width: 50vw; /* Modificado para adaptarse al ancho de la pantalla */
  max-width: 600px; /* Agregado un ancho máximo para evitar que sea demasiado ancho */
  background: #fff;
  border-radius: 5px;
  height: 65px;
  display: flex;
  padding: 10px;
  box-shadow: 0 8px 6px -10px #b3c6ff;
  border: 2px solid #0070a3;
}

.dropdown {
  width: 200px;
  border-right: 2px solid #dde2f1;
  color: #5b5e6a;
  position: relative;
  cursor: pointer;
}

.default_option {
  text-transform: uppercase;
  padding: 13px 15px;
  font-size: 14px;
}

.dropdown ul {
  position: absolute;
  top: 70px;
  left: -10px;
  background: #fff;
  width: 200px;
  height: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
  padding: 20px;
  display: none;
  box-shadow: 8px 8px 6px -10px #b3c6ff;
  border: 2px solid #0070a3; /* Agregado para el contorno */
  z-index: 1000000000;
}

.dropdown ul.active {
  display: block;
}

.dropdown ul li {
  padding-bottom: 20px;
  font-size: 1.3rem;
}

.dropdown ul li:last-child {
  padding-bottom: 0;
}

.dropdown ul li:hover {
  color: #0070a3;
}

.dropdown:before {
  content: "";
  position: absolute;
  top: 18px;
  right: 20px;
  border: 8px solid;
  border-color: #0070a3 transparent transparent transparent;
}

.search_field {
  width: 350px;
  height: 100%;
  position: relative;
}

.search_field .input {
  width: 100%;
  height: 100%;
  border: 0px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 38px;
  color: #5b5e6a;
}

.search_field .fas {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  color: #0070a3;
  cursor: pointer;
}

::-webkit-input-placeholder {
  color: #5b5e6a;
}
::-moz-placeholder {
  color: #5b5e6a;
}
:-ms-input-placeholder {
  color: #5b5e6a;
}

@media only screen and (max-width: 768px) {
  /* Estilos específicos para tablet */
  .search_box {
    width: 80vw; /* Modificado para adaptarse al ancho de la pantalla de la tablet */
  }

  .dropdown {
    width: 100%; /* O cualquier otro ajuste necesario */
  }

  .search_field {
    width: 100%; /* O cualquier otro ajuste necesario */
  }
}

@media only screen and (max-width: 480px) {
  /* Estilos específicos para teléfono */
  .search_box {
    width: 80vw; /* Modificado para ocupar el ancho completo de la pantalla del teléfono */
  }

  .dropdown {
    width: 100%; /* O cualquier otro ajuste necesario */
  }

  .search_field {
    width: 100%; /* O cualquier otro ajuste necesario */
  }
}



/*BOTÓN QUE LLEVA AL INICIO*/
.scroll-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: var(--black);
  color: var(--white);
  padding: 20px;
  border-radius: 50%;
  font-size: 20px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: 9999;
}

.scroll-top-btn:hover {
  opacity: 0.8;
}
/*FIN BOTÓN QUE LLEVA AL INICIO*/

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
}

body {
  background: #f2f2f2;
}

.features .btn {
  border: 0.2rem solid var(--black);
  color: var(--darkYellow);
}

.products .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}

.blogs .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}

.review .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}

.categories .btn {
  border: 0.2rem solid #0070a3 !important;
  background-color: #0070a3;
  width: 75%;
  height: 4.5rem;
  font-size: 1.8rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center center;
  color: var(--white);
  padding-top: 7px;
}

.footer .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}
.footer .box-container .box:last-child {
  display: flex;
  flex-direction: column;
}

.modal .btn {
  border: 0.2rem solid var(--black);
  color: var(--yellow);
}

.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: 0.8rem 3rem;
  font-size: 2.5rem;
  font-weight: 400;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--white);
  color: var(--yellow);
  cursor: pointer;
}

.btn:hover {
  background: var(--black);
  border: 0.2rem solid var(--white);
  color: #f2f2f2;
  transition: 0.5s;
  scale: 1.1;
}

/* NAVBAR GENERAL*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 9%;
  background: #f2f2f2;
  box-shadow: var(--box-shadow);
}

.header .logo {
  min-width: 7.15rem;
  min-height: 7.15rem;
  /*max-height: 8rem;*/
  height: 50px;
  width: 50px;
  font-size: 3rem;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  color: var(--black);
}
.header .logo:hover {
  transition: 0.5s;
  scale: 1.2;
}

.header .logo i {
  color: var(--black);
}

.header .navbar a {
  font-size: 1.7rem;
  margin: 0 1rem;
  margin-top: 18px;
  color: var(--black);
  text-decoration: none;
}

.header .navbar a:hover {
  color: var(--black);
  scale: 1.2;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.5s;
}

.header .icons div {
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.5rem;
  border-radius: 0.5rem;
  background: #eee;
  color: var(--black);
  font-size: 2rem;
  margin-left: 0.3rem;
  cursor: pointer;
  text-align: center;
}

.header .icons div:hover {
  background: var(--black);
  color: #f2f2f2;
}

#menu-btn {
  display: none;
}

.header .search-form {
  position: absolute;
  top: 110%;
  right: -110%;
  width: 50rem;
  height: 5rem;
  background: #f2f2f2;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
}

.header .search-form.active {
  right: 2rem;
  transition: 0.5s;
}

.header .search-form input {
  height: 100%;
  width: 100%;
  background: none;
  text-transform: none;
  font-size: 1.6rem;
  color: var(--black);
  padding: 0 1.5rem;
}

.header .search-form label {
  font-size: 2.2rem;
  padding-right: 1.5rem;
  color: var(--black);
  cursor: pointer;
}

.header .search-form label:hover {
  color: var(--black);
}

.header .shopping-cart {
  position: absolute;
  top: 100%;
  right: -110%;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  width: 35rem;
  background: #f2f2f2;
}

.header .shopping-cart.active {
  right: 2rem;
  transition: 0.4s linear;
}

.header .shopping-cart .box {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  margin: 1rem 0;
}

.header .shopping-cart .box img {
  height: 10rem;
}

.header .shopping-cart .box .fa-trash {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  right: 2rem;
  cursor: pointer;
  color: var(--light-color);
  transform: translateY(-50%);
}

.header .shopping-cart .box .fa-trash:hover {
  color: var(--black);
}

.header .shopping-cart .box .content h3 {
  color: var(--black);
  font-size: 1.7rem;
  padding-bottom: 1rem;
}

.header .shopping-cart .box .content span {
  color: var(--light-color);
  font-size: 1.6rem;
  padding-bottom: 1rem;
}

.header .shopping-cart .box .content .quantity {
  padding-left: 1rem;
}

.header .shopping-cart .total {
  font-size: 2.5rem;
  padding: 1rem 0;
  text-align: center;
  color: var(--black);
}

.header .shopping-cart .btn {
  display: block;
  text-align: center;
  margin: 1rem;
}

@media (max-width: 991px) {
  html {
    font-size: 55%;
  }
  .header {
    padding: 2rem;
  }
  section {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .header .navbar {
    position: absolute;
    top: 110%;
    right: -110%;
    width: 30rem;
    box-shadow: var(--box-shadow);
    border-radius: 0.5rem;
    background: #f2f2f2;
  }

  .header .search-form {
    width: 90%;
  }
  #menu-btn {
    display: inline-block;
  }

  .header .navbar.active {
    right: 2rem;
    transition: 0.5s linear;
  }

  .header .navbar a {
    font-size: 2rem;
    margin: 2rem 2.5rem;
    display: block;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .heading {
    font-size: 2.5rem;
  }

  .footer {
    text-align: center;
  }
}

section {
  padding: 2rem 9%;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  /*background: url()no-repeat;*/
  background-position: center center;
  background-size: cover;
  padding-top: 17rem;
  padding-bottom: 10rem;
}

.home .content {
  text-align: center;
  width: 90rem;
}

.home .content h3 {
  color: var(--white);
  font-size: 6rem;
}

.home .content h3 span {
  color: var(--yellow);
}

.home .content p {
  color: var(--white);
  font-size: 1.9rem;
  padding: 1rem 0;
  line-height: 1.8;
}

.heading {
  text-align: center;
  padding: 2rem 0;
  padding-bottom: 3rem;
  font-size: 3.5rem;
  color: var(--black);
}

.heading span {
  background: var(--black);
  color: #f2f2f2;
  display: inline-block;
  padding: 0.5rem 3rem;
  clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
}
@media (max-width: 450px) {
  .heading span {
    clip-path: none;
  }
}

.features .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
}

.features .box-container .box {
  padding: 3rem 2rem;
  background: #f2f2f2;
  outline: var(--outline);
  outline-style: -1rem;
  text-align: center;
  box-shadow: var(--box-shadow);
}

.features .box-container .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.features .box-container .box img {
  margin: 1rem 9;
  height: 15rem;
}

.features .box-container .box h3 {
  font-size: 2.5rem;
  line-height: 1.8;
  color: var(--light-color);
  padding: 1rem 0;
}

.products .product-slider {
  padding: 1rem;
}

.products .product-slider:first-child {
  margin-bottom: 2rem;
}

.products .product-slider .box {
  background: #f2f2f2;
  border-radius: 0.5rem;
  text-align: center;
  padding: 3rem 2rem;
  outline-offset: -1rem;
  outline: var(--outline);
  box-shadow: var(--box-shadow);
}

.products .product-slider .box:hover {
  outline-offset: 0rem;
  outline: var(--outline-hover);
}

.products .product-slider .box img {
  height: 20rem;
}

.products .product-slider .box h3 {
  font-size: 2.5rem;
  color: var(--black);
}

.products .product-slider .box .stars i {
  font-size: 1.7rem;
  color: var(--black);
  padding: 0.5rem 0;
}

.categories .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  gap: 1.5rem;
}

.categories .box-container .box {
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  background: #f2f2f2;
  box-shadow: var(--box-shadow);
  outline: var(--outline);
  outline-offset: -1rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories .box-container .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.categories .box-container .box img {
  margin: 1rem 0;
  max-height: 100%;
  min-height: 50%;
  object-fit: contain;
  width: 95%;
}

.categories .box-container .box h3 {
  font-size: 2rem;
  color: var(--black);
  line-height: 1.8;
  height: 7rem;
  display: flex;
  align-items: center;
}

.categories .box-container .box p {
  font-size: 1.4rem;
  color: var(--black);
  line-height: 1.8;
  padding: 0.1rem;
  height: 9rem;
}

.review .review-slider {
  padding: 1rem;
}

.review .review-slider .box {
  background: #f2f2f2;
  border-radius: 0.5rem;
  text-align: center;
  padding: 3rem 2rem;
  outline-offset: -1rem;
  outline: var(--outline);
  box-shadow: var(--box-shadow);
  transition: 0.2s linear;
}

.review .review-slider .box:hover {
  outline: var(--outline-hover);
  outline-offset: 0rem;
}

.review .review-slider .box img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
}

.review .review-slider .box p {
  padding: 1rem 0;
  line-height: 1.8;
  color: var(--light-color);
  font-size: 1.5rem;
}

.review .review-slider .box h3 {
  padding-bottom: 0.5rem;
  color: var(--black);
  font-size: 2.2rem;
}

.review .review-slider .box .stars i {
  color: var(--black);
  font-size: 1.7rem;
}

.blogs .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  gap: 1.5rem;
}

.blogs .box-container .box {
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  background: #f2f2f2;
}

.blogs .box-container .box img {
  height: 25rem;
  width: 100%;
  object-fit: cover;
}

.blogs .box-container .box .content {
  padding: 2rem;
}

.blogs .box-container .box .content .icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a {
  color: var(--light-color);
  font-size: 1.5rem;
}

.blogs .box-container .box .content .icons a:hover {
  color: var(--black);
}

.blogs .box-container .box .content .icons a i {
  color: var(--black);
  padding-right: 0.5rem;
}

.blogs .box-container .box .content h3 {
  line-height: 1.8;
  color: var(--black);
  font-size: 2.2rem;
  padding: 0.5rem 0;
}

.blogs .box-container .box .content p {
  line-height: 1.8;
  color: var(--light-color);
  font-size: 1.5rem;
  padding: 0.5rem 0;
}

.footer {
  padding-top: 50px;
  background: #eee;
  box-shadow: var(--box-shadow);
  text-decoration: none;
}

.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;
}

.footer .box-container .box h3 {
  font-size: 2.5rem;
  color: var(--black);
  padding: 1rem 0;
}

.footer .box-container .box h3 i {
  color: var(--black);
}

.footer .box-container .box .links {
  display: block;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box .links i {
  color: var(--black);
  padding-right: 0.5rem;
}

.footer .box-container .box .links:hover i {
  padding-right: 2rem;
  text-decoration: none;
}

.footer .box-container .box p {
  line-height: 1.8;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box .share a {
  height: 4rem;
  width: 4rem;
  line-height: 4rem;
  border-radius: 0.5rem;
  font-size: 2rem;
  color: var(--black);
  margin-left: 0.2rem;
  text-align: center;
}

.footer .box-container .box .share a:hover {
  background: var(--black);
  color: #f2f2f2;
}

.footer .box-container .box .email {
  margin: 0.7rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--black);
  background: #eee;
  font-size: 1.6rem;
  color: var(--black);
  text-transform: none;
}

.footer .box-container .box .payment-img {
  margin-top: 2rem;
  height: 3rem;
}

.footer .credit {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 2.5rem;
  font-size: 2rem;
  color: var(--black);
  border-top: var(--border);
}

.footer .credit span {
  color: var(--black);
}

a {
  text-decoration: none;
}

/*ACABA CSS NILMAR*/

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}
.gallery-block {
  padding-bottom: 60px;
  padding-top: 60px;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: -0px;
  margin-top: 15px;
}
.gallery-block .heading {
  margin-bottom: 50px;
  text-align: center;
}

.gallery-block .heading h2 {
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.gallery-block.cards-gallery h6 {
  font-size: 17px;
  font-weight: bold;
}

.gallery-block.cards-gallery .card {
  transition: 0.4s ease;
}

.gallery-block.cards-gallery .card img {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.gallery-block.cards-gallery .card-body {
  text-align: center;
}

.gallery-block.cards-gallery .card-body p {
  font-size: 15px;
}

.gallery-block.cards-gallery a {
  color: #212529;
}

.gallery-block.cards-gallery a:hover {
  text-decoration: none;
}

.gallery-block.cards-gallery .card {
  margin-bottom: 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

@media (min-width: 576px) {
  .gallery-block .transform-on-hover:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important;
  }
}
</style>
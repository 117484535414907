<template>
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
      crossorigin="anonymous"
    />

    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
      crossorigin="anonymous"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
      crossorigin="anonymous"
    />
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
      crossorigin="anonymous"
    />

    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    />
  </head>

  <body></body>
  <AppNavbar />
  <section style="padding-top: 10rem"></section>
  <div
    style="
      margin: 0;
      padding: 0;
      height: 100%;
      background-image: url('https://commons.bcit.ca/news/files/2018/05/college_students.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    "
  >
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.068382076956!2d-68.11456612395718!3d-16.522645041310575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915f20ee187a3103%3A0x2f2bb2b7df32a24d!2sUniversidad%20Cat%C3%B3lica%20Boliviana%20%22San%20Pablo%22!5e0!3m2!1ses-419!2sbo!4v1683164371559!5m2!1ses-419!2sbo"
      width="100%"
      height="400"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
    <section style="padding-top: 7rem"></section>
    <section>
      <div>
        <div class="contact-form" id="contact">
          <div class="division" id="area">
            <ul class="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div class="column" id="first-colum">
              <h1>Contáctanos</h1>
              <label for="name">Asunto:</label>
              <input type="text" id="name" name="name" required />
              <label for="email">Correo electrónico:</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                value="usei.lpz@ucb.edu.bo"
                readonly
              />
              <label for="message">Mensaje:</label>
              <textarea id="message" name="message" required></textarea>
              <button class="btn" type="submit" @click="openEmailClient">
                Enviar
              </button>
            </div>
            <div class="column" id="second-column">
              <div class="column-2">
                <div class="titulo"><h3>Encuéntranos</h3></div>
                <div class="botones">
                  <a href="https://www.facebook.com/UCB.BOLIVIA/?locale=es_LA" class="facebook"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a href="https://twitter.com/UCBLaPaz?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" class="twitter"><i class="fab fa-twitter"></i></a>
                  <a href="https://z-p42.www.instagram.com/ucb.lapaz/?hl=es-la" class="instagram"
                    ><i class="fab fa-instagram"></i
                  ></a>
                  <a href="https://bo.linkedin.com/company/ucb-lapaz" class="linkedin"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <AppFooter />
</template>

<script>
import AppNavbar from "./navbar.vue";
import AppFooter from "./footer.vue";

export default {
  methods: {
    openEmailClient() {
      const name = document.getElementById("name").value;
      const email = document.getElementById("email").value;
      const message = document.getElementById("message").value;

      const subject = `${name}`;
      const body = `${message}`;
      console.log(`mailto:${email}?subject=${subject}&body=${body}`);
      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    },
  },
  async mounted() {
    /**SCRIPTS NAVBAR */
    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });

    window.addEventListener("scroll", () => {
      navbar.classList.remove("active");
    });
  },
  components: {
    AppFooter,
    AppNavbar,
  },
};
</script>

<style scoped>
:root {
  --orange: #ff7800;
  --black: #120f40;
  --darkYellow: #f2b90c;
  --yellow: #f2cb05;
  --marine: #024873;
  --blue: #034c8c;
  --white: #f2f2f2;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
}

/**ESTILOS CONTACTOS */
#contact {
  height: 60vh;
  /**background-color: #221266;*/
  width: 100%;
  padding: 2% 10%;
  transform: translateY(-50%);
}
.division {
  display: grid;
  grid-template-columns: 2.3fr 1.7fr;
  border-radius: 15px;
  /**background-color: brown;*/
  border: 5px solid var(--black);
  width: 100%;
  height: 100%;
  padding: 1%;
}
.column {
  /**background-color: white;*/
  color: var(--white);
  margin: 0 auto;
  width: 100%;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 2rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  z-index: 2;
}
.column h1 {
  font-size: 5rem;
}
input{
  text-transform: initial;

}
.column input {
  width: 100%;
  height: 7%;
  /*background-color: black;*/
  margin: 1% 0;
  color: var(--black);
  padding: 2%;
  font-size: 15px;
}
input:focus,
textarea:focus {
  border: 2px solid var(--black);
}

.column textarea {
  width: 100%;
  height: 20%;
  /*background-color: orangered;*/
  color: var(--black);
  margin: 2% 0;
  padding: 2%;
  font-size: 15px;
}

.column label {
  font-size: 2rem;
}

.btn {
  border: 0.2rem solid var(--black);
  background-color: var(--black);
  color: var(--white) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
}
.btn:hover {
  border: 0.2rem solid var(--white);
  color: var(--black) !important;
  background-color: var(--white);
  transition: 0.5s;
  scale: 1.1;
}

@media screen and (max-width: 1000px) {
  .division {
    grid-template-columns: 1fr;
  }
  #contact {
    transform: translateY(-40%);
    padding: 2% 0;
    height: 80vh;
  }
  .column {
    font-size: 1rem;
  }
  .column h1 {
    font-size: 2.5rem;
  }
  .botones a {
    margin: 15px;
    background-color: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-size: 30px;
  }
}

.section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  font: 300 60px "Oswald", sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 6px;
}

.contact-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  width: 100%;
}

.form-horizontal {
  max-width: 400px;
  font-family: "Lato";
  font-weight: 400;
}

.form-control,
textarea {
  width: 100%;
  background-color: var(--white);
  color: #fff;
  letter-spacing: 1px;
  text-transform: initial;
}

.send-button {
  margin-top: 15px;
  height: 34px;
  width: 400px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.alt-send-button {
  width: 400px;
  height: 34px;
  transition: all 0.2s ease-in-out;
}

.send-text {
  display: block;
  margin-top: 10px;
  font: 700 12px "Lato", sans-serif;
  letter-spacing: 2px;
}

.alt-send-button:hover {
  transform: translate3d(0px, -29px, 0px);
}

.direct-contact-container {
  max-width: 400px;
}

.contact-list {
  list-style-type: none;
  margin-left: -30px;
  padding-right: 20px;
}

.list-item {
  line-height: 4;
  color: #aaa;
}

.contact-text {
  font: 300 18px "Lato", sans-serif;
  letter-spacing: 1.9px;
  color: #bbb;
}

.place {
  margin-left: 62px;
}

.phone {
  margin-left: 56px;
}

.gmail {
  margin-left: 53px;
}

.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}

.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(27, 27, 27);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #fff;
}

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

.social-media-list li:hover a {
  color: #000;
}

.copyright {
  font: 200 14px "Oswald", sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 850px) {
  .contact-wrapper {
    display: flex;
    flex-direction: column;
  }
  .direct-contact-container,
  .form-horizontal {
    margin: 0 auto;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 300px;
  }
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .botones a {
    margin: 30px;
    font-size: 30px;
  }
  .botones {
    width: 100%;
  }
}

@media screen and (max-width: 569px) {
  .direct-contact-container,
  .form-wrapper {
    float: none;
    margin: 0 auto;
  }
  .form-control,
  textarea {
    margin: 0 auto;
  }

  .name,
  .email,
  textarea {
    width: 280px;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 280px;
  }
  .social-media-list {
    left: 0;
  }
  .social-media-list li {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 2rem;
  }
  .social-media-list li:after {
    width: 55px;
    height: 55px;
    line-height: 55px;
  }
}

@media screen and (max-width: 410px) {
  .send-button {
    width: 99%;
  }
}
/**FIN ESTILOS CONTACTO */
/**ESTILOS BOTONES REDES SOCIALES */

#second-column {
  /**background-color: black;*/
}

.column-2 {
  padding: 2%;
  width: 100%;
  height: 100%;
  /**background-color: #fff;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titulo {
  margin-bottom: 10px;
}

.botones {
  display: flex;
  justify-content: center;
  align-items: center;
}

.botones a {
  margin: 5px;
  background-color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-size: 25px;
}

.botones a:hover {
  background-color: #db6e82;
}

.botones .facebook:hover {
  background-color: #4267b2;
}
.botones .twitter:hover {
  background-color: #1a91da;
}

.botones .linkedin:hover {
  background-color: #0077b5;
}

.botones .instagram:hover,
.botones .facebook:hover,
.botones .twitter:hover,
.botones .linkedin:hover {
  color: var(--white);
}
.botones .instagram {
  color: #c32aa3;
}
.botones .facebook {
  color: #3b5998;
}
.botones .twitter {
  color: #1da1f2;
}
.botones .linkedin {
  color: #0e76a8;
}

.botones a .ins .botones a i {
  color: black;
  font-size: 25px;
}

.botones a:hover {
  animation: vibrate 0.5s;
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-2px);
  }
  40% {
    transform: translateX(2px);
  }
  60% {
    transform: translateX(-2px);
  }
  80% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

@media screen and (min-width: 1350px) {
  .botones a {
    margin: 15px;
    background-color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-size: 30px;
  }
}

/**FIN BOTONES REDES SOCIALES */

/**ANIMATED BACKGROUND FROM CONTACTS */
#area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/**END ANIMATED BACKGROUND*/
</style>

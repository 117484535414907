<template>
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
      crossorigin="anonymous"
    />
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
      crossorigin="anonymous"
    />
  
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    />
    <br />
    <br />
    <!-- Header Start -->
    <AppNavbar />

        <body
        style="min-height: 100vh; width: 100vw; justify-content: center; align-items: center;"
        :class="{ 'blur-background': showEditarAdminModal || showNuevoAdminModal || showBorrarAdminModal }"
        >
        <br />
        <div
            style="display: flex; justify-content: center; align-items: center; height: 10rem;"
        >
            <button style="height: 1rem;"></button>
        </div>
        <div
            style="display: flex; justify-content: center; align-items: center; margin-top: 5rem;"
        >
            <h1 style="font-size: 3rem; font-weight: bold; color: #043C4B;">Estudiantes</h1>
        </div>
        <div class="contenedorBotonNuevo">
            <input
            type="text"
            v-model="searchText"
            placeholder="Buscar por nombre o ci..."
            class="search"
            />
           
        </div>

        <table class="container">
            <thead>
            <tr>
                <th><h1>Ci</h1></th>
                <th><h1>Nombre</h1></th>
                <th><h1>Correo</h1></th>
                <th><h1>Controles</h1></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="estudiante in filteredEstudiantes" :key="estudiante.ci">
                <td>{{ estudiante.ci }}</td>
                <td>{{ estudiante.nombre }}</td>
                <td style="text-transform: none;">{{ estudiante.correo }}</td>
                <td class="button-container">
               
                <input
                    style="background-color: #b5123c;"
                    type="button"
                    value="Borrar"
                    class="btn"
                    @click="borrarEstudiante(estudiante)"
                />
                </td>
            </tr>
            </tbody>
        </table>
        </body>

        <AppFooter />
  
    <a href="#" class="scroll-top-btn">
      <i class="fas fa-arrow-up"></i>
    </a>
  
    <!-- Modal -->
  
    <!--End Modal-->
    <!-- Reload the page after closing inscripcionInstitucion modal -->
  
   

    <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"
                 />
  
  </template>
  
  <script>
  import axios from "axios";
  import AppNavbar from "./navbar-admin.vue";
  import AppFooter from "./footer-admin.vue";

  import { rutaString, puertoString } from "@/assets/apiconfig";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  import Swal from 'sweetalert2'

  
  export default {
    name: "ProductList",
    data() {
      return {
        superUsuario:
          sessionStorage.getItem("tipo") === null
            ? -1
            : sessionStorage.getItem("tipo"),
        items: [],
        showModalInstitucion: false,
        showModalInstitucionNueva: false,
        showModalInstitucionBorrar: false,
        institucionSelect: "0",
        showModalAnuncio: false,
        modalLoaded: false,
        isLoading: false,
  
        ruta: rutaString,
        puerto: puertoString,
        searchText: "",
      };
    },

    computed: {
        filteredEstudiantes() {
            //por nombre y appellido dentro de la variable "nombre" y ci en la variable ci
            return this.items.filter((estudiante) => {
                return (
                    estudiante.nombre.toLowerCase().includes(this.searchText.toLowerCase()) ||
                    estudiante.ci.toLowerCase().includes(this.searchText.toLowerCase())
                );
            });
        },
    },
  
    
  
    methods: {
        async borrarEstudiante(estudiante){
           
            let response;
            const { value: inputValue } = await Swal.fire({
                title: `Borrar estudiante CI: ` + estudiante.ci,
                input: "text",
                inputLabel: `Ingrese el ci del estudiante para confirmar`,
                
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                    return "Debes escribir el ci";
                    }
                }
                });
                console.log(inputValue);
                if(inputValue==estudiante.ci){
                    await axios
                    .delete(`http://${this.ruta}${this.puerto}/estudiante/${estudiante.cuenta_id}`)
                    .then((response) => {
                        Swal.fire({
                            title: "Estudiante eliminado",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        console.log(response);
                        this.getEstudiantes();
                    })
                    .catch((error) => {
                        console.log(error);
                        Swal.fire({
                            title: "Error al eliminar estudiante",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
                }
                else{
                    Swal.fire({
                        title: "Error al verificar el CI",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
                console.log(response);
                this.isLoading = false;
                this.isLoading = true;
                await this.getEstudiantes();
                this.isLoading = false;
        },
        async getEstudiantes(){
            this.isLoading = true;
            await axios
            .get(`http://${this.ruta}${this.puerto}/estudiantes`)
            .then((response) => {
                this.items = response.data;
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
            });
            
        
        }
      
    },
  
    /*SCRIPTS NAVBAR*/
    async mounted() {
      if (this.superUsuario != 1) {
        alert("Acceso denegado");
        this.$router.push("/");
      } else {
        console.log("Acceso concedido");
        await this.getEstudiantes();
      }
      const navbar = document.querySelector('.navbar');
        const menuBtn = document.querySelector('#menu-btn');
        menuBtn.addEventListener('click', () => {
            navbar.classList.toggle('active');
        });
    
        window.addEventListener('scroll', () => {
            navbar.classList.remove('active');
        });
    },
    /*Componentes*/
    components: {
      AppNavbar,
      AppFooter,
        Loading,
     
    },
  };
  </script>
  <style scoped>
  /*EMPIEZA CSS NILMAR*/
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");
  @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
/* CSS class to disable scrolling for the entire application */
/* CSS class to disable scrolling */

.search {
  width: 40vw;
  height: 5vh;
  border-radius: 5px;
  border: 2px solid #043C4B;
  padding-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;

  font-weight: bolder;
  color: #043C4B;
  background-color: #FFFFFF;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
@media screen and (max-width: 768px) {
  .search {
    width: 70vw;
    height: 4vh;
    border-radius: 5px;
    border: 1px solid #043C4B;
    padding-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    font-size: 3vw;
    font-weight: bolder;
    color: #043C4B;
    background-color: #FFFFFF;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
}


.blur-background {
  filter: blur(5px); /* Adjust the blur amount as needed */
  pointer-events: none; /* Prevent interactions with the blurred content */
}


.contenedorBotonNuevo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding-right: 7vw;

}
@media screen and (max-width: 768px) {
  .contenedorBotonNuevo{
    padding-right: 1rem;
  }
}


.modal {
    
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 65%; 
  overflow: hidden; 
  overflow-x: hidden; 

}
@media screen and (max-width: 768px) {
  .modal {
    width: 90%; 
    height: auto; 
    overflow: hidden; 
    overflow-x: hidden; 
  }
}


.btn {
  padding: 5px 10px;
  background-color: #043C4B;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Efecto hover para los botones */
.btn:hover {
  background-color: #025169;
  transform: scale(1.05);
}
/* Estilos para la columna de botones */
.button-container {
  justify-content: center;
  align-items: center;
}
/* Media Query para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .button-container {
        display: flex;
    flex-direction: column; /* Cambia la dirección de flex a columna */
    text-align: center; /* Centra el texto */
  }
  .btn {
    padding: 8px 16px; /* Ajustar el padding para pantallas pequeñas */
    font-size: 2.5vw; /* Ajustar el tamaño del texto para pantallas pequeñas */
  }
}


body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    line-height: 1.42em;
    color:#A7A1AE;
}

h1 {
    font-size:3em; 
    font-weight: 300;
    line-height:1em;
    text-align: center;
    color: #4DC3FA;
}

h2 {
    font-size:1em; 
    font-weight: 300;
    text-align: center;
    display: block;
    line-height:1em;
    padding-bottom: 2em;
    color: #FB667A;
}

h2 a {
    font-weight: 700;
    text-transform: uppercase;
    color: #FB667A;
    text-decoration: none;
}

.blue { color: #185875; }
.yellow { color: #FFF842; }

.container th h1 {
        font-weight: bold;
        font-size: 1vw;
    text-align: left;
    color: #0087B5;
}

@media screen and (max-width: 768px) {
    .container th h1 {
        font-size: 3vw; /* Tamaño para pantallas pequeñas */
    }
}

.container td {
        font-weight: normal;
        font-size: 1vw; /* El tamaño de fuente será el 3% del ancho de la ventana */
        -webkit-box-shadow: 0 2px 2px -2px #0E1119;
        -moz-box-shadow: 0 2px 2px -2px #0E1119;
        box-shadow: 0 2px 2px -2px #0E1119;
}

@media screen and (max-width: 768px) {
  .container td {
    font-size: 3vw; /* Tamaño para pantallas pequeñas */
  }
  
}

.container {
    text-align: left;
    overflow: hidden;
    width: 85%;
    margin: 0 auto;
    display: table;
    padding: 0 0 8em 0;
    margin-top: 1rem;
}
@media screen and (max-width: 768px) {
    .container {
        width: 95%;
    }
}

.container td, .container th {
        padding-bottom: 2%;
        padding-top: 2%;
    padding-left:2%;  
}

/* Background-color of the odd rows */
.container tr:nth-child(odd) {
        background-color: #323C50;
}

/* Background-color of the even rows */
.container tr:nth-child(even) {
        background-color: #2C3446;
}

.container th {
        background-color: #1F2739;
}

.container td:first-child { color: #3DA8CC;
    font-weight: bold;
    font-size: 1vw;
}
@media screen and (max-width: 768px) {
    .container td:first-child {
        font-size: 2vw; /* Tamaño para pantallas pequeñas */
    }
}

.container tr:hover {
    background-color: #464A52;
    -webkit-box-shadow: 0 6px 6px -6px #0E1119;
    -moz-box-shadow: 0 6px 6px -6px #0E1119;
    box-shadow: 0 6px 6px -6px #0E1119;
}

.container td:hover {
    background-color: #3DA8CC;
    color: #403E10;
    font-weight: bold;
    
    box-shadow: #003242 -1px 1px, #003242 -2px 2px, #003242 -3px 3px, #003242 -4px 4px, #003242 -5px 5px, #003242 -6px 6px;
    transform: translate3d(6px, -6px, 0);
    
    transition-delay: 0s;
        transition-duration: 0.4s;
        transition-property: all;
    transition-timing-function: line;
}

@media (max-width: 800px) {
.container td:nth-child(3),
.container th:nth-child(3) { display: none; }
}
  :root {
    --orange: #ff7800;
    --black: #120f40;
    --darkYellow: #f2b90c;
    --yellow: #f2cb05;
    --marine: #024873;
    --blue: #034c8c;
    --white: #f2f2f2;
    --light-color: #666;
    --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
    --border: 0.2rem solid rgba(0, 0, 0, 0.1);
    --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
    --outline-hover: 0.2rem solid var(--black);
  }
  
  * {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.2s linear;
  }
  .contenedor-modal {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }


  /*BOTÓN QUE LLEVA AL INICIO*/
  .scroll-top-btn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    background-color: var(--black);
    color: var(--white);
    padding: 20px;
    border-radius: 50%;
    font-size: 20px;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    z-index: 9999;
  }
  
  .scroll-top-btn:hover {
    opacity: 0.8;
  }
  /*FIN BOTÓN QUE LLEVA AL INICIO*/
  
  html {
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-padding-top: 7rem;
  }
  
  body {
    background: #f2f2f2;
  }
  
  .features .btn {
    border: 0.2rem solid var(--black);
    color: var(--darkYellow);
  }
  
  .products .btn {
    border: 0.2rem solid var(--black);
    color: var(--yellow);
  }
  
  .blogs .btn {
    border: 0.2rem solid var(--black);
    color: var(--yellow);
  }
  
  .review .btn {
    border: 0.2rem solid var(--black);
    color: var(--yellow);
  }
  
  .categories .btn {
    border: 0.2rem solid var(--black);
    background-color: var(--black);
    width: 75%;
    height: 4.5rem;
    font-size: 1.8rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    align-items: center;
    justify-content: center center;
    color: var(--white);
    padding-top: 7px;
  }
  
  .footer .btn {
    border: 0.2rem solid var(--black);
    color: var(--yellow);
  }
  .footer .box-container .box:last-child {
    display: flex;
    flex-direction: column;
  }
  
  .modal .btn {
    border: 0.2rem solid var(--black);
    color: var(--yellow);
  }
  
  
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: var(--black);
    color: var(--white);
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-out;
  }
  .btn:hover {
    background: var(--black);
    border: 0.2rem solid var(--white);
    color: #f2f2f2;
    transition: 0.5s;
    scale: 1.1;
  }
  
  .box .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: var(--black);
    color: var(--white);
    font-size: 1.3rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-out;
    box-sizing: border-box; /* Agregamos esta propiedad */
  }
  
  .box .btn:hover {
    background: var(--black);
    border: 0.2rem solid var(--white);
    color: #f2f2f2;
    transition: 0.5s;
    transform: scale(1.5); /* corregimos la propiedad scale */
    width: 120%; /* añadimos la propiedad width */
  }
  
  
  /* NAVBAR GENERAL*/
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 9%;
    background: #f2f2f2;
    box-shadow: var(--box-shadow);
  }
  
  .header .logo {
    min-width: 7.15rem;
    min-height: 7.15rem;
    /*max-height: 8rem;*/
    height: 50px;
    width: 50px;
    font-size: 3rem;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    color: var(--black);
  }
  .header .logo:hover {
    transition: 0.5s;
    scale: 1.2;
  }
  
  .header .logo i {
    color: var(--black);
  }
  
  .header .navbar a {
    font-size: 1.7rem;
    margin: 0 1rem;
    margin-top: 18px;
    color: var(--black);
    text-decoration: none;
  }
  
  .header .navbar a:hover {
    color: var(--black);
    scale: 1.2;
    padding-left: 20px;
    padding-right: 20px;
    transition: 0.5s;
  }
  
  .header .icons div {
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    border-radius: 0.5rem;
    background: #eee;
    color: var(--black);
    font-size: 2rem;
    margin-left: 0.3rem;
    cursor: pointer;
    text-align: center;
  }
  
  .header .icons div:hover {
    background: var(--black);
    color: #f2f2f2;
  }
  
  #menu-btn {
    display: none;
  }
  
  .header .search-form {
    position: absolute;
    top: 110%;
    right: -110%;
    width: 50rem;
    height: 5rem;
    background: #f2f2f2;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow);
  }
  
  .header .search-form.active {
    right: 2rem;
    transition: 0.5s;
  }
  
  .header .search-form input {
    height: 100%;
    width: 100%;
    background: none;
    text-transform: none;
    font-size: 1.6rem;
    color: var(--black);
    padding: 0 1.5rem;
  }
  
  .header .search-form label {
    font-size: 2.2rem;
    padding-right: 1.5rem;
    color: var(--black);
    cursor: pointer;
  }
  
  .header .search-form label:hover {
    color: var(--black);
  }
  
  .header .shopping-cart {
    position: absolute;
    top: 100%;
    right: -110%;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow);
    width: 35rem;
    background: #f2f2f2;
  }
  
  .header .shopping-cart.active {
    right: 2rem;
    transition: 0.4s linear;
  }
  
  .header .shopping-cart .box {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    margin: 1rem 0;
  }
  
  .header .shopping-cart .box img {
    height: 10rem;
  }
  
  .header .shopping-cart .box .fa-trash {
    font-size: 2rem;
    position: absolute;
    top: 50%;
    right: 2rem;
    cursor: pointer;
    color: var(--light-color);
    transform: translateY(-50%);
  }
  
  .header .shopping-cart .box .fa-trash:hover {
    color: var(--black);
  }
  
  .header .shopping-cart .box .content h3 {
    color: var(--black);
    font-size: 1.7rem;
    padding-bottom: 1rem;
  }
  
  .header .shopping-cart .box .content span {
    color: var(--light-color);
    font-size: 1.6rem;
    padding-bottom: 1rem;
  }
  
  .header .shopping-cart .box .content .quantity {
    padding-left: 1rem;
  }
  
  .header .shopping-cart .total {
    font-size: 2.5rem;
    padding: 1rem 0;
    text-align: center;
    color: var(--black);
  }
  
  .header .shopping-cart .btn {
    display: block;
    text-align: center;
    margin: 1rem;
  }
  
  @media (max-width: 991px) {
    html {
      font-size: 55%;
    }
    .header {
      padding: 2rem;
    }
    section {
      padding: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .header .navbar {
      position: absolute;
      top: 110%;
      right: -110%;
      width: 30rem;
      box-shadow: var(--box-shadow);
      border-radius: 0.5rem;
      background: #f2f2f2;
    }
  
    .header .search-form {
      width: 90%;
    }
    #menu-btn {
      display: inline-block;
    }
  
    .header .navbar.active {
      right: 2rem;
      transition: 0.5s linear;
    }
  
    .header .navbar a {
      font-size: 2rem;
      margin: 2rem 2.5rem;
      display: block;
    }
  }
  
  @media (max-width: 450px) {
    html {
      font-size: 50%;
    }
  
    .heading {
      font-size: 2.5rem;
    }
  
    .footer {
      text-align: center;
    }
  }
  
  section {
    padding: 2rem 9%;
  }
  
  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: url()no-repeat;*/
    background-position: center center;
    background-size: cover;
    padding-top: 17rem;
    padding-bottom: 10rem;
  }
  
  .home .content {
    text-align: center;
    width: 90rem;
  }
  
  .home .content h3 {
    color: var(--white);
    font-size: 6rem;
  }
  
  .home .content h3 span {
    color: var(--yellow);
  }
  
  .home .content p {
    color: var(--white);
    font-size: 1.9rem;
    padding: 1rem 0;
    line-height: 1.8;
  }
  
  .heading {
    text-align: center;
    padding: 2rem 0;
    padding-bottom: 3rem;
    font-size: 3.5rem;
    color: var(--darkYellow);
  }
  
  .heading span {
    background: var(--black);
    color: #f2f2f2;
    display: inline-block;
    padding: 0.5rem 3rem;
    clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
  }
  
  .features .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 1.5rem;
  }
  
  .features .box-container .box {
    padding: 3rem 2rem;
    background: #f2f2f2;
    outline: var(--outline);
    outline-style: -1rem;
    text-align: center;
    box-shadow: var(--box-shadow);
  }
  
  .features .box-container .box:hover {
    outline: var(--outline-hover);
    outline-offset: 0rem;
  }
  
  .features .box-container .box img {
    margin: 1rem 9;
    height: 15rem;
  }
  
  .features .box-container .box h3 {
    font-size: 2.5rem;
    line-height: 1.8;
    color: var(--light-color);
    padding: 1rem 0;
  }
  
  .products .product-slider {
    padding: 1rem;
  }
  
  .products .product-slider:first-child {
    margin-bottom: 2rem;
  }
  
  .products .product-slider .box {
    background: #f2f2f2;
    border-radius: 0.5rem;
    text-align: center;
    padding: 3rem 2rem;
    outline-offset: -1rem;
    outline: var(--outline);
    box-shadow: var(--box-shadow);
  }
  
  .products .product-slider .box:hover {
    outline-offset: 0rem;
    outline: var(--outline-hover);
  }
  
  .products .product-slider .box img {
    height: 20rem;
  }
  
  .products .product-slider .box h3 {
    font-size: 2.5rem;
    color: var(--black);
  }
  
  .products .product-slider .box .stars i {
    font-size: 1.7rem;
    color: var(--black);
    padding: 0.5rem 0;
  }
  
  .categories .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
    gap: 1.5rem;
  }
  
  .categories .box-container .box {
    padding: 3rem 2rem;
    border-radius: 0.5rem;
    background: #f2f2f2;
    box-shadow: var(--box-shadow);
    outline: var(--outline);
    outline-offset: -1rem;
    text-align: center;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  .categories .box-container .box .anadir {
    height: 75%;
    width: 100%;
    font-size: 22rem;
    color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 1.5s;
  }
  
  .categories .box-container .box .anadir:hover {
    animation: rotate 5s ease-in-out;
    transform: rotateZ(360deg);
  }
  
  .categories .box-container .box:hover .add-text {
    opacity: 1;
  }
  
  .categories .box-container .box .add-text {
    position: absolute;
    font-size: 2rem;
    color: var(--black);
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.5s;
    align-self: flex-end;
  }
  
  .categories .box-container .box .anadir {
    height: 100%;
    width: 100%;
    font-size: 22rem;
    color: var(--black);
  }
  
  .categories .box-container .box:hover {
    outline: var(--outline-hover);
    outline-offset: 0rem;
  }
  
  .categories .box-container .box img {
    margin: 1rem 0;
    max-height: 25rem;
    min-height: 25rem;
    object-fit: contain;
    width: 95%;
  }
  
  .categories .box-container .box h3 {
    font-size: 2rem;
    color: var(--black);
    line-height: 1.8;
    height: 7rem;
    display: flex;
    align-items: center;
  }
  
  .categories .box-container .box p {
    font-size: 1.2rem;
    color: var(--black);
    line-height: 1.8;
    padding: 1rem 0;
    height: 9rem;
  }
  
  .review .review-slider {
    padding: 1rem;
  }
  
  .review .review-slider .box {
    background: #f2f2f2;
    border-radius: 0.5rem;
    text-align: center;
    padding: 3rem 2rem;
    outline-offset: -1rem;
    outline: var(--outline);
    box-shadow: var(--box-shadow);
    transition: 0.2s linear;
  }
  
  .review .review-slider .box:hover {
    outline: var(--outline-hover);
    outline-offset: 0rem;
  }
  
  .review .review-slider .box img {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
  }
  
  .review .review-slider .box p {
    padding: 1rem 0;
    line-height: 1.8;
    color: var(--light-color);
    font-size: 1.5rem;
  }
  
  .review .review-slider .box h3 {
    padding-bottom: 0.5rem;
    color: var(--black);
    font-size: 2.2rem;
  }
  
  .review .review-slider .box .stars i {
    color: var(--black);
    font-size: 1.7rem;
  }
  
  .blogs .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
    gap: 1.5rem;
  }
  
  .blogs .box-container .box {
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow);
    background: #f2f2f2;
  }
  
  .blogs .box-container .box img {
    height: 25rem;
    width: 100%;
    object-fit: cover;
  }
  
  .blogs .box-container .box .content {
    padding: 2rem;
  }
  
  .blogs .box-container .box .content .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: var(--border);
  }
  
  .blogs .box-container .box .content .icons a {
    color: var(--light-color);
    font-size: 1.5rem;
  }
  
  .blogs .box-container .box .content .icons a:hover {
    color: var(--black);
  }
  
  .blogs .box-container .box .content .icons a i {
    color: var(--black);
    padding-right: 0.5rem;
  }
  
  .blogs .box-container .box .content h3 {
    line-height: 1.8;
    color: var(--black);
    font-size: 2.2rem;
    padding: 0.5rem 0;
  }
  
  .blogs .box-container .box .content p {
    line-height: 1.8;
    color: var(--light-color);
    font-size: 1.5rem;
    padding: 0.5rem 0;
  }
  
  .footer {
    padding-top: 50px;
    background: #eee;
    box-shadow: var(--box-shadow);
    text-decoration: none;
  }
  
  .footer .box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 1.5rem;
  }
  
  .footer .box-container .box h3 {
    font-size: 2.5rem;
    color: var(--black);
    padding: 1rem 0;
  }
  
  .footer .box-container .box h3 i {
    color: var(--black);
  }
  
  .footer .box-container .box .links {
    display: block;
    font-size: 1.5rem;
    color: var(--light-color);
    padding: 1rem 0;
  }
  
  .footer .box-container .box .links i {
    color: var(--black);
    padding-right: 0.5rem;
  }
  
  .footer .box-container .box .links:hover i {
    padding-right: 2rem;
    text-decoration: none;
  }
  
  .footer .box-container .box p {
    line-height: 1.8;
    font-size: 1.5rem;
    color: var(--light-color);
    padding: 1rem 0;
  }
  
  .footer .box-container .box .share a {
    height: 4rem;
    width: 4rem;
    line-height: 4rem;
    border-radius: 0.5rem;
    font-size: 2rem;
    color: var(--black);
    margin-left: 0.2rem;
    text-align: center;
  }
  
  .footer .box-container .box .share a:hover {
    background: var(--black);
    color: #f2f2f2;
  }
  
  .footer .box-container .box .email {
    margin: 0.7rem 0;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 0.2rem solid var(--black);
    background: #eee;
    font-size: 1.6rem;
    color: var(--black);
    text-transform: none;
  }
  
  .footer .box-container .box .payment-img {
    margin-top: 2rem;
    height: 3rem;
  }
  
  .footer .credit {
    text-align: center;
    margin-top: 2rem;
    padding: 1rem;
    padding-top: 2.5rem;
    font-size: 2rem;
    color: var(--black);
    border-top: var(--border);
  }
  
  .footer .credit span {
    color: var(--black);
  }
  
  a {
    text-decoration: none;
  }
  
  /*ACABA CSS NILMAR*/
  
  .card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
  }
  .gallery-block {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: -0px;
    margin-top: 15px;
  }
  .gallery-block .heading {
    margin-bottom: 50px;
    text-align: center;
  }
  
  .gallery-block .heading h2 {
    font-weight: bold;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  
  .gallery-block.cards-gallery h6 {
    font-size: 17px;
    font-weight: bold;
  }
  
  .gallery-block.cards-gallery .card {
    transition: 0.4s ease;
  }
  
  .gallery-block.cards-gallery .card img {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }
  
  .gallery-block.cards-gallery .card-body {
    text-align: center;
  }
  
  .gallery-block.cards-gallery .card-body p {
    font-size: 15px;
  }
  
  .gallery-block.cards-gallery a {
    color: #212529;
  }
  
  .gallery-block.cards-gallery a:hover {
    text-decoration: none;
  }
  
  .gallery-block.cards-gallery .card {
    margin-bottom: 30px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }
  
  @media (min-width: 576px) {
    .gallery-block .transform-on-hover:hover {
      transform: translateY(-10px) scale(1.02);
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important;
    }
  }
  
  /*Arreglo botones de Admin*/
  .mensaje {
    display: none;
    margin-right: 5px;
    font-weight: normal;
    font-size: 10px;
    text-transform: uppercase;
  }
  
  .btn:hover .mensaje {
    transition: 0.5s;
    display: block;
  }
  /*Fin botones admin */
  </style>
<template>
  <div class="modal">
    <div class="modal-content">
      <button class="close-button" @click="closeModalForm">X</button>
      <div class="form">
        <div class="flex-column">
          <label>Nueva Contraseña </label>
        </div>
          <InputPassword
            inputId="password"
            type="password"
            name="password"
            class="inputPassword"
            v-model="PasswordIngresado"
            weakLabel="Débil"
            mediumLabel="Media"
            strongLabel="Fuerte"
            inputStyle="text-transform: initial; width: 100%;"
            veryStrongLabel="Perfecto!"
            promptLabel="Ingrese su contraseña"
            panelStyle="background: #f4f4f4; color: #333; border: 1px solid #ccc; padding: 10px; border-radius: 5px; width: 250px; margin: 10px 0;"
            toggleMask
            mediumRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})"
            strongRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,;:'])(?=.{12,})"
            style="width: 100%"
          >
            <template #footer>
              <p style="margin: 0; font-size: 1.4rem">
                La contraseña debe contener:
              </p>
              <ul
                style="
                  line-height: 1.5;
                  font-size: 1.2rem;
                  list-style-type: none;
                "
              >
                <li>Una minúscula</li>
                <li>Una mayúscula</li>
                <li>Un número</li>
                <li>Un caracter especial: !@#$%^&*.,;:'</li>
                <li>Debe ser de al menos 12 caracteres</li>
              </ul>
            </template>
          </InputPassword>
          <label
            for="password"
            class="password-label"
            v-show="!PasswordIngresado.length > 0"
            >Contraseña</label
          >

        <div class="flex-column">
          <label>Repite tu nueva contraseña </label>
        </div>
          <InputPassword
            inputId="password"
            type="password"
            name="password"
            class="inputPassword"
            v-model="PasswordConfirmar"
            weakLabel="Débil"
            mediumLabel="Media"
            strongLabel="Fuerte"
            inputStyle="text-transform: initial;"
            veryStrongLabel="Perfecto!"
            promptLabel="Confirme su contraseña"
            panelStyle="background: #f4f4f4; color: #333; border: 1px solid #ccc; padding: 10px; border-radius: 5px; width: 250px; margin: 10px 0; z-index: 3000;"
            toggleMask
            mediumRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})"
            strongRegex="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,;:'])(?=.{12,})"
            style="width: 100%"
          >
            <template #footer>
              <p style="margin: 0; font-size: 1.4rem">
                La contraseña debe contener:
              </p>
              <ul
                style="
                  line-height: 1.5;
                  font-size: 1.2rem;
                  list-style-type: none;
                "
              >
                <li>Una minúscula</li>
                <li>Una mayúscula</li>
                <li>Un número</li>
                <li>Un caracter especial: !@#$%^&*.,;:'</li>
                <li>Debe ser de al menos 12 caracteres</li>
              </ul>
            </template>
          </InputPassword>
          <label
            for="password"
            class="password-label"
            v-show="!PasswordConfirmar.length > 0"
            >Confirmar Contraseña</label
          >
        <div class="search" style="width: 100%">
          <input placeholder="Ingresar Código" type="" v-model="codigo" />
          <button type="submit" @click="verificarCodigo()">Verificar</button>
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";
import { rutaString, puertoString } from "@/assets/apiconfig";
import Loading from "vue-loading-overlay";
export default {
  name: "FormRecuperacionModal",
  data() {
    return {
      PasswordIngresado: "",
      PasswordConfirmar: "",
      ruta: rutaString,
      puerto: puertoString,
      codigo: "",
      isLoading: false,
    };
  },
  components: {
    Loading,
  },
  methods: {
    closeModalForm() {
      this.$emit("close");
    },
    async reiniciarContrasenia() {
      if (this.PasswordIngresado === this.PasswordConfirmar) {
        if (this.PasswordIngresado.length < 12) {
          alert("La contraseña debe tener al menos 12 caracteres");
          return;
        } else if (!this.PasswordIngresado.match(/[a-z]/g)) {
          alert("La contraseña debe tener al menos una letra minúscula");
          return;
        } else if (!this.PasswordIngresado.match(/[A-Z]/g)) {
          alert("La contraseña debe tener al menos una letra mayúscula");
          return;
        } else if (!this.PasswordIngresado.match(/[0-9]/g)) {
          alert("La contraseña debe tener al menos un número");
          return;
        } else if (!this.PasswordIngresado.match(/[!@#$%^&*.,;:']/g)) {
          alert(
            "La contraseña debe tener al menos un caracter especial: !@#$%^&*.,;:'"
          );
          return;
        }
        try {
          this.isLoading = true;
        const response = await axios.post(
          `http://${this.ruta}${this.puerto}/validatecode`,
          {
            code: this.codigo,
            password: this.PasswordIngresado,
          }
        );
        console.log(response);

          if (response.status === 200) {
            alert("Contraseña cambiada con éxito");
            this.isLoading = false;
            this.$emit("close");
          } else {
            this.isLoading = false;
            if(response.data.message === "La contraseña no puede ser igual a una contraseña que ya se usó anteriormente, por favor vuelve a intentarlo solicitando un nuevo código")
            {
              this.$emit("close");
            }
            alert(response.data.message);
          }
        } catch (error) {
          this.isLoading=false;
        }
        
        
      } else {
        alert("Las contraseñas no coinciden");
      }
      this.isLoading = false;
    },

    verificarCamposTexto() {
      if (
        this.PasswordIngresado.trim() === "" ||
        this.PasswordConfirmar.trim() === "" ||
        this.codigo.trim() === ""
      ) {
        return false; // Al menos uno de los campos está vacío
      }
      return true; // Todos los campos tienen texto
    },
    async verificarCodigo() {
      if (this.verificarCamposTexto()) {
        await this.reiniciarContrasenia();
      } else {
        alert("Por favor, llene todos los campos");
      }
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-content {
  background-color: rgb(175, 81, 170);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 60%;
  position: relative;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: 900;
  margin-right: 10px;
  cursor: pointer;
}

.close-button:hover {
  transform: scale(1.2);
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 30px;
  width: 450px;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.form button {
  align-self: flex-end;
}

.flex-column > label {
  color: #151717;
  font-weight: 600;
}

.inputForm {
  border: 1.5px solid #ecedec;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 85%;
  height: 100%;
}

.input:focus {
  outline: none;
}

.inputForm:focus-within {
  border: 1.5px solid #2d79f3;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.flex-row > div > label {
  font-size: 14px;
  color: black;
  font-weight: 400;
}

.span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}

.button-submit {
  margin: 20px 0 10px 0;
  background-color: #151717;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}
.inputPassword {
  border: none;
  width: 100%;
  font-family: "Roboto";
  color: #671f82;
  text-align: center;
  font-size: 21px;
  font-weight: 100;
  margin-bottom: 25px;
}
.inputPassword:deep svg {
  width: 2rem;
  height: 2rem;
  top: 20%;
}

.button-submit:hover {
  background-color: #252727;
}

.p {
  text-align: center;
  color: black;
  font-size: 14px;
  margin: 5px 0;
}

.btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.btn:hover {
  border: 1px solid #2d79f3;
}
.search {
  display: inline-block;
  position: relative;
}

.search input[type=""] {
  width: 200px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search button[type="submit"] {
  background-color: #4e99e9;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.9s ease;
}

.search button[type="submit"]:hover {
  transform: scale(1.1);
  color: rgb(255, 255, 255);
  background-color: blue;
}
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    
  },
  
};
</script>
<style>
div:where(.swal2-container) div:where(.swal2-popup){
  font-size: 1.5rem !important;
}
</style>

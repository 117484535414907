<template>
    <div class="container-tarjeta">
        <div class="cookiesContent" id="cookiesPopup">
            <button class="close" @click="$emit('close-modal')">✖</button>
            <iframe v-if="verVideo" width="560" height="315" src="https://www.youtube.com/embed/NLHON9yCs4E?si=nDyyKbx8aK_d8zDE&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <img v-if="!verVideo" :src="this.linkImagen" alt="cookies-img" />
            <p>{{ this.mensaje }}</p>

            <div class="btn-container">
              <button class="accept" v-if="!verVideo"  @click="this.verVideo=true;">Ver Video</button>
            <button class="accept" v-if="verVideo"  @click="this.verVideo=false;">Ver Imagen</button>

            <button class="accept" @click="$emit('close-modal')">Entendido!</button>
            </div>
           
        </div>
        </div>
</template>
<script>
export default {
	name: "ModalAnuncio",
    props: {
        mensaje: {
            type: String,
            required: true
        },
        linkImagen: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            verVideo: false
        }
    }
   


    
}
</script>

<style scoped>
.btn-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
}


.container-tarjeta {

  width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

   
}

.cookiesContent {
  width: 80%;
  max-width: 700px;
  max-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
}

.cookiesContent img {
  width: auto;
  max-width: 100%;
  max-height: 65vh;
  margin-bottom: 15px;
  margin-top: 15px;
}

.cookiesContent p {
  margin-bottom: 20px;
  font-size: 18px;
}

.close {
    width: 30px;
    font-size: 20px;
    color: #000000;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
  }
  .close:hover {
        color: rgb(120, 48, 131);
}

.accept {
    background-color: rgb(0, 139, 203);
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: white;
    box-shadow: 0px 6px 18px -5px rgba(0, 139, 203, 1);
  }
  .accept:hover {
        background-color: rgb(0, 119, 183);
    }

/* Media Query para pantallas más pequeñas, ajustar según sea necesario */
@media (max-width: 600px) {
  .cookiesContent {
    width: 90%;
  }
}

/* Media Query para pantallas más grandes, ajustar según sea necesario */
@media (min-width: 1200px) {
  .cookiesContent {
    width: 60%;
  }
}
</style>

<template>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
    crossorigin="anonymous"
  />
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
  />
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
    crossorigin="anonymous"
  />

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
  />
  <link
    type="text/css"
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
  />
  <link
    type="text/css"
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
  />
  <br />
  <br />
  <!-- Header Start -->
  <AppNavbar />
  <div class="contenedor-modal" v-if="showModalAnuncio && modalLoaded">
    <AnuncioModal
    :linkImagen="this.linkFotoModal"
    :mensaje="this.mensajeModal"
    @close-modal="showModalAnuncio = false"
  />
  </div>
 





  <!-- Header End -->
  <!--HOME-->
  <section class="bck-img">
    <div class="hero">
      <div class="content2">
        <h1 class="anim">
          <h2 style="margin-bottom: 0; color: #671f82; text-transform: none;">Bienvenido a</h2>
          <h2
            style="
              font-size: 4vw;
              color: #078bcc;
              font-weight: bold;
              letter-spacing: 0.2vw;
            "
          >
            CONECTA TU FUTURO
          </h2>
        </h1>

        <p style="text-transform: none" class="anim">
          Este año, 2024, podrás usar esta página web en la que encontrarás
          muchas instituciones, cada una de ellas contará con un espacio lleno
          de información. Te sugerimos navegar las páginas de todas las
          instituciones para conocer a profundidad cada una de ellas. A partir
          del 19 de Febrero podrás reunirte con el área de recursos humanos de las
          instituciones para realizar consultas sobre las inquietudes que
          tengas.
        </p>
        <section id="body" class="anim">
          <div class="wrapper">
            <div class="icon facebook">
              <a href="https://youtu.be/QOYwweSp-uo" target="_blank">
                <div class="tooltip">Directora Académica</div>
                <span><i class="fas fa-play"></i></span>
              </a>
            </div>
            
          </div>
        </section>
        <a href="#nosotros" class="btn-inicio anim"
          ><i style="width: auto; padding: 0% 1%; font-size: 1.5rem"
            >Más_Información</i
          ></a
        >
      </div>
      <img
        v-if="this.isDesktop"
        src="../assets/personaInicio.png"
        class="feature-img-anim"
        alt=""
        style="max-width: auto; height: auto"
      />
    </div>



  </section>
  <!--END HOME-->
  <!--CHATBOT-->
  <!--
  <div id="chat-container">
    <button
      id="chat-button"
      style="background-color: #0070a3"
      @click="toggleChat"
    >
      Abrir Chat
    </button>
    <div v-if="showMessage && !chatOpened" class="message">{{ message }}</div>

    <div id="chat-box" :class="{ open: showChat }">
      <a class="logo1">
        <div class="logo-image"></div>
        <div class="tituloChat" style="color: var(--black)">CHATBOT</div>
      </a>
      <div
        class="messageBox"
        id="scroll-container"
        @mouseenter="stopAutoScroll"
        @mouseleave="startAutoScroll"
        @wheel="stopAutoScroll"
      >
        <div v-for="(message, index) in messages" :key="index">
          <div
            :class="
              message.from == 'user' ? 'messageFromUser' : 'messageFromChatGpt'
            "
          >
            <div
              :class="
                message.from == 'user'
                  ? 'userMessageWrapper'
                  : 'chatGptMessageWrapper'
              "
            >
              <div
                :class="
                  message.from == 'user'
                    ? 'userMessageContent'
                    : 'chatGptMessageContent'
                "
              >
                {{ message.data }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inputContainer">
        <input
          v-on:keydown.enter="sendMessage(currentMessage)"
          v-model="currentMessage"
          type="text"
          class="messageInput"
          placeholder="¿En qué puedo ayudarte?"
        />
        <button @click="sendMessage(currentMessage)" class="askButton">
          <template v-if="isWaitingResponse"> ... </template>
          <template v-else> Preguntar </template>
        </button>
      </div>
    </div>
  </div>
  -->
  <!--FIN CHATBOT-->

  <!--BOTÓN WHATSAPP-->
  <div class="whatsapp-button" style="border: solid 2px var(--white)">
    <a
      style="color: var(--black)"
      href="https://wa.me/59176521445"
      target="_blank"
    >
      <i class="fab fa-whatsapp"></i>
    </a>
  </div>
  <!--FIN BOTÓN WHATSAPP-->

  <!-- Modal -->
  


  <section class="about-us" id="nosotros">
    <div class="container1">
      <div class="img">
        <div class="contenedor1">
          <div class="logo-ejemplo1">
            <div class="imagen">
              <img
                src="https://logowiki.net/wp-content/uploads/imgp/Banco-Nacional-de-Bolivia-Logo-1-6464.jpg"
                alt=""
              />
            </div>
            <div class="empresa"><p>Banco Nacional de Bolivia</p></div>
          </div>
          <div class="botones">
            <section id="body">
              <div class="wrapper">
                <div class="icon facebook">
                  <a href="https://youtu.be/X661dkdiWXs" target="_blank">
                    <div class="tooltip">Gonzalo_Díaz</div>
                    <span><i class="fas fa-play"></i></span>
                  </a>
                </div>
                <div class="icon twitter">
                  <a href="https://youtu.be/GDxe1ZM6IA8" target="_blank">
                    <div class="tooltip">Freddy_RDC.</div>
                    <span><i class="fas fa-play"></i></span>
                  </a>
                </div>
                <div class="icon instagram">
                  <a href="https://youtu.be/zE7K-d6kdEI" target="_blank">
                    <div class="tooltip">Jorge_Orías</div>
                    <span><i class="fas fa-play"></i></span>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="contenedor2">
          <div class="logo-ejemplo2">
            <div class="imagen">
              <img
                src="https://yt3.ggpht.com/a/AGF-l7-fq-eK8kq1HaZPStQR5RX1kmxOL1ecboJDjw=s900-c-k-c0xffffffff-no-rj-mo"
                alt=""
              />
            </div>
            <div class="empresa"><p>Banco Mercantil Santa Cruz</p></div>
          </div>
          <div class="botones">
            <section id="body">
              <div class="wrapper">
                <div class="icon facebook">
                  <a href="https://youtu.be/0J7iJ3F7yPg" target="_blank">
                    <div class="tooltip">Fabiola_Ibañez</div>
                    <span><i class="fas fa-play"></i></span>
                  </a>
                </div>
                <div class="icon twitter">
                  <a href="https://youtu.be/rxlVE3cLlg8" target="_blank">
                    <div class="tooltip">Hernan_Solares</div>
                    <span><i class="fas fa-play"></i></span>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>



      <div class="about_details">
        <span>Acerca de nuestros Ex-Estudiantes</span>
        <h2>Consigue tu empleo soñado con la Cato a tu lado</h2>
        <p style="text-transform: none; letter-spacing: 1px">
          La Cato te ayuda en tu búsqueda de tu empleo soñado en las + de 45
          empresas y organizaciones invitadas. No dejes pasar esta oportunidad
          única de conectar con empleadores potenciales y expandir tus
          horizontes profesionales. ¡Te esperamos!
        </p>
        <br />

        <button
          class="btn-us"
          @click="abrirInstituciones"
          style="font-size: 2rem"
        >
          Ver Instituciones
        </button>
      </div>

    </div>

  </section>
  <div style="width: 100%; justify-items: center; display: flex; align-items: center; flex: content; flex-direction: column;" class="banner">
    <img src="../assets/banner1.png" style="width: 70vw;" class="feature-img-anim" alt="" />

  </div>
  <!--End Modal-->

  <!--Footer Start-->
  <AppFooter />
  <!--Footer end-->
</template>

<script>
import axios from "axios";
import AppNavbar from "./navbar.vue";
import AppFooter from "./footer.vue";
import { rutaString, puertoString } from "../assets/apiconfig.js";
import AnuncioModal from "@/components/modals/AnuncioModal.vue";

/**import $ from "jquery";
import "slick-carousel/slick/slick.min.js"; */

export default {
  name: "ProductList",
  data() {
    return {
      isDesktop: false,
      isWaitingResponse: false,
      autoScrollInterval: null,
      items: [],
      showChat: false,
      showMessage: false,
      message: "",
      chatInterval: null,
      chatOpened: false,
      currentMessage: "",
      messages: [],
      showTypingEffect: false,
      ruta: rutaString,
      puerto: puertoString,
      showModalAnuncio: true,
      modalLoaded: false,
      linkFotoModal: "",
      mensajeModal: "",
    };
  },

  created() {},

  methods: {
    async obtenerAnuncio(){
      await axios.get(`http://${this.ruta}${this.puerto}/anuncio/1`).then((response) => {
        this.linkFotoModal = response.data.link_imagen;
        this.mensajeModal = response.data.texto_anuncio;
        this.modalLoaded = true;
     
      });
    },
    

    verificarAnuncio() {
      const hoy = new Date().toDateString();
      const anuncioYaMostrado = sessionStorage.getItem('anuncioMostrado');
      console.log(anuncioYaMostrado);
      console.log(hoy);

      if (anuncioYaMostrado !== hoy) {
        console.log('Mostrar anuncio');
        // Mostrar el anuncio y registrar la fecha actual
        this.showModalAnuncio = true;
        sessionStorage.setItem('anuncioMostrado', hoy);
      }else{
        console.log('No mostrar anuncio');
        this.showModalAnuncio = false;
      }
    },
    checkWindowSize() {
      this.isDesktop = window.innerWidth >= 768;
    },
    abrirInstituciones() {
      this.$router.push("/instituciones");
    },
    /**metodos slider */
    /** initSlickCarousel() {
      // Configuración del slider principal
      $(".slider-for").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".slider-nav",
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 4000,
      });

      // Configuración del slider de navegación
      $(".slider-nav").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: ".slider-for",
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 4000,
      });
    }, */

    /**fin metodos slider */

    startAutoScroll() {
      const scrollContainer = document.getElementById("scroll-container");
      if (scrollContainer !== null) {
        scrollContainer.scrollTop += 1; // Ajusta la velocidad de desplazamiento cambiando este valor
      } else {
        console.error(
          "El elemento 'scroll-container' no se encontró en el DOM."
        );
      }
    },

    stopAutoScroll() {
      clearInterval(this.autoScrollInterval);
    },

    async sendMessage(message) {
      this.messages.push({
        from: "user",
        data: message,
      });

      this.isWaitingResponse = true;

      console.log(this.ruta + this.puerto);

      await axios
        .post(`http://${this.ruta}${this.puerto}/chatbot?message=` + message, {
          message: message,
        })
        .then((response) => {
          this.messages.push({
            from: "chatGpt",
            data: response.data.data, // Accede a la propiedad 'data' del objeto de respuesta
          });

          this.isWaitingResponse = false;

          this.$nextTick(() => {
            const scrollContainer = document.getElementById("scroll-container");
            scrollContainer.scrollTop = scrollContainer.scrollHeight; // Desplaza automáticamente al final del contenedor de mensajes
          });
        });

      this.currentMessage = "";
    },

    toggleChat() {
      this.showChat = !this.showChat;
      this.chatOpened = this.showChat;
    },

    startPopupInterval() {
      this.chatInterval = setInterval(() => {
        this.showPopupMessage();
      }, 3000);
    },

    showPopupMessage() {
      if (!this.chatOpened) {
        this.message = "¿Tienes preguntas?";
        this.showMessage = true;

        setTimeout(() => {
          this.showMessage = false;
          this.message = "";
        }, 2000);
      }
    },

    verPagina(id) {
      // Abrir otra página y enviar el ID del item como parámetro en la URL
      //window.open(`/PaginaInstitucion?id=${id}`, "_blank");
      window.open(`/PaginaInstitucion/${id}`);
    },
    /*Métodos Navbar*/
    irACalendarioCharlas() {
      this.$router.push("/calendario_charlas");
    },
    irAInstituciones() {
      this.$router.push("/instituciones");
    },
    /*Fin métodos navbar*/
  },

  /*SCRIPTS NAVBAR*/
  async mounted() {
    this.verificarAnuncio();
    await this.obtenerAnuncio();

    this.checkWindowSize();
    window.addEventListener("resize", this.checkWindowSize);
    document.addEventListener("DOMContentLoaded", () => {
      this.startAutoScroll();
    });

    this.startPopupInterval();

    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });

    window.addEventListener("scroll", () => {
      navbar.classList.remove("active");
    });

    /*scripts de slider
    this.$nextTick(() => {
      this.initSlickCarousel();
    });

    /** Configuración del slider principal
    $(".slider-for").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: ".slider-nav",
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 4000,
    });

    // Configuración del slider de navegación
    $(".slider-nav").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: ".slider-for",
      dots: false,
      centerMode: true,
      focusOnSelect: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 4000,
    }); */

    /*FIN DE slider*/
  },
  beforeUnmount() {
    clearInterval(this.chatInterval);
    window.removeEventListener("resize", this.checkWindowSize);
    clearInterval(this.chatInterval);
    /**$(".slider-for").slick("slickPause");
    $(".slider-nav").slick("slickPause"); */
  },

  components: {
    AppNavbar,
    AppFooter,
    AnuncioModal,
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");
.feature-img-anim{
  animation: fadeIn 2s;
  width: 45vw;
  margin-top: 3rem;
  margin-left: 10rem;
}


.contenedor-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.container1 {
  display: flex;
  justify-content: space-between;

  align-items: center;
  padding: 0 5%;
  margin-top: 5%;
}

:root {
  --orange: #ff7800;
  /**--black: #120f40; */
  --black: #124d66;
  --fdeceleste: #078bcc;
  --darkYellow: #f2b90c;
  --yellow: #f2cb05;
  --marine: #024873;
  --blue: #034c8c;
  --white: #f2f2f2;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
}
* {
  font-family: "Poppins", sans-serif;
}
/**ESTILOS CHATBOT */
/**BOTÓN CHATBOX */
#chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

#chat-button {
  padding: 5%;
  width: 150px;
  height: 50px;
  border-radius: 18px;
  background-color: var(--black);
  color: var(--white);
  font-size: 1.7rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.message {
  font-size: 1.7rem;
  position: absolute;
  bottom: 60px;
  right: 0;
  background-color: #f1f1f1;
  padding: 10px;
  height: 50px;
  width: 200px;
  border-radius: 5px;
  animation: popup 0.5s;
  z-index: 999;
}

@keyframes popup {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

#chat-box {
  display: none;
  position: absolute;
  bottom: 60px;
  right: 0;
  width: 420px;
  height: 450px;
  background-color: #f1f1f1;
  padding: 5%;
  overflow-y: scroll;
}

#chat-box.open {
  display: block;
}
/**FIN CHATBOX */

/**CHAT GPT */
.logo1 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 17%;
  padding: 2.5% 0;
}

.logo-image {
  width: 15%;
  height: 100%;
  border-radius: 50%;
  background: url(https://i.imgur.com/TKxNFIp.png) no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 15%;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.tituloChat {
  font-size: 3rem;
  font-weight: 700;
  margin-right: 15%;
  color: var(--black) !important;
}

.chatbox-container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
}

.container {
  width: 360px;
  height: 600px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}

.messageBox {
  padding: 16px;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.messageFromUser,
.messageFromChatGpt {
  display: flex;
}

.messageBox {
  max-height: 400px;
  height: 70%;
  overflow-y: auto;
  padding: 0 16px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  flex-grow: 1;
}

.messageFromUser,
.messageFromChatGpt {
  display: flex;
  margin-bottom: 8px;
}

.userMessageWrapper,
.chatGptMessageWrapper {
  display: flex;
  flex-direction: column;
}

.userMessageWrapper {
  align-self: flex-end;
}

.chatGptMessageWrapper {
  align-self: flex-start;
}

.userMessageContent,
.chatGptMessageContent {
  max-width: 100%;
  padding: 8px 12px;
  border-radius: 18px;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.4;
}

.userMessageContent {
  background-color: var(--black);
  color: var(--white);
  border-top-left-radius: 0;
  min-width: 2rem;
}

.chatGptMessageContent {
  background-color: #ededed;
  color: #222;
  border-top-right-radius: 0;
}

.userMessageTimestamp,
.chatGptMessageTimestamp {
  font-size: 10px;
  color: #999;
  margin-top: 2px;
}

.userMessageTimestamp {
  align-self: flex-end;
}

.chatGptMessageTimestamp {
  align-self: flex-start;
}

.inputContainer {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f0f0f0;
  width: 100%;
}

.messageInput {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 12px;
  width: 50%;
  font-size: 1.3rem;
  background-color: white;
  border-radius: 24px;
  margin-right: 8px;
}

.askButton {
  background-color: #0070a3;
  color: var(--white);
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 24px;
  transition: background-color 0.3s ease-in-out;
}

.askButton:hover {
  background-color: var(--white);
  color: var(--black);
  scale: 1.2;
  animation: hoverAnimation 0.5s ease-in-out infinite;
  transition: 0.5s;
  font-weight: 700;
}

@keyframes hoverAnimation {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px) translateY(-2px);
  }
  50% {
    transform: translateX(0) translateY(0);
  }
  75% {
    transform: translateX(2px) translateY(2px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    max-width: none;
    border-radius: 0;
  }
}
.chatbox-container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
}

.messageBox {
  padding: 16px;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.messageFromUser,
.messageFromChatGpt {
  display: flex;
}
/**FIN ESTILOS CHATBOT */

/**BOTÓN WHATSAPP */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #25d366;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 24px;
  cursor: pointer;
  z-index: 9999;
}
.whatsapp-button:hover {
  background-color: #128c7e;
  color: var(--white) !important;
}
/**FIN WHATSAPP */

/**ESTILOS SLIDER */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  overflow-x: hidden;
  font-family: Adieu;
}

.slider-full img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}
.slider-full h1 {
  margin-top: 15%;
  font-size: 7rem;
  color: #078bcc;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background: transparent;
}

.slider-thumb img {
  width: 90%;
  object-fit: cover;
  height: 20vh;
}

.slider-thumb {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 950px) {
  .slider-full img {
    width: 100%;
    object-fit: cover;
    height: 100vh;
  }
  .slider-full h1 {
    margin-top: 15%;
    font-size: 7rem;
    color: var(--black);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background: transparent;
  }
  .slider-thumb img {
    width: 90%;
    object-fit: cover;
    height: 20vh;
  }
  .slider-thumb .slick-slide h2 {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 770px) {
  .slider-full img {
    width: 100%;
    object-fit: cover;
    height: 100vh;
  }
  .slider-full h1 {
    margin-top: 10%;
    font-size: 6rem;
    color: var(--black);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background: transparent;
  }
  .slider-thumb img {
    width: 90%;
    object-fit: cover;
    height: 20vh;
  }
  .slider-thumb .slick-slide h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 470px) {
  .slider-full img {
    width: 100%;
    object-fit: cover;
    height: 100vh;
  }
  .slider-full h1 {
    margin-top: 0%;
    font-size: 5rem;
    color: var(--black);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background: transparent;
  }
  .slider-thumb img {
    width: 90%;
    object-fit: cover;
    height: 20vh;
  }
  .slider-thumb .slick-slide h2 {
    font-size: 1.2rem;
  }
  .slider-full-caption p {
    font-size: 2.5rem;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .slider-full-caption .btn {
    font-size: 1.2rem;
  }
  .whatsapp-button {
    bottom: 10px;
    left: 10px;
  }
  #chat-container {
    bottom: 10px;
    right: 10px;
  }
  #chat-box {
    bottom: 60px;
    right: 2px;
    width: 230%;
    height: 450px;
    background-color: #f1f1f1;
    padding: 5%;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 370px) {
  .slider-full img {
    width: 100%;
    object-fit: cover;
    height: 100vh;
  }
  .slider-full h1 {
    margin-top: -20%;
    font-size: 4rem;
    color: var(--black);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background: transparent;
  }
  .slider-thumb img {
    width: 90%;
    object-fit: cover;
    height: 20vh;
  }
  .slider-thumb .slick-slide h2 {
    font-size: 1rem;
  }
  .slider-full-caption p {
    font-size: 2rem;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  .slider-full-caption .btn {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 300px) {
  #chat-box {
    bottom: 60px;
    right: 20px;
    width: 175%;
    height: 420px;
    background-color: #f1f1f1;
    padding: 5%;
    overflow-y: scroll;
  }
}

.slider-thumb .slick-slide {
  position: relative;
  margin: 15px;
  transition: all ease 0.5s;
  text-align: center; /* Agrega esta línea para centrar el contenido */
}

.slider-thumb .slick-slide h2 {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(
    -55%,
    -320%
  ); /* Agrega esta línea para centrar el título */
  text-align: center;
  width: 100%;
  color: white;
  opacity: 0;
  transition: all ease 0.5s;
}

.slider-thumb .slick-slide img {
  border-radius: 8px;
  transition: all ease 0.5s;
}

.slider-thumb .slick-slide:hover img {
  filter: brightness(0.5);
}

.slider-thumb .slick-slide:hover h2 {
  opacity: 1;
  top: 40%;
}

.slider-thumb .slick-slide.slick-current {
  transform: scale(1.1);
}

.slider-thumb .slick-slide.slick-current img {
  filter: brightness(0.5);
}

.slider-thumb .slick-slide.slick-current h2 {
  opacity: 1;
  top: 40%;
}

.slider-full-caption {
  position: absolute;
  top: 150px;
  left: 50px;
  color: white;
  font-size: 68px;
  width: 50%;
}

p {
  font-size: 3.5rem;
  margin: 10px 0;
}
.btn {
  border: 0.2rem solid #078bcc;
  background-color: #078bcc;
  color: var(--white) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 50%;
  height: 3.5rem;
  font-size: 1.7rem;
}
.btn:hover {
  border: 0.2rem solid var(--white);
  background-color: var(--white);
  color: var(--black) !important;
  transition: 0.5s;
  scale: 1.1;
}

/**FIN ESTILOS SLIDER */

/**ESTILOS SECCIÓN DE VIDEOS */
.videos {
  display: flex;
  position: relative;
  max-height: 100vh;
}

.first-column,
.second-column {
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
}
.first-column img,
.second-column img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(0.5);
}

.fde-icon {
  position: absolute;
  /**background-color: red; */
  height: 35%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
@media screen and (min-width: 885px) {
  .fde-icon {
    height: 50%;
  }
}

@media screen and (max-width: 701px) {
  .fde-icon {
    height: 20%;
  }
}

@media screen and (max-width: 480px) {
  .fde-icon {
    height: 17%;
  }
  .videos .fde-icon a h1 {
    font-size: 2rem;
  }
  .videos .fde-icon a:hover h1 {
    font-size: 2rem;
  }
  .videos .fde-icon a {
    font-size: 2rem;
  }
}
.fde-icon h1 {
  opacity: 0;
}

.fde-icon img {
  height: 100%;
  width: auto;
  object-fit: cover;
  filter: drop-shadow(15px 10px 50px #000);
}
.fde-icon img:hover {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}
.fde-icon a:hover h1 {
  opacity: 1;
  color: var(--white);
  font-size: 5rem;
  font-style: none;
  text-decoration: none;
  transform: scale(1.1);
  transform-origin: center;
  transform: translateX(22%);
}
.fde-icon a {
  display: contents;
  opacity: 1;
  color: var(--white);
  font-size: 5rem;
  font-style: none;
  text-decoration: none;
  transform: scale(1.1);
}

.first-column .text-button1 {
  /**background-color: red; */
  width: 20%;
  height: 30%;
  position: absolute;
  /*background-color: red;*/
  top: 50%;
  left: 22%;
  transform: translate(-50%, -50%);
  padding: 1%;
}
.second-column .text-button2 {
  /**background-color: blue; */
  width: 20%;
  height: 30%;
  position: absolute;
  /*background-color: red;*/
  top: 50%;
  right: 5%;
  transform: translate(-50%, -50%);
  padding: 1%;
}

.first-column .text-button1 .btn-play1 {
  height: 75%;
  width: 100%;
  /**background-color: #25d366; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.first-column .text-button1 .btn-play1:hover ~ .textV1 h1 {
  transform: translateY(-10%);
  color: #0970a2;
  font-size: 6rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px #f6f4f7;
  padding: 5px;
}

.first-column .text-button1 .btn-play1:hover {
  filter: brightness(1);
  transform: scale(1.25);
  transition: 0.3s ease-in-out;
}
.first-column .text-button1 .btn-play1 img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  /**background-color: #221266; */
  border-radius: 50%;
  filter: drop-shadow(15px 10px 50px #000);
}

.first-column .text-button1 .textV1 {
  height: 25%;
  width: 100%;
  /*background-color: #222;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.first-column .text-button1 .textV1 h1 {
  color: var(--white);
  font-size: 4rem;
  transform: translateY(-50%);
}

.second-column .text-button2 .btn-play2 {
  height: 75%;
  width: 100%;
  /**background-color: #25d366; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.second-column .text-button2 .btn-play2:hover ~ .textV2 h1 {
  transform: translateY(-10%);
  color: #671f82;
  font-size: 5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px #f6f4f7;
  padding: 5px;
}

.second-column .text-button2 .btn-play2:hover {
  filter: brightness(1);
  transform: scale(1.25);
  transition: 0.3s ease-in-out;
}
/**.second-column .text-button2 .btn-play2:hover ~ #img-second-column {
  filter: brightness(1);
  transform: scale(1.25);
  transition: 0.3s ease-in-out;
} */

.second-column .text-button2 .btn-play2 img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  /**background-color: #221266; */
  border-radius: 50%;
  filter: drop-shadow(15px 10px 50px #000);
}

.second-column .text-button2 .textV2 {
  height: 25%;
  width: 100%;
  /*background-color: #222;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.second-column .text-button2 .textV2 h1 {
  color: var(--white);
  font-size: 4rem;
  transform: translateY(-50%);
}

/**FIN SECCIÓN DE VIDEOS */

/**SECCIÓN ABOUT US */
.about-us {
  height: 100vh;
  position: static;
  display: flex;
}

.about-us .container1 {
  background-color: #e8f3e8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5%;
  padding: 40px 47px;
  box-shadow: 10px 10px 18px -8px #aaaaaa, -10px -10px 24px #ffffff;
  border-radius: 20px;
  height: auto;
  width: 90%;
}
.about-us .container1 .img {
  height: 100%;
  width: 55%;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-us .container1 .img .contenedor1 {
  height: 50%;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us .container1 .img .contenedor2 {
  height: 50%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.about-us .container1 .img .contenedor1 .logo-ejemplo1 {
  height: 100%;
  width: 100%;
  margin: 5% 2% 0% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-us .container1 .img .contenedor2 .logo-ejemplo2 {
  height: 100%;
  width: 100%;
  margin: 5% 2% 0% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-us .container1 .img .contenedor1 .botones,
.about-us .container1 .img .contenedor2 .botones {
  height: 100%;
  width: 100%;
  margin: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us .container1 .img .contenedor1 .logo-ejemplo1 .imagen {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  border-radius: 50%;
  background-color: #06943e;
}

.about-us .container1 .img .contenedor2 .logo-ejemplo2 .imagen {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.about-us .container1 .img .contenedor1 .logo-ejemplo1 .imagen img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.about-us .container1 .img .contenedor2 .logo-ejemplo2 .imagen img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: contain;
}
.about-us .container1 .img .contenedor1 .logo-ejemplo1 .empresa,
.about-us .container1 .img .contenedor2 .logo-ejemplo2 .empresa {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-us .container1 .img .contenedor1 .logo-ejemplo1 .empresa p,
.about-us .container1 .img .contenedor2 .logo-ejemplo2 .empresa p {
  font-size: 1.5rem;
  font-weight: bold;
}

.about_details {
  margin: 0 45px;
  width: 45%;
  height: 100%;
}

.about-us span {
  margin-top: 20px;
  color: red;
}

.about_details h2 {
  font-family: "Potta One";
  font-weight: 100;
  font-size: 7rem;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.about_details p {
  color: gray;
  font-size: 2.1rem;
}

.btn-us {
  margin: 15px 0;
  padding: 8px 20px;
  background-color: transparent;
  border: 1px solid red;
  border-radius: 10px;
  letter-spacing: 1px;
  color: #373737;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.btn-us:hover {
  background-color: red;
  color: var(--white);
}

@media screen and (max-width: 1366px) {
  .about-us {
   
    height: auto;
    width: 90vw;
  }
  .banner{
    width: 90vw;
  }
}

@media screen and (max-width: 1081px) {
  .about_details h2 {
    font-size: 5rem;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  .about_details p {
    color: gray;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 691px) {
  .about-us {
    min-height: 120vh;
    width: 100vw;
    padding: 0;
    transform: translateX(-4%);
    margin: 0 0 10% 0;
    margin-bottom: 10%;
  }
  .about-us .container1 {
    display: flex;
    flex-direction: column;
    margin: 5%;
    margin-right: 0%;
    height: 100%;
    width: 90%;
  }
  .about-us .container1 .img {
    flex-direction: row;
    display: flex;
    width: 100%;
    height: 50%;
  }
  .about-us .about_details {
    text-align: start;
    order: 1;
    height: 50%;
    width: 100%;
    margin-top: 30%;
  }
  .about-us .about_details h2 {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .about-us .about_details p {
    font-size: 11px;
  }

  .about-us .container1 .img .contenedor1 {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 1%;
  }
  .about-us .container1 .img .contenedor2 {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 1%;
  }
  .about-us .container1 .img .contenedor1 .logo-ejemplo1 {
    height: 60%;
    width: 100%;
    margin: 2%;
  }
  .about-us .container1 .img .contenedor2 .logo-ejemplo2 {
    height: 60%;
    width: 100%;
    margin: 2%;
  }
  .about-us .container1 .img .contenedor1 .botones,
  .about-us .container1 .img .contenedor2 .botones {
    height: 35%;
    width: 100%;
    margin: 2%;
  }
}

@media screen and (max-width: 1000px) {
  .about-us .container1 .img .contenedor1 .logo-ejemplo1 .imagen,
  .about-us .container1 .img .contenedor2 .logo-ejemplo2 .imagen {
    height: 50%;
    width: 100%;
  }
}

/**FIN SECCIÓN ABOUT US */

/**INICIO PRIMERA PARTE  */
.bck-img {
  width: 100vw;
  height: auto;
  padding: 10px 2%;
  overflow: hidden;
  position: relative;
}

.bck-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/conecta tu futuro -01.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 45% auto;
  background-position-x: 42%;
  opacity: 0.3;
  z-index: -1;
}

.hero {
  /**  background-color: red;
 */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2% 5%;
  overflow: hidden;
  position: relative;
}

.hero .content2 {
  margin-top: 10%;
  width: 40%;
}

.hero .content2 p {
  margin: 4% 0;
  font-size: 1.7rem;
  color: #333;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.hero .btn-inicio {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 14px 40px;
  color: #fff;
  background-image: linear-gradient(45deg, #078bcc, #923891);
  font-size: 14px;
  border-radius: 14px;
  border-top-right-radius: 0;
  width: 50%;
  transition: 0.5s;
}

.hero .content2 .btn-inicio {
  padding: 15px 80px;
  font-size: 16px;
  transition: 0.5s all ease-in-out;
}

.hero .content2 .btn-inicio:hover {
  border-top-right-radius: 30px;
  width: 60%;
}
.hero .content2 .btn-inicio:hover i {
  letter-spacing: 4px;
}

.hero .feature-img {
  width: auto;
  position: absolute;
  bottom: 0;
  right: 5%;
  margin-left: 10%;
  height: auto;
}

.anim {
  opacity: 0;
  transform: translateY(30px);
  animation: moveup 0.7s linear forwards;
}

@keyframes moveup {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/**BOTONES VIDEOS PRIMERA PARTE */
/* BOTONES REDER SOCIALES */
#body {
  margin: 2% 0;
  display: flex;
  height: 25%;
  max-width: 100vw;
  justify-content: center;
  align-items: center;
}

#body .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.wrapper .icon {
  margin: 5%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.5, 0.3, 1.5);
}
#body .wrapper:hover .icon {
  margin: 40% 30% 0 30%;
}

.about-us .container1 .img .contenedor1 .botones #body .wrapper:hover .icon,
.about-us .container1 .img .contenedor2 .botones #body .wrapper:hover .icon {
  margin: 20% 20% 0 20%;
}
.about-us .container1 .img .contenedor1 .botones #body .wrapper .icon,
.about-us .container1 .img .contenedor2 .botones #body .wrapper .icon {
  margin: 0% 5%;
}

#body .wrapper .icon {
  margin: 0% 25%;
}

.wrapper .icon span {
  display: block;
  color: #fff;
  height: 60px;
  width: 60px;
  background: #923891;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.5, 0.3, 1.5);
}

.wrapper .icon span i {
  line-height: 60px;
  font-size: 25px;
}

.wrapper .icon .tooltip {
  position: absolute;
  top: 0;
  z-index: 1;
  background: white;
  color: white;
  padding: 10px 18px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 25px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.5, 0.3, 1.5);
}

.wrapper .icon:hover .tooltip {
  top: -70px;
  opacity: 1;
  pointer-events: auto;
}

.icon .tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: white;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.5, 0.3, 1.5);
}

.wrapper .icon:hover span {
  color: #fff;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper .facebook:hover span,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip:before {
  background: #078bcc;
}

.wrapper .facebook:hover .tooltip {
  transform: translateX(-30%);
}
.wrapper .twitter:hover .tooltip {
  transform: translateX(-30%);
}
.wrapper .instagram:hover .tooltip {
  transform: translateX(-28%);
}
.wrapper .linkedin:hover .tooltip {
  transform: translateX(-24%);
}
.wrapper .marker:hover .tooltip {
  transform: translateX(-8%);
}

.wrapper .twitter:hover span,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip:before {
  background: #671f82;
}
.wrapper .instagram:hover span,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip:before {
  background: #e1303c;
}
.wrapper .linkedin:hover span,
.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip:before {
  background: #0a66c2;
}
.wrapper .marker:hover span,
.wrapper .marker:hover .tooltip,
.wrapper .marker:hover .tooltip:before {
  background: #46c1f6;
}
/*FIN BOTONES*/
/**FIN BOTONES VIDEOS PRIMERA PARTE */

@media screen and (max-width: 691px) {
  .about-us .container1 .img .contenedor1 .botones #body .wrapper:hover .icon,
  .about-us .container1 .img .contenedor2 .botones #body .wrapper:hover .icon {
    margin: 20% 5% 0 5%;
  }
}

/**FIN PRIMERA PARTE */

@media screen and (max-width: 768px) {
  .bck-img {
    width: 100vw;
    height: 120vh;
    padding: 10px 2%;
    overflow: hidden;
    position: relative;
  }
  .bck-img .hero .content2 {
    width: 100%;
    height: 100%;
    margin-top: 50%;
    padding: 2% 2%;
  }
  .bck-img .hero .content2 p {
    font-size: 1.5rem;
    text-align: justify;
  }
  .bck-img .hero .content2 .btn-inicio {
    margin-left: 25%;
  }
  .bck-img .hero .content2 .btn-inicio i {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 450px) {
  .bck-img .hero .content2 #body .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .about-us .container1 .img .contenedor1 .botones,
  .about-us .container1 .img .contenedor2 .botones {
    scale: 0.7;
  }
}
</style>
<template>
    <div v-if="showIframe" class="iframe-popup"  >
      <button class="close-button" @click="closeIframe">x</button>
      <iframe :src="iframeSrc" allow="clipboard-read; clipboard-write"></iframe>
    </div>
  </template>

  <script>
  export default {
    data() {
      return {
        showIframe: false,
        iframeSrc: ''
      };
    },
    methods: {
      openIframe(src) {
        this.showIframe = true;
        this.iframeSrc = src;
      },
      closeIframe() {
        this.showIframe = false;
        this.iframeSrc = '';

      }
    }
  };
  </script>

  <style scoped>
  .iframe-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .iframe-popup iframe {
    width: 80%;
    height: 80%;
  }
  .close-button {
  background-color: #f44336; /* Color de fondo rojo */
  color: white; /* Color de texto blanco */
  border: none; /* Remueve los bordes */
  text-align: center; /* Centra el texto */
  text-decoration: none; /* Remueve el subrayado */
  display: inline-block;
  font-size: 12px; /* Tamaño del texto */
  width: 24px; /* Ancho del botón */
  height: 24px; /* Altura del botón */
  line-height: 24px; /* Centra la "x" verticalmente */
  border-radius: 50%; /* Hace que el botón sea circular */
  padding: 0; /* Remueve el padding */
  transition-duration: 0.2s; /* Duración de la transición */
  cursor: pointer; /* Cambia el cursor cuando pasas el mouse encima */
}
  </style>
<template>
  <a href="#" class="scroll-top-btn">
    <i class="fas fa-arrow-up"></i>
  </a>
  <div>
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
      />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ"
        crossorigin="anonymous"
      />

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
      />

      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp"
        crossorigin="anonymous"
      />
    </head>
    <body>
      <AppNavbar />
      <section style="padding-top: 15rem" class="blogs" id="blogs">
        <h1 class="heading" style="color: #0070A3;">TODAS<span style="background-color: #0070A3;"> NUESTRAS CHARLAS </span></h1>
        <div class="box-container">
          <div
            class="box"
            v-for="itemcharla in Charlas"
            :key="itemcharla.Id_Charla"
          >
            <img :src="itemcharla.URL_FotoCharla" alt="Imagen Charla" />
            <div class="content">
              <div class="icons">
                <a 
                  ><i class="fas fa-calendar"></i
                  >{{ formatDate(itemcharla.Fecha) }}</a
                >
                <a ><i class="fas fa-clock"></i>{{ itemcharla.Hora }}</a>
              </div>
              <h3>{{ itemcharla.Nombre }}</h3>
              <h3 >{{ itemcharla.Nombre.lenght }}</h3>
              <h4>{{ itemcharla.NombreInstitucion }}</h4>
              <p>Cupos: {{ itemcharla.Cantidad_Cupos }}</p>
              <p>Cupos Disponibles: {{ itemcharla.Cupos_Disponibles }}</p>
              <button
                v-if="name"
                href=""
                class="btn"
                @click="doInscripcion(itemcharla.Id_Charla)"
                :disabled="itemcharla.Cupos_Disponibles === 0"
              >
                Inscribirme
              </button>
            </div>
          </div>
        </div>
      </section>

      <br />
      <AppFooter />
    </body>
  </div>
  <InscripcionCharla ref="confirmDialogue"></InscripcionCharla>
</template>

<script>
import axios from "axios";
import AppNavbar from "./navbar.vue";
import AppFooter from "./footer.vue";

import { rutaString, puertoString } from "@/assets/apiconfig";
import InscripcionCharla from "./Componentes_user/Modals/ConfirmDialogue.vue";

export default {
  name: "PaginaInstitucion",
  data() {
    return {
      name: sessionStorage.getItem("tipo"),
      cisession: sessionStorage.getItem("ci"),

      paginaInstitucion: {
        Nombre: "",
        Logo: "",
        Bienvenida: "",
        Mision: "",
        car1: "",
        car2: "",
        car3: "",
        Telefono: "",
        Correo: "",
        Direccion: "",
      },
      currentImage: 0,
      /*Charlas: {
          Id_Charla: "",
          Nombre: "",
          Fecha: "",
          Hora: "",
          Cantidad_Cupos: "",
          Cupos_Disponibles: "",
        },*/

      Charlas: [],
      Presentaciones: [],
      ruta: rutaString,
      puerto: puertoString,
    };
  },

  async mounted() {
    /**SCRIPTS NAVBAR */
    console.log(this.name);
    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector("#menu-btn");
    menuBtn.addEventListener("click", () => {
      navbar.classList.toggle("active");
    });

    window.addEventListener("scroll", () => {
      navbar.classList.remove("active");
    });

    const responsecharla = await axios.get(
      `http://${this.ruta}${this.puerto}/charlas`
    );
    this.Charlas = responsecharla.data;
    //this.Charlas = this.filtrarCharlas(this.Charlas);
    //script botón que lleva al inicio de la página/
    const scrollBtn = document.querySelector(".scroll-top-btn");
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 300) {
        scrollBtn.style.opacity = "1";
      } else {
        scrollBtn.style.opacity = "0";
      }
    });

    scrollBtn.addEventListener("click", function (e) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  },

  methods: {
    filtrarCharlas(json) {
      const fechaActual = new Date();
      const charlasFuturas = json.filter((charla) => {
        const fechaCharla = new Date(charla.Fecha);
        const horaCharla = charla.Hora.split(":");
        fechaCharla.setHours(horaCharla[0]);
        fechaCharla.setMinutes(horaCharla[1]);
        fechaCharla.setSeconds(horaCharla[2]);
        return fechaCharla > fechaActual;
      });
      return charlasFuturas;
    },

    formatDate(dateString) {
        const trimmedDate = dateString.substring(0, 10); // Recortar los primeros 10 caracteres de la cadena
        const parts = trimmedDate.split("-");
        const reversedDate = parts.reverse().join("-");
          return reversedDate;
      },



    //Función para obtener el nombre del zoom
    async obtenerNombreZoom(auxCi) {
      const response = await axios.get(
        `http://${this.ruta}${this.puerto}/nameuser/${auxCi}`
      );
      return response.data.nombre;
    },
    //Función para obtener el id de la cuenta
    async obtenerIdCuenta(auxCi) {
      const response = await axios.get(
        `http://${this.ruta}${this.puerto}/iduser/${auxCi}`
      );
      return response.data.cuenta_id;
    },

    async processInscripcion(auxCharlaId, auxCi) {
      const auxnombre = await this.obtenerNombreZoom(auxCi);
      const auxid = await this.obtenerIdCuenta(auxCi);
      
      try {
        const response = await axios.post(
          `http://${this.ruta}${this.puerto}/inscripcionCharla`,
          {
            Fecha: new Date().toISOString().slice(0, 19).replace("T", " "),
            NombreZoom: auxnombre,
            Charlas_Id_Charlas: auxCharlaId,
            cuenta_cuenta_id: auxid,
          }
        );
        if (response.data.affectedRows == 0) {
          alert("Usted ya se encuentra inscrito");
        }

        location.reload();

        // Resto del código
      } catch (err) {
        console.log(err);
      }
    },

    async doInscripcion(auxCharlaId) {
      const ok = await this.$refs.confirmDialogue.show({
        title: "Inscribir Charla",
        message: "Estas seguro que deseas inscribirte esta Charla?",
        okButton: "Inscribir",
      });
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        this.processInscripcion(auxCharlaId, this.cisession);
      }
    },

    prevImage() {
      this.currentImage = (this.currentImage - 1 + 3) % 3;
    },
    nextImage() {
      this.currentImage = (this.currentImage + 1) % 3;
    },
  },
  components: {
    AppFooter,
    AppNavbar,
    InscripcionCharla,
  },
};
</script>

<style>
/*estilos para el sitio*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

/*BOTÓN QUE LLEVA AL INICIO*/
.scroll-top-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background-color: var(--black);
  color: var(--white);
  padding: 20px;
  border-radius: 50%;
  font-size: 20px;
  opacity: 0;
  z-index: 9999;
}

.scroll-top-btn:hover {
  opacity: 1;
}
/*FIN BOTÓN QUE LLEVA AL INICIO*/

:root {
  --orange: #ff7800;
  --black: #120f40;
  --darkYellow: #f2b90c;
  --yellow: #f2cb05;
  --marine: #024873;
  --blue: #034c8c;
  --white: #f2f2f2;
  --light-color: #666;
  --box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
  --outline-hover: 0.2rem solid var(--black);
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2s linear;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
}

body {
  background: #f2f2f2;
}
.cuerpo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

/*responsive presentación de la organización*/
@media screen and (max-width: 768px) {
  .inicio,
  .bienvenido,
  .mision {
    flex-basis: 100%;
  }
}
/*fin del responsive de organización*/

/*Botón que va asecciones por id*/
.boton {
  position: fixed;
  bottom: 20px;
  left: 50%;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border-radius: 20px;
  text-decoration: none;
  z-index: 9999;
}

.boton:hover {
  scale: 1.2;
  transition: 0.5s;
  transform: translateY(5px);
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.boton-rebote {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: var(--black);
  color: var(--white);
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  animation: bounce 1s infinite;
}
/*Fin botón */

/*sección dos-columnas*/
.dos-columnas {
  display: flex;
  flex-wrap: wrap;
}

.columna1 {
  flex-basis: 100%;
  max-width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.columna1 img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.columna2 {
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 20px;
}

.columna2 h2 {
  font-size: 5rem;
  width: 100%;
}
.columna2 p {
  font-size: 3.5rem;
}

.titulo-seccion {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin: 1rem 0;
}

.titulo-seccion::before,
.titulo-seccion::after {
  content: "●";
  margin-right: 0.5rem;
  color: var(--black);
}

.titulo-seccion::after {
  margin-left: 0.5rem;
}

@media screen and (min-width: 768px) {
  .columna1 {
    flex-basis: 50%;
    max-width: 50%;
    text-align: left;
  }

  .columna2 {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 40px;
  }
}

/*fin de la sección */
.card {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.inicio {
  background-color: #f1f1f1;
  grid-row: 1 / span 2;
}
.logo {
  width: 100%;
}
.bienvenido {
  background-color: rgb(0, 0, 53);
  color: white;
  grid-column: 2;
  grid-row: 1;
}
.mision {
  background-color: rgb(17, 46, 81);
  color: white;
  grid-column: 2;
  grid-row: 2;
}
.title {
  font-size: 70px;
  margin-bottom: 0.5rem;
}
p {
  font-size: 30px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 100%) {
  .cuerpo {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
  }
  .inicio,
  .bienvenido,
  .mision {
    grid-column: 1;
  }
  .bienvenido {
    grid-row: 2;
  }
  .mision {
    grid-row: 3;
  }
}
.carousel {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  overflow: hidden;
}
.carousel-images {
  display: flex;
  width: 300%;
  transition: transform 0.5s ease;
  overflow: hidden; /* Asegura que solo se muestre una imagen a la vez */
}
.carousel-image {
  width: 100%;
  flex-shrink: 0;
}
.carousel-image img {
  width: 100%;
  object-fit: cover;
  height: 400px;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 10;
}
.arrow-left {
  left: 10px;
}
.arrow-right {
  right: 10px;
}
/* Transición basada en la imagen actual */
.carousel-images {
  transform: translateX(calc(-33.33% * var(--current-image)));
}
.contacto {
  flex-basis: 300px;
  background-color: #0074d9; /* Cambiar a azul */
}
.contacto-tarjeta {
  margin: 0;
  padding: 2rem;
  background-color: #000ece;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff;
}
.contacto-tarjeta h2 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}
.contacto-tarjeta ul {
  font-size: 2.5rem;

  list-style: none;
  padding: 0;
  margin: 0;
}
.contacto-tarjeta li {
  margin-bottom: 0.5rem;
}
.registro {
  margin-top: 2rem;
}
.registro button {
  padding: 1rem 2rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0074d9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.registro button:hover {
  background-color: #00549e;
}

/*CARTAS BLOGS-PRESENTACIONES*/
.heading1 {
  text-align: center;
  padding: 1.5rem 1rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.blogs .btn {
  border: 0.2rem solid #0070A3;
  background-color: #0070A3;
  color: var(--white) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
}
.blogs .btn:hover {
  border: 0.2rem solid var(--white);
  color: var(--black) !important;
  transition: 0.5s;
  scale: 1.1;
}

.blogs .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  gap: 1.5rem;
}

.blogs .box-container .box {
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  background: #f2f2f2;
  max-width: 50rem;
}

.blogs .box-container .box img {
  height: 25rem;
  width: 100%;
  object-fit: cover;
}

.blogs .box-container .box .content {
  padding: 2rem;
}

.blogs .box-container .box .content .icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: var(--border);
}

.blogs .box-container .box .content .icons a {
  color: var(--light-color);
  font-size: 1.5rem;
}

.blogs .box-container .box .content .icons a:hover {
  color: var(--black);
}

.blogs .box-container .box .content .icons a i {
  color: var(--black);
  padding-right: 0.5rem;
}

.blogs .box-container .box .content h3 {
  line-height: 1.8;
  color: #0070A3;
  font-size: 2.2rem;
  padding: 0.5rem 0;
  height: 7.5rem;
}

.blogs .box-container .box .content p {
  line-height: 1.8;
  color: var(--light-color);
  font-size: 1.5rem;
  padding: 0.5rem 0;
}
/*FIN CARTAS BLOGS-PRESENTACIONES*/

/*INICIO FOOTER*/

.footer {
  padding-top: 50px;
  background: #eee;
  box-shadow: var(--box-shadow);
  text-decoration: none;
}

.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;
}

.footer .box-container .box:last-child {
  display: flex;
  flex-direction: column;
}

.footer .box-container .box h3 {
  font-size: 2.5rem;
  color: var(--black);
  padding: 1rem 0;
}

.footer .box-container .box h3 i {
  color: var(--black);
}

.footer .box-container .box .links {
  display: block;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box .links i {
  color: var(--black);
  padding-right: 0.5rem;
}

.footer .box-container .box .links:hover i {
  padding-right: 2rem;
  text-decoration: none;
}

.footer .box-container .box p {
  line-height: 1.8;
  font-size: 1.5rem;
  color: var(--light-color);
  padding: 1rem 0;
}

.footer .box-container .box .share a {
  height: 4rem;
  width: 4rem;
  line-height: 4rem;
  border-radius: 0.5rem;
  font-size: 2rem;
  color: var(--black);
  margin-left: 0.2rem;
  text-align: center;
}

.footer .box-container .box .share a:hover {
  background: var(--black);
  color: #f2f2f2;
}

.footer .box-container .box .email {
  margin: 0.7rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--black);
  background: #eee;
  font-size: 1.6rem;
  color: var(--black);
  text-transform: none;
}

.footer .box-container .box .payment-img {
  margin-top: 2rem;
  height: 3rem;
}

.footer .credit {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 2.5rem;
  font-size: 2rem;
  color: var(--black);
  border-top: var(--border);
}

.footer .credit span {
  color: var(--black);
}

.footer .btn {
  font-size: 20px;
  border: 0.2rem solid var(--black);
  color: var(--white) !important;
  background-color: var(--black);
}
.footer .btn:hover {
  scale: 1.2;
  transition: 0.5s;
  color: var(--black) !important;
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .heading {
    font-size: 2.5rem;
  }

  .footer {
    text-align: center;
  }
}
/*FIN FOOTER*/
</style>
